import { useHistory } from 'react-router-dom'
import { formatMoney } from 'shop/components/Cart/utils'
import ErrorModal, { ErrorModalButton } from 'shop/components/Modal/ErrorModal'
import { useModal } from 'shop/hooks'
import { FulfillmentType } from 'shop/types'
import { getStoreUrl } from 'shop/utils'
import { capitalizeWords } from 'shop/utils/common'

const discountErrorGlossary = ({
  errorCode,
  origin = 'checkout',
  checkoutRemoveDiscount,
  currentFulfillmentType,
  values,
  history,
  openModal,
  storeSlug
}: Partial<DiscountErrorModalProps> & {
  history: any
  checkoutRemoveDiscount: () => void
  openModal: (selectedModal: string) => void
}): {
  title: string
  messages: string[]
  primaryButton?: ErrorModalButton
  secondaryButton?: ErrorModalButton
  handleButtonClick?: () => void
} => {
  const navigateBack = () =>
    storeSlug ? history.push(getStoreUrl(storeSlug)) : history.goBack()

  const createDiscountErrorContent = (title: string, message: string) => ({
    title,
    messages: [message],
    primaryButton: {
      message: 'Remove Discount',
      onClick: checkoutRemoveDiscount
    },
    secondaryButton: undefined
  })

  switch (errorCode) {
    case 'DISCOUNT_EXPIRED':
      return createDiscountErrorContent(
        'Expired discount',
        'Your discount code has expired. Click below to remove the discount from your cart'
      )

    case 'DISCOUNT_INVALID':
      return createDiscountErrorContent(
        'Invalid discount',
        'Your discount code is invalid. Click below to remove the discount from your cart'
      )

    case 'DISCOUNT_LIMIT_REACHED':
      return createDiscountErrorContent(
        'Discount limit reached',
        'This discount has reached its maximum number of uses. Please click below to remove the discount from your cart'
      )

    case 'CUSTOMER_LOGIN_REQUIRED': {
      const primaryButtonMap = {
        checkout: {
          message: 'Login or Sign up',
          onClick: () => {
            openModal('login')
          }
        },
        pay: {
          message: 'Go Back',
          onClick: () => history.goBack()
        }
      }
      const secondaryButtonMap = {
        checkout: {
          message: 'Continue without discount',
          onClick: checkoutRemoveDiscount
        },
        pay: undefined
      }
      const messageMap = {
        checkout: [
          `This discount code requires you to be logged in with an account.`,
          `Already have an account? Log in now to enjoy exclusive savings!`,
          `Not a member yet? Sign up in seconds to unlock special offers and discounts.`
        ],
        pay: [
          `This discount code requires you to be logged in with an account.`,
          `Please go back to login or signup and enjoy exclusive savings.`
        ]
      }

      return {
        title: 'Discount requires login',
        messages: messageMap[origin],
        primaryButton: primaryButtonMap[origin],
        secondaryButton: secondaryButtonMap[origin]
      }
    }

    case 'DISCOUNT_MINIMUM_VALUE_NOT_MET': {
      const secondaryButtonMap = {
        checkout: {
          message: 'Checkout Without Discount',
          onClick: checkoutRemoveDiscount
        },
        pay: undefined
      }

      const minimumOrderValuePrice =
        values?.minimumOrderValue &&
        formatMoney(parseFloat(values?.minimumOrderValue))

      return {
        title: 'Discount Minimum Not Met',
        messages: [
          `You've applied a discount code, but your cart total doesn't meet the minimum value required to use it.`,
          `Increase your cart value to ${minimumOrderValuePrice} to meet the discount's minimum requirement.`
        ],
        primaryButton: {
          message: 'Add More Items',
          onClick: navigateBack
        },
        secondaryButton: secondaryButtonMap[origin]
      }
    }

    case 'NO_TARGET_CATEGORIES_IN_CART': {
      const secondaryButtonMap = {
        checkout: {
          message: 'Checkout Without Discount',
          onClick: checkoutRemoveDiscount
        },
        pay: undefined
      }

      return {
        title: `Your Discount Isn't Applied Yet`,
        messages: [
          `The items in your cart don't qualify for the discount code you've added.`,
          `You can continue without a discount, or browse more products to add to your cart.`
        ],
        primaryButton: {
          message: 'Go to Shop page',
          onClick: navigateBack
        },
        secondaryButton: secondaryButtonMap[origin]
      }
    }

    case 'NO_TARGET_PRODUCTS_IN_CART': {
      const secondaryButtonMap = {
        checkout: {
          message: 'Checkout Without Discount',
          onClick: checkoutRemoveDiscount
        },
        pay: undefined
      }

      return {
        title: `Your Discount Can't Be Applied`,
        messages: [
          `The items in your cart don't qualify for the discount code you've added.`,
          `You can continue without a discount, or browse more products to add to your cart.`
        ],
        primaryButton: {
          message: 'Add More Items',
          onClick: navigateBack
        },
        secondaryButton: secondaryButtonMap[origin]
      }
    }

    case 'NO_TARGET_VARIANTS_IN_CART': {
      const secondaryButtonMap = {
        checkout: {
          message: 'Checkout Without Discount',
          onClick: checkoutRemoveDiscount
        },
        pay: undefined
      }

      return {
        title: `Your Discount Can't Be Applied`,
        messages: [
          `The items in your cart don't qualify for the discount code you've added.`,
          `You can continue without a discount, or browse more products to add to your cart.`
        ],
        primaryButton: {
          message: 'Add More Items',
          onClick: navigateBack
        },
        secondaryButton: secondaryButtonMap[origin]
      }
    }

    case 'TOTAL_DISCOUNT_IS_ZERO': {
      const secondaryButtonMap = {
        checkout: {
          message: 'Checkout Without Discount',
          onClick: checkoutRemoveDiscount
        },
        pay: undefined
      }

      return {
        title: `Your Discount Isn't Applied`,
        messages: [
          `You've entered a discount code, but it currently offers a £0 discount on your order.`,
          `Review your cart to ensure that the product you entered is valid for the discount.`
        ],
        primaryButton: {
          message: 'Review your Cart',
          onClick: navigateBack
        },
        secondaryButton: secondaryButtonMap[origin]
      }
    }

    case 'DISCOUNT_INVALID_FULFILLMENT_TYPE': {
      const fulfillmentType =
        currentFulfillmentType && capitalizeWords(currentFulfillmentType)

      const lowercaseFulfillmentType =
        currentFulfillmentType === 'ORDER_AT_TABLE'
          ? 'table orders'
          : currentFulfillmentType?.replace(/_/g, ' ').toLowerCase()

      const primaryButtonMap = {
        checkout: {
          message: 'Checkout Without Discount',
          onClick: checkoutRemoveDiscount
        },
        pay: {
          message: 'Review your Cart',
          onClick: navigateBack
        }
      }

      return {
        title: `Discount Not Valid for ${fulfillmentType}`,
        messages: [
          `This discount code cannot be used for ${lowercaseFulfillmentType}.`
        ],
        primaryButton: primaryButtonMap[origin],
        secondaryButton: undefined
      }
    }

    case 'DISCOUNT_NOT_APPLICABLE_TO_STORE': {
      const secondaryButtonMap = {
        checkout: {
          message: 'Checkout Without Discount',
          onClick: checkoutRemoveDiscount
        },
        pay: undefined
      }

      return {
        title: `Oops! Discount Not Applicable Here`,
        messages: [
          `The discount code you've entered cannot be used at this store.`,
          `Review the discount details to see if it's valid for a different store close to you.`
        ],
        primaryButton: {
          message: 'Update Store',
          onClick: navigateBack
        },
        secondaryButton: secondaryButtonMap[origin]
      }
    }
    default: {
      return {
        title: 'Oops, something has gone wrong',
        messages: [
          'We have encountered an unexpected problem. Please go back and try again.'
        ],
        primaryButton: {
          message: 'Try again',
          onClick: () => history.push('/')
        }
      }
    }
  }
}

interface DiscountErrorModalProps {
  errorCode: string
  checkoutRemoveDiscount: () => void
  storeSlug?: string
  currentFulfillmentType: FulfillmentType | undefined
  values?: {
    minimumOrderValue?: string
  }
  origin?: 'checkout' | 'pay'
}

const DiscountErrorModal = (props: DiscountErrorModalProps) => {
  const history = useHistory()
  const { openModal, isModalOpen } = useModal()

  // if we have opened the Login Modal as part of the ErrorModal CTA, don't render the ErrorModal underneath.
  if (isModalOpen('login')) return <></>
  return (
    <ErrorModal
      errorCode={props.errorCode}
      errorTitle={
        discountErrorGlossary({
          history,
          openModal,
          ...props
        }).title
      }
      errorMessages={
        discountErrorGlossary({
          history,
          openModal,
          ...props
        }).messages
      }
      primaryButton={
        discountErrorGlossary({
          history,
          openModal,
          ...props
        }).primaryButton
      }
      secondaryButton={
        discountErrorGlossary({
          history,
          openModal,
          ...props
        }).secondaryButton
      }
    />
  )
}

export default DiscountErrorModal
