import { useCallback, useEffect, useState } from 'react'
import { useShop } from 'shop/hooks'
import { throttle } from 'lodash'
import { CategoryWithProducts } from '../Shop/Categories/types'
import { CategoryGrouping } from './CategoryGrouping'
import { LoadingContainer } from './LoadingContainer'
import { EmptyState } from './EmptyState'
import { useShopPage } from 'shop/hooks'
import { ProductListMeta } from 'shop/components'

interface Props {
  selectedCategory: string
  categories: CategoryWithProducts[]
  setActiveCategory: (key: string) => void
}

const ProductList = ({
  categories,
  selectedCategory,
  setActiveCategory
}: Props) => {
  const { isStoreLoading, isProductsLoading } = useShop()

  const { isAutoScrolling, searchValue, setSearchValue } = useShopPage()

  const [categoryElements, setCategoryElements] = useState<{
    [key: string]: { top: number; bottom: number }
  }>({})

  const pageWidth = window.innerWidth

  useEffect(() => {
    if (isStoreLoading || isProductsLoading) {
      return
    }

    const offsetY = window.pageYOffset

    const elementsUpdate = categories.reduce((acc, cur) => {
      const element = document.getElementById(
        `product-category-${cur.id || cur.name}`
      )

      if (!element) {
        return acc
      }

      return {
        ...acc,
        [cur.id || cur.name]: {
          top: element.getBoundingClientRect().top + offsetY,
          bottom: element.getBoundingClientRect().bottom + offsetY
        }
      }
    }, {})

    setCategoryElements(elementsUpdate)
  }, [isStoreLoading, isProductsLoading, categories, pageWidth])

  const handleSetActiveCategory = useCallback(
    (key: string) => {
      if (selectedCategory === key) {
        return
      }

      setActiveCategory(key)
    },
    [selectedCategory, setActiveCategory]
  )

  const handleScroll = useCallback(() => {
    if (isAutoScrolling) {
      return
    }

    const keys = Object.keys(categoryElements)
    const pageBottomOffset = 100

    if (
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight - pageBottomOffset
    ) {
      handleSetActiveCategory(keys[keys.length - 1])

      return
    }

    const pageViewPointOffset = 200

    keys.forEach((key) => {
      if (!categoryElements[key]) {
        return
      }

      const upperBound = categoryElements[key].bottom
      const lowerBound = categoryElements[key].top

      if (
        window.scrollY + pageViewPointOffset <= upperBound &&
        window.scrollY + pageViewPointOffset >= lowerBound
      ) {
        handleSetActiveCategory(key)
      }
    })
  }, [categoryElements, handleSetActiveCategory, isAutoScrolling])

  useEffect(() => {
    const onScroll = throttle(handleScroll, 300)

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [handleScroll])

  if (isStoreLoading || isProductsLoading) {
    return <LoadingContainer />
  }

  if (
    !categories.length ||
    (categories.length === 1 && !categories[0].products.length)
  ) {
    return (
      <EmptyState searchValue={searchValue} setSearchValue={setSearchValue} />
    )
  }

  return (
    <>
      <ProductListMeta
        products={categories.flatMap((category) => category.products)}
      />
      <CategoryGrouping categories={categories} />
    </>
  )
}

export default ProductList
