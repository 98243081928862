import { SelectedModifiers } from '../Landing'

export const ALLERGENS = {
  celery: 'Celery',
  cereals: 'Cereals containing gluten',
  crustaceans: 'Crustaceans',
  eggs: 'Eggs',
  fish: 'Fish',
  gluten: 'Gluten',
  lupin: 'Lupin',
  milk: 'Milk',
  molluscs: 'Molluscs',
  mustard: 'Mustard',
  nuts: 'Nuts',
  peanuts: 'Peanuts',
  sesame: 'Sesame',
  soybeans: 'Soybeans',
  sulphur: 'Sulphur dioxide and sulphites',
  treenuts: 'Tree nuts',
  wheat: 'Wheat'
}

export const DIETS = {
  VEGAN: {
    label: 'Ve',
    name: 'Vegan'
  },
  VEGETARIAN: {
    label: 'V',
    name: 'Vegetarian'
  },
  GLUTEN_FREE: {
    label: 'GF',
    name: 'Gluten Free'
  },
  DAIRY_FREE: {
    label: 'DF',
    name: 'Dairy Free'
  },
  HALAL: {
    label: 'H',
    name: 'Halal'
  },
  PALEO: {
    label: 'P',
    name: 'Paleo'
  },
  KETO: {
    label: 'K',
    name: 'Keto'
  }
}

export type Allergens = (typeof ALLERGENS)[keyof typeof ALLERGENS]

export type OptionsAllergens = Record<string, Record<string, Array<Allergens>>>

export type ProductOptions = {
  name: string
  values: string[]
}

export interface AppliedModifiers {
  [key: string]: SelectedModifiers
}

export type ProductModifierGroup = {
  id: string
  minimum: number | null
  modifiers: ProductModifier[]
  maximum: number | null
  name: string
}

export type ProductModifier = {
  id: string
  image: ProductImageURLs
  inStock: boolean
  name: string
  price: Price
  restrictions: {
    alcoholic: boolean
  }
  allergens: Array<Allergens>
}

export type ProductImageURLs = {
  thumb: string
  standard: string
  original: string
}

export interface SelectedOption {
  name: string
  value: string
}

export type Price = {
  basePrice: string
}
