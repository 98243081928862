import { useState, createContext, useContext } from 'react'

type ShopPageState = {
  isSearching: boolean
  searchValue: string
  setSearchValue: (value: string) => void
  isAutoScrolling: boolean
  handleAutoScrolling: () => void
}

export const SetupShopPageStore = (): ShopPageState => {
  const [isAutoScrolling, setIsAutoScrolling] = useState(false)
  const [autoScrollTimeout, setAutoScrollTimeout] = useState<null | ReturnType<
    typeof setTimeout
  >>(null)
  const [searchValue, setSearchValue] = useState('')

  const handleAutoScrolling = () => {
    if (autoScrollTimeout) {
      clearTimeout(autoScrollTimeout)
    }

    setIsAutoScrolling(true)

    const scrollDuration = 700
    const timeout = setTimeout(() => setIsAutoScrolling(false), scrollDuration)
    setAutoScrollTimeout(timeout)
  }

  const isSearching = searchValue.length >= 3

  return {
    isSearching,
    searchValue,
    setSearchValue,
    isAutoScrolling,
    handleAutoScrolling
  }
}

const initialState: ShopPageState = {
  isSearching: false,
  searchValue: '',
  setSearchValue: () => {},
  isAutoScrolling: false,
  handleAutoScrolling: () => {}
}

export const ShopPageContext = createContext<ShopPageState>(initialState)

export const useShopPage = () => {
  return useContext(ShopPageContext)
}
