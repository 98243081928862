import { CheckoutForm, FORM_DEFAULTS } from './types'
import { ErrorOption, FieldError } from 'react-hook-form'
import { findErrorWarning, formatMoney } from '../Cart/utils'
import {
  BaseAddress,
  CartErrorMessage,
  CartWarningMessage,
  ConsumerCart,
  ConsumerCartError,
  ConsumerCartWarning,
  DiscountError,
  DiscountWarning,
  DiscountWarningCode,
  DomesticAddress,
  ExtendedOrderItem,
  FullAddress,
  LoyaltyCard,
  MinimumValueWarning,
  OrderItem,
  RewardStatus,
  CheckoutErrorCode
} from 'shop/types/cart'
import { CountryCode } from 'shop/types'
import { keysHaveValues } from 'shop/utils/common'
import { default as countryCodes } from 'shop/countryCodes.json'
import parsePhoneNumberFromString from 'libphonenumber-js'

export const onlySpaces = (str: string) => {
  return /^\s*$/.test(str)
}

export const ukNumberRegex =
  /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/

export const redirectUrl = (
  transactionId: string,
  withTracking: boolean = false
) => {
  const origin = window.location.origin
  if (withTracking) return `${origin}/order/purchase/${transactionId}`
  return `${origin}/order/track/${transactionId}`
}

export type DeliveryAddressErrorCode =
  | CartErrorMessage.COULD_NOT_GEOCODE
  | CartWarningMessage.ADDRESS_OUTSIDE_DELIVERY_AREA
  | CartWarningMessage.INVALID_ADDRESS

export type CartErrorCode =
  | CartWarningMessage.SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE
  | CartWarningMessage.TOTAL_BELOW_MINIMUM_ORDER_VALUE

export type CartProductErrorCode =
  | CartErrorMessage.PRODUCTS_OUT_OF_STOCK
  | CartErrorMessage.PRODUCTS_UNAVAILABLE
  | CartErrorMessage.PRODUCT_PRICES_CHANGED
  | CartErrorMessage.INVALID_MODIFIERS

export type DiscountErrorCode =
  | CartErrorMessage.DISCOUNT_INVALID
  | CartErrorMessage.DISCOUNT_EXPIRED
  | CartErrorMessage.DISCOUNT_LIMIT_REACHED

/** Type guard function to check if a value is a valid DeliveryAddressErrorCode */
export const isDeliveryAddressErrorCode = (
  value: CheckoutErrorCode
): value is DeliveryAddressErrorCode => {
  return [
    CartErrorMessage.COULD_NOT_GEOCODE,
    CartWarningMessage.ADDRESS_OUTSIDE_DELIVERY_AREA,
    CartWarningMessage.INVALID_ADDRESS
  ].includes(value as DeliveryAddressErrorCode)
}

export const isCartErrorCode = (
  value: CheckoutErrorCode
): value is CartErrorCode => {
  return [
    CartWarningMessage.SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE,
    CartWarningMessage.TOTAL_BELOW_MINIMUM_ORDER_VALUE
  ].includes(value as CartErrorCode)
}

export const isDiscountErrorCode = (
  validation: CheckoutErrorCode
): validation is DiscountErrorCode => {
  return [
    CartErrorMessage.DISCOUNT_INVALID,
    CartErrorMessage.DISCOUNT_EXPIRED,
    CartErrorMessage.DISCOUNT_LIMIT_REACHED
  ].includes(validation as DiscountErrorCode)
}

export const isDiscountWarningCode = (
  validation: CheckoutErrorCode
): validation is DiscountWarningCode => {
  return [
    CartWarningMessage.NO_TARGET_CATEGORIES_IN_CART,
    CartWarningMessage.NO_TARGET_PRODUCTS_IN_CART,
    CartWarningMessage.NO_TARGET_VARIANTS_IN_CART,
    CartWarningMessage.TOTAL_DISCOUNT_IS_ZERO,
    CartWarningMessage.DISCOUNT_MINIMUM_VALUE_NOT_MET,
    CartWarningMessage.DISCOUNT_INVALID_FULFILLMENT_TYPE,
    CartWarningMessage.DISCOUNT_NOT_APPLICABLE_TO_STORE,
    CartWarningMessage.CUSTOMER_LOGIN_REQUIRED
  ].includes(validation as DiscountWarningCode)
}

export const isTimeSlotError = (
  validation: CheckoutErrorCode
): validation is CartWarningMessage.INVALID_TIMESLOT => {
  return validation === CartWarningMessage.INVALID_TIMESLOT
}

export const isCartProductError = (
  validation: CheckoutErrorCode
): validation is CartProductErrorCode => {
  return [
    CartErrorMessage.INVALID_MODIFIERS,
    CartErrorMessage.PRODUCTS_OUT_OF_STOCK,
    CartErrorMessage.PRODUCTS_UNAVAILABLE,
    CartErrorMessage.PRODUCT_PRICES_CHANGED
  ].includes(validation as CartProductErrorCode)
}

/** Returns UI messages based on consumer cart delivery address validation errors/warnings */
export const deliveryAddressErrorGlossary = (
  errorCode: DeliveryAddressErrorCode
): string | null => {
  switch (errorCode) {
    case CartWarningMessage.INVALID_ADDRESS:
    case CartErrorMessage.COULD_NOT_GEOCODE:
      return 'Sorry we are unable to find your address. Please try again with a different address, or choose pickup'
    case CartWarningMessage.ADDRESS_OUTSIDE_DELIVERY_AREA:
      return 'Unfortunately, we do not currently deliver to your address'
    default:
      return null
  }
}

/** Returns UI messages based on consumer cart delivery address validation errors/warnings */
export const cartErrorGlossary = (
  errorCode: CartErrorCode,
  hasDiscount: boolean = false,
  minimumValue?: string
): string | null => {
  switch (errorCode) {
    case CartWarningMessage.SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE:
    case CartWarningMessage.TOTAL_BELOW_MINIMUM_ORDER_VALUE:
      const minimumValueText = !!minimumValue
        ? ` of ${formatMoney(parseFloat(minimumValue))}`
        : ''
      const ctaDescription = hasDiscount
        ? `you can either keep shopping, or check out without your discount code.`
        : `take another look at our shop page and add more items to you cart.`
      return `Your cart total doesn't meet the minimum value${minimumValueText} required to check out. To continue, ${ctaDescription}`
    default:
      return null
  }
}

/** Takes warnings/errors array and finds error object using guard function */
export const findTopPrioErrorWarning = (
  errors: ConsumerCartError[],
  warnings: ConsumerCartWarning[],
  errorCodeGuardFunc: (validation: CheckoutErrorCode) => boolean
): ConsumerCartWarning | ConsumerCartError | null => {
  const foundError = findErrorWarning(errors, errorCodeGuardFunc)
  if (foundError) return foundError

  const foundWarning = findErrorWarning(warnings, errorCodeGuardFunc)
  if (foundWarning) return foundWarning
  return null
}

/** Takes warnings/errors, finds the highest priority Cart error and sets it in the form for the user to see.
 * @returns The error code for reference
 */
export const findAndSetCartErrors = (
  errors: ConsumerCartError[],
  warnings: ConsumerCartWarning[],
  hasSubtotalDiscount: boolean,
  setFormError: (name: string, error: ErrorOption) => void,
  clearErrors: (name: string) => void
): CartErrorCode | null => {
  // Find the top priority error or warning
  const cartError = findTopPrioErrorWarning(
    errors,
    warnings,
    isCartErrorCode
  ) as ConsumerCartWarning | ConsumerCartError | null

  if (cartError) {
    const cartErrorCode = cartError?.message as CartErrorCode
    let minimumValue
    // extract MOV for the appropriate errors/warnings
    if (
      cartErrorCode === CartWarningMessage.TOTAL_BELOW_MINIMUM_ORDER_VALUE ||
      cartErrorCode === CartWarningMessage.SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE
    ) {
      minimumValue = (cartError as MinimumValueWarning).minimumValue
    }

    const cartErrorMessage = cartErrorGlossary(
      cartErrorCode,
      hasSubtotalDiscount,
      minimumValue
    )

    if (cartErrorMessage) {
      setFormError('checkout_cart', {
        type: 'manual',
        message: cartErrorMessage
      })
      return cartErrorCode
    }
  } else {
    // Clear any previously set errors
    clearErrors('checkout_cart')
  }
  return null
}

/** Takes warnings/errors, finds the highest priority Delivery error and sets it in the form for the user to see.
 * @returns The error code for reference
 */
export const findAndSetDeliveryErrors = (
  errors: ConsumerCartError[],
  warnings: ConsumerCartWarning[],
  setFormError: (name: string, error: ErrorOption) => void,
  clearErrors: (name: string) => void
) => {
  let deliveryErrorCode: DeliveryAddressErrorCode | undefined

  // Find delivery error code from cart errors
  deliveryErrorCode = findTopPrioErrorWarning(
    errors,
    warnings,
    isDeliveryAddressErrorCode
  )?.message as DeliveryAddressErrorCode

  if (deliveryErrorCode) {
    const deliveryErrorMessage = deliveryAddressErrorGlossary(deliveryErrorCode)
    if (deliveryErrorMessage) {
      setFormError('address_api', {
        type: 'manual',
        message: deliveryErrorMessage
      })
      return deliveryErrorCode
    }
  } else {
    // clear any previously set errors
    clearErrors('address_api')
  }
  return null
}

/** Returns discount errors and warnings, prioritising errors over warnings */
export const getCurrentDiscountWarningErrors = (
  consumerCartErrors: ConsumerCartError[],
  consumerCartWarnings: ConsumerCartWarning[]
) => {
  return [...consumerCartErrors, ...consumerCartWarnings].filter(
    (errorWarning) =>
      findErrorWarning([errorWarning], isDiscountErrorCode) ||
      findErrorWarning([errorWarning], isDiscountWarningCode)
  ) as (DiscountError | DiscountWarning)[]
}

/** Default address error message for consumer cart delivery address errors/warnings */
export const defaultCheckoutErrorMessage =
  'We have encountered an unexpected error. Please contact support@slerp.com, or refresh the page'

export const inputCharactersLimitValidator =
  (name = 'N/A', limit = 255) =>
  (value: string) => {
    if (value.length > limit) {
      return `Character limit in the ${name} is ${limit}, please remove ${
        value.length - limit
      } character(s)`
    }
    return true
  }

export const hasCustomField = (
  cart_details: {
    fulfillment_type: string
    id: string
  },
  field_details: {
    enabled: boolean
    name: string
    fulfillment_types: string[]
  }
) => {
  const { enabled, name, fulfillment_types } = field_details
  const isNameValid = name && name !== ''
  const areFulfillmentTypesValid =
    fulfillment_types && fulfillment_types.length > 0

  if (!enabled || !isNameValid || !areFulfillmentTypesValid) return false
  return fulfillment_types.includes(cart_details.fulfillment_type)
}

export const isAddressParamsValid = (address: DomesticAddress) => {
  if (address.lineOne === '') return false
  if (address.zip.length < 5) return false

  return true
}

export const focusNextInput = (inputList: string[]) => {
  const currentInputId = document.activeElement?.id
  let nextInputId = ''
  if (currentInputId) {
    inputList.every((input, index) => {
      if (currentInputId.includes(input)) {
        if (index + 1 > inputList.length) return false
        nextInputId = `${inputList[index + 1]}`
        return false
      }
      return true
    })
    const nextInputEl = document.getElementById(nextInputId)
    if (nextInputEl) {
      // Very weird bug with JS.
      // Needs a timeout of 1ms so that the focus allows you to type. ¯\_(ツ)_/¯
      setTimeout(() => {
        nextInputEl.focus()
      }, 1)
    }
  }
}

export const getAddressErrorMessage = (errors: {
  [key: string]: FieldError
}) => {
  const errorKeysArray = Object.keys(errors)
  const lastErrorKey = errorKeysArray[errorKeysArray.length - 1]
  const lastError = errors[lastErrorKey]

  if (lastError.message && lastError.message !== '') {
    return lastError.message
  }

  return `${addressFieldGlossary(lastErrorKey)} ${addressErrorGlossary(
    lastError.type
  )}.`
}

const addressFieldGlossary = (key: string) => {
  switch (key) {
    case 'line_1':
      return 'Street address'
    case 'zip':
      return 'Post code'
    case 'city':
      return 'City'
    default:
      return 'The field'
  }
}

const addressErrorGlossary = (type: string) => {
  switch (true) {
    case type === 'isRequired':
      return 'is required'
    case /^length_\d+$/.test(type):
      const amount = type.split('_')[1]
      return `must be at least ${amount} characters`
    default:
      return 'is incorrect'
  }
}

export const hasAddressErrors = (addressErrors: {
  [key: string]: FieldError
}): boolean => {
  return Boolean(addressErrors) && Boolean(Object.values(addressErrors).length)
}

const CUSTOMER_PREFIX = 'customerDetails'
export const CUSTOMER_INPUTS = {
  firstNameInputId: `${CUSTOMER_PREFIX}[firstName]`,
  lastNameInputId: `${CUSTOMER_PREFIX}[lastName]`,
  emailInputId: `${CUSTOMER_PREFIX}[email]`,
  phonePrefixId: `${CUSTOMER_PREFIX}[contactNumPrefix]`,
  phoneInputId: `${CUSTOMER_PREFIX}[contactNumber]`
}

export const RECIPIENT_PREFIX = 'recipientDetails'
export const RECIPIENT_INPUTS = {
  firstNameInputId: `${RECIPIENT_PREFIX}[firstName]`,
  lastNameInputId: `${RECIPIENT_PREFIX}[lastName]`,
  phonePrefixId: `${RECIPIENT_PREFIX}[contactNumPrefix]`,
  phoneInputId: `${RECIPIENT_PREFIX}[contactNumber]`
}

export const SHIPPING_PREFIX = 'deliveryAddress'
export const SHIPPING_INPUTS = {
  line1InputId: `${SHIPPING_PREFIX}[lineOne]`,
  line2InputId: `${SHIPPING_PREFIX}[lineTwo]`,
  zipInputId: `${SHIPPING_PREFIX}[zip]`,
  cityInputId: `${SHIPPING_PREFIX}[city]`,
  dropoffNotesInputId: 'dropoffNotes',
  saveAddressInputId: 'saveAddress'
}

/** For phone number inputs */
export const trimAndRemoveLeadingZero = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  let { value } = event.target
  value = value.trim()
  if (value.startsWith('0')) {
    value = value.slice(1)
  }
  event.target.value = value
  return value
}

/** Temp: Strips +44 prefixes from contact nums */
export const stripDialCodePrefix = (contactNum: string) => {
  const phoneNumber = parsePhoneNumberFromString(contactNum)
  return phoneNumber ? phoneNumber.nationalNumber : contactNum
}

export const handlePhoneNumberOnChange = (
  e: React.ChangeEvent<HTMLInputElement>
) => {
  let newValue = trimAndRemoveLeadingZero(e)
  newValue = stripDialCodePrefix(newValue)
  e.target.value = newValue
}

export const getFormDefaultValue = (
  field: string,
  sources: (ConsumerCart | CheckoutForm | null)[]
) => {
  for (const source of sources) {
    const value = source?.[field]

    if (value) {
      if (typeof value === 'object') {
        if (keysHaveValues(Object.keys(FORM_DEFAULTS[field]), value)) {
          return value
        }
      }
      return value
    }
  }

  return FORM_DEFAULTS[field]
}

export const getPrefilledAddress = (
  loadedCart: ConsumerCart | null,
  tempSavedCheckoutValues: CheckoutForm | null,
  defaults: DomesticAddress
) => {
  const deliveryAddressKeys = Object.keys(FORM_DEFAULTS.deliveryAddress)

  const tempCheckoutFormHasValues =
    !!tempSavedCheckoutValues?.deliveryAddress &&
    keysHaveValues(deliveryAddressKeys, tempSavedCheckoutValues.deliveryAddress)

  const cartDeliveryAddressHasValues =
    !!loadedCart?.deliveryAddress &&
    keysHaveValues(deliveryAddressKeys, loadedCart.deliveryAddress)

  let address = defaults

  if (cartDeliveryAddressHasValues) {
    address = loadedCart.deliveryAddress
  } else if (tempSavedCheckoutValues && tempCheckoutFormHasValues) {
    address = tempSavedCheckoutValues.deliveryAddress
  }

  const { lineOne, lineTwo, city, zip } = address

  return {
    lineOne: lineOne || '',
    lineTwo: lineTwo || '',
    city: city || '',
    zip: zip || ''
  }
}

export const findFlagFromCountryCode = (countryCode: string) => {
  if (!countryCode) return ''
  return (
    countryCodes.find((option: CountryCode) => option.dial_code === countryCode)
      ?.flag || ''
  )
}

/** Used in Account page */
export const updateContactNum = (
  contactNum: string,
  contactNumPrefix: string
) => {
  let flag = ''
  if (contactNum) {
    const phoneNumber = parsePhoneNumberFromString(
      `${contactNumPrefix}${contactNum}`
    )
    if (phoneNumber?.isValid()) {
      flag = findFlagFromCountryCode(`+${phoneNumber.countryCallingCode}`)
      return {
        contact_num_prefix: `${flag} +${phoneNumber.countryCallingCode}`,
        contact_num: phoneNumber.nationalNumber
      }
    }
  } else if (contactNumPrefix) {
    return {
      contact_num_prefix: `${contactNumPrefix}`,
      contact_num: ''
    }
  }

  return {
    contact_num_prefix: '🇬🇧 +44',
    contact_num: ''
  }
}

export const updateContactNumCheckout = (
  contactNum: string,
  contactNumPrefix: string
) => {
  let flag = ''
  if (contactNum) {
    const phoneNumber = parsePhoneNumberFromString(
      `${contactNumPrefix}${contactNum}`
    )
    if (phoneNumber?.isValid()) {
      flag = findFlagFromCountryCode(`+${phoneNumber.countryCallingCode}`)
      return {
        contactNumPrefix: `${flag} +${phoneNumber.countryCallingCode}`,
        contactNumber: phoneNumber.nationalNumber
      }
    }
  } else if (contactNumPrefix) {
    return {
      contactNumPrefix: `${contactNumPrefix}`,
      contactNumber: ''
    }
  }

  return {
    contactNumPrefix: '🇬🇧 +44',
    contactNumber: ''
  }
}

export const handlePhoneNumberOnBlur = async (
  e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  setValue: (name: string, value: string) => void,
  trigger: (name: string) => void,
  fieldName: string
) => {
  let newValue = trimAndRemoveLeadingZero(e)
  newValue = stripDialCodePrefix(newValue)
  await setValue(fieldName, newValue)

  // Trigger re-validation after setting trimmed/zeros removed value
  trigger(fieldName)
}

export const returnStoreBaseAddress = (
  storeAddress: FullAddress
): BaseAddress => {
  const { lineOne, lineTwo, zip, city, country } = storeAddress || {}
  return {
    lineOne: lineOne || '',
    lineTwo: lineTwo || '',
    zip: zip || '',
    city: city || '',
    country: country || ''
  }
}

export const getApplicableRewards = (loyaltyCards?: LoyaltyCard[]) => {
  if (!loyaltyCards?.length) return []

  return loyaltyCards.flatMap((card) =>
    card.availableRewards.filter(
      ({ isApplicable, status }) =>
        isApplicable && status === RewardStatus.ACTIVE
    )
  )
}

/** Returns values that determine checkout CTA behaviour */
export const getCheckoutCtaValues = (
  loyaltyCards: any[],
  isMobile: boolean,
  hasSeenRewards: boolean
) => {
  const hasApplicableRewards = getApplicableRewards(loyaltyCards)
  const rewardsCtaActive = isMobile && !!hasApplicableRewards.length
  const checkoutPayCtaActive = !rewardsCtaActive || hasSeenRewards
  const checkoutCtaText = checkoutPayCtaActive ? 'Go to Payment' : 'See Rewards'

  return {
    rewardsCtaActive,
    checkoutPayCtaActive,
    checkoutCtaText
  }
}

export const getInvalidItemIds = (
  invalidItems: (OrderItem | ExtendedOrderItem)[]
) => invalidItems.map((invalidItem) => invalidItem.id)

/** Matches against Cart warnings / errors and returns extra fields provided by interface */
export const getDiscountErrorWarningValues = (
  warningError: DiscountWarning | DiscountError
) => {
  switch (warningError.message) {
    case CartWarningMessage.DISCOUNT_MINIMUM_VALUE_NOT_MET:
      return { minimumOrderValue: warningError.minimumValue }
    default:
      return {}
  }
}
