import { useEffect, useState, createRef } from 'react'

interface UseExpandableDescriptionProps {
  forceShowDescription?: boolean
}

const useExpandableDescription = ({
  forceShowDescription = false
}: UseExpandableDescriptionProps) => {
  const ref: React.RefObject<HTMLParagraphElement> = createRef()
  const [hasExpandedText, setHasExpandedText] = useState(forceShowDescription)
  const [maxHeight, setMaxHeight] = useState<number | undefined>()
  const [isTransitioning, setIsTransitioning] = useState(false)

  useEffect(() => {
    // for animation purposes, setting the maxHeight to fit the content
    setMaxHeight(ref.current?.scrollHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.scrollHeight, setMaxHeight])

  const toggleDescriptionExpansion = () => {
    setIsTransitioning(true)
    setHasExpandedText(!hasExpandedText)
    setTimeout(() => {
      setIsTransitioning(false)
    }, 500)
  }

  return {
    ref,
    hasExpandedText,
    maxHeight,
    isTransitioning,
    toggleDescriptionExpansion
  }
}

export default useExpandableDescription
