import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Link } from 'react-router-dom'
import { IoClose as CloseIcon } from 'react-icons/io5'

interface Props {
  parentUrl: string
}

const CloseButton = ({ parentUrl }: Props) => {
  return (
    <Link to={{ pathname: parentUrl }}>
      <Container data-testid='closeButton'>
        <CloseIcon />
      </Container>
    </Link>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    position: 'fixed',
    padding: '4px',
    top: '24px',
    right: '24px',
    borderRadius: '50%',
    // hexadecimal 66 -> opcacity: 40%
    background: '#FFFFFF66',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: theme?.zIndex?.productModal,

    '&:hover': {
      // hexadecimal 99 -> opcacity: 60%
      background: '#EFEFF099'
    },
    '&:active': {
      // hexadecimal CC -> opcacity: 80%
      background: '#D8D8D8CC'
    },
    '> svg': {
      display: 'block',
      height: '20px',
      width: '20px'
    },
    '> svg > path': {
      fill: theme.colors['primary'],
      // 20px container causes the icon path to be w:10.63 h: 10.63
      // we want the icon to be 14px so we can scale it. (14 / 10.63 = 1.317)
      scale: '1.317',
      transformOrigin: 'center'
    }
  })
)

export default CloseButton
