import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { CustomerAddress, LatLng } from 'shop/types'
import { createAddressString } from 'shop/utils/address'
import { CurrentLocationState } from '../types'

export interface GeocodedAddress {
  latLng: LatLng
  formattedAddress: string
  addressComponents: google.maps.GeocoderAddressComponent[]
}

/*===== Form Utils - DeliveryForm / PickupForm =====*/

/** Depending on what type of address is passed in the params, either geocode the address or pass values we already have. */
export const handleAddressSelect = async ({
  singleLineAddress,
  customerAddress,
  geocodedAddress
}: {
  singleLineAddress?: string
  customerAddress?: CustomerAddress
  geocodedAddress?: GeocodedAddress
}): Promise<CurrentLocationState | GeocodedAddress | void> => {
  // raw string address ready to be geocoded
  if (!!singleLineAddress) {
    return geocodeFromAddress(singleLineAddress)
  }

  // an already saved customer address
  if (!!customerAddress?.id) {
    const formattedAddress = createAddressString(customerAddress.address, {
      withCommas: true
    })
    // If the customer address has no LatLng, geocode the address.
    if (
      !customerAddress.address.coordinates?.lat ||
      !customerAddress.address.coordinates.lng
    ) {
      return geocodeFromAddress(formattedAddress)
    }
    // otherwise, return a similar object but with an addressId
    return {
      address: formattedAddress,
      addressId: customerAddress.id,
      addressComponents: [],
      latLng: customerAddress.address.coordinates
    }
  }

  // an already geocoded address
  if (!!geocodedAddress) {
    return geocodedAddress
  }
}

/** Geocode an address and return a formatted object */
export const geocodeFromAddress = async (singleLineAddress: string) => {
  return geocodeByAddress(singleLineAddress)
    .then(async (results) => {
      const latLng = await getLatLng(results[0])
      const formattedAddress = results[0].formatted_address
      const addressComponents = results[0].address_components

      return {
        formattedAddress,
        addressComponents,
        latLng
      }
    })
    .catch((error) => console.error('Geocode Error', error))
}

/** Compare a new Address to the Current Location state and ensure they match and have coordinate values ready to use. */
export const isAddressSameAsCurrentLocation = (
  currentLocation?: CurrentLocationState,
  newAddress?: string
): boolean => {
  if (!currentLocation || !newAddress) return false
  const { latLng, address } = currentLocation
  const hasLatLng = latLng.lat && latLng.lng
  const isMatchingAddress = address === newAddress

  // we are selecting the same address and we already have coordinates
  return !!(isMatchingAddress && hasLatLng)
}

/*===== END - Form Utils - DeliveryForm / PickupForm =====*/
