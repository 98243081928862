/* global google */

import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import React, { useEffect, useState, useRef } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import uuidv4 from 'uuid/v4'
import { GeocodedAddress } from './utils'
import { useMediaQueries, useShop } from 'shop/hooks'
import { trackGA4CustomSearchByAddressSuggestion } from 'tracker/GA/custom'

type PlacesAutocompleteRef = PlacesAutocomplete & {
  fetchPredictions: () => void
}

type AddressSuggestionsProps = {
  inputValue: string
  onChange: (value: string) => void
  onSelect: ({
    newAddress,
    geocodedAddress
  }: {
    newAddress?: string
    geocodedAddress?: GeocodedAddress
  }) => void
  setHasUserChangedInput: React.Dispatch<React.SetStateAction<boolean>>
}

export const isAddressEligible = (inputValue: string) => {
  const strippedAddressLength = inputValue.replace(/\s/g, '')
  const isEligible = strippedAddressLength.length >= 3

  return isEligible
}

const AddressSuggestions = ({
  inputValue,
  onChange,
  onSelect,
  setHasUserChangedInput
}: AddressSuggestionsProps) => {
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false)
  const placesAutocompleteRef = useRef<PlacesAutocompleteRef>(null)
  const { isMobile } = useMediaQueries()
  const { setIsSortedPartnerStoresLoading } = useShop()

  const searchOptions = {
    location: new google.maps.LatLng({ lat: 51.5137137, lng: -0.1285204 }),
    radius: 30,
    sessiontoken: uuidv4()
  }

  useEffect(() => {
    if (isAddressEligible(inputValue)) {
      setShouldFetchSuggestions(true)
      placesAutocompleteRef.current?.fetchPredictions()
    } else {
      setShouldFetchSuggestions(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  const handleAddressSuggestionSelect = (address: string) => {
    trackGA4CustomSearchByAddressSuggestion('slerpGA4Tracker')
    trackGA4CustomSearchByAddressSuggestion('merchantGA4Tracker')

    setIsSortedPartnerStoresLoading(true)
    setShouldFetchSuggestions(false)
    onSelect({ newAddress: address })
    // stop fetching suggestions after selecting an address
    setHasUserChangedInput(false)
  }

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      debounce={500}
      shouldFetchSuggestions={shouldFetchSuggestions}
      highlightFirstSuggestion={!isMobile}
      ref={placesAutocompleteRef}
      onChange={onChange}
      onSelect={(address: string) => handleAddressSuggestionSelect(address)}
      value={inputValue}
    >
      {(placesProps) => {
        const { getSuggestionItemProps } = placesProps
        return (
          <AutoCompleteInputGroup data-testid='address-autocomplete'>
            <div className='autocomplete-dropdown'>
              {placesProps.suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? 'suggestion-item suggestion-item-active'
                  : 'suggestion-item'

                return (
                  <div
                    data-testid={`addressSuggestion`}
                    {...{
                      ...getSuggestionItemProps(suggestion, { className }),
                      key: `address-suggestion-${i}`
                    }}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </AutoCompleteInputGroup>
        )
      }}
    </PlacesAutocomplete>
  )
}
const AutoCompleteInputGroup = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    position: 'relative',
    '.autocomplete-dropdown': {
      fontSize: theme.fontSizes[1],
      color: theme.colors.textBody,
      width: '100%',
      zIndex: 10
    },
    '.suggestion-item': {
      background: '#ffffff',
      cursor: 'pointer',
      padding: '16px 16px',
      lineHeight: '16px',
      marginBottom: '4px',
      color: theme.colors.body,

      [theme.mediaQueries.viewport7]: {
        padding: '16px 12px'
      }
    },
    '.suggestion-item:last-child': {
      marginBottom: '0'
    },
    '.suggestion-item-active': {
      background: '#FAFAFA',
      borderRadius: '0',

      [theme.mediaQueries.viewport7]: {
        borderRadius: '12px'
      }
    }
  })
)

export default AddressSuggestions
