import { BasePrice, FulfillmentType, Image, LatLng, OrderType } from './common'

export interface AppliedModifiersParams {
  modifierGroupId: string
  modifierId: string
  quantity: number
}

export interface TipValues {
  amount: string
  percentage: string
}

// Non hasura cart/order types:
export interface ConsumerCart {
  additionalItems: AdditionalItems
  customField: CustomField | null
  customerDetails: CustomerDetailsParams
  deliveryAddress: FullAddress
  dropoffNotes: string | null
  fulfillment: Fulfillment
  id: string
  loyaltyCards: LoyaltyCard[]
  marketingOptIn: boolean
  minimumOrderValue: string | null
  orderItems: OrderItem[]
  orderNotes: string | null
  recipientDetails: RecipientDetailsParams
  tipValues: TipValues[]
  store: {
    id: string
    address: FullAddress
    slug: string
    name: string
  }
  summary: Summary
  saveAddress: boolean
}

export interface Order extends Omit<ConsumerCart, 'minimumOrderValue'> {
  cartId: string
  delivery: Delivery
  deliveryAddress: FullAddress
  fulfilledAt: string | null
  isEligibleForAccountCreation: boolean
  isTrackable: boolean
  loyalty: Loyalty
  status: string
  pickupStatus: string
  reference: string
  trackingToken: string
}

interface Driver {
  contactNumber: string
  firstName: string
  lastName: string
  coordinates: {
    lat: number
    lng: number
  }
  transportType: string
}

export interface Delivery {
  pincode: string | null
  driver: Driver | null
  dropoffTime: string | null
  service: string | null
  status: string | null
}

interface CustomField {
  name: string
  value: string
}

export interface OrderItem {
  id: string
  image: Image
  modifierGroups: ModifierGroup[]
  name: string
  options: Option[] | null
  product: {
    id: string
    name: string
  }
  quantity: number
  restrictions: {
    alcoholic: boolean
  }
  total: Price
  variantId: string
  variantPrice: Price
}

export interface ExtendedOrderItem extends OrderItem {
  newPriceWithVat: string
  oldPriceWithVat: string
}

export interface Option {
  name: string
  values: string[]
}

export interface ModifierGroup {
  name: string
  modifiers: Modifier[]
}

export interface Modifier {
  name: string
  quantity: number
  price: Price
}

export interface Price extends BasePrice {
  discounted: string | null
  reduction: string | null
}

export interface Summary {
  delivery: Price | null
  deliveryChargeInfo: DeliveryChargeInfo
  discount: Discount | null
  reward: SummaryReward | null
  subtotal: Price
  storeFee: { price: Price | null } | null
  tip: string | null
  total: Price
}

export interface Discount {
  amount: number
  code: string
  description: string | null
}

export interface SummaryReward {
  id: string
  amount: string
  description: string
  name: string
}

export interface DeliveryChargeInfo {
  reductionReason: string | null
  pricingBand: {
    lowerThreshold: string
    percentageDiscount: number
    upperThreshold: string
  } | null
}

export interface AdditionalItems {
  giftWrapping: {
    message: string
    price: BasePrice
  } | null
}

interface FulfillmentWindow {
  to: string
  from: string
}

export interface Fulfillment {
  type: FulfillmentType
  orderType: OrderType
  tableNumber: string | null
  window: FulfillmentWindow
}

export interface BaseAddress {
  lineOne: string
  lineTwo: string
  city: string
  country: string
  zip: string
}

export interface DomesticAddress extends Omit<BaseAddress, 'country'> {}

export interface FullAddress extends BaseAddress {
  coordinates?: LatLng
  confidence?: 'LOW' | 'HIGH' // this may only be appropriate for addresses that require confidence, e.g. checkout page
}

export interface CustomerDetailsParams {
  contactNumber: string
  email: string
  firstName: string
  lastName: string
}

type RecipientDetailsParams = Omit<CustomerDetailsParams, 'email'>

export interface ConsumerCartPayload {
  storeId: string
  fulfillmentType: FulfillmentType
  fulfillmentDate: string
  fulfillmentTime: string | null
  addressId?: string | null // customer address id
  deliveryAddress?: BaseAddress | null
  customerDetails?: CustomerDetailsParams
  tableNumber?: string
}

export interface ConsumerCartUpdatePayload {
  customFieldValue?: string
  customerDetails?: CustomerDetailsParams
  dropoffNotes?: string
  giftWrapMessage?: string | null
  marketingOptIn?: boolean
  orderNotes?: string
  recipientDetails?: RecipientDetailsParams
  tipValue?: Number
  saveAddress?: boolean
}

export interface ConsumerCartFulfillmentUpdatePayload {
  deliveryAddress?: BaseAddress
  fulfillmentDate?: string
  fulfillmentType?: FulfillmentType
  fulfillmentTime?: string
  proceedWithNewPrices?: boolean
  storeId?: string
  tabeleNumber?: string
}

export interface ConsumerCartRemoveOrderItemsPayload {
  ids: string[]
}

export interface ConsumerCartCreateOrderItemPayload {
  modifiers?: AppliedModifiersParams[]
  quantity: number
  variantId: string
  proceedWithNewOrderItems?: boolean
}

export interface ConsumerCartUpdateOrderItemQuantityPayload {
  id: string
  quantity: number
  proceedWithNewOrderItems?: boolean
}

type ResponseKeys =
  | 'applyDiscount'
  | 'applyReward'
  | 'createCart'
  | 'removeDiscount'
  | 'updateCart'
  | 'updateCartFulfillment'
  | 'createOrderItem'
  | 'removeOrderItems'
  | 'updateOrderItemQuantity'

export type CartMutationResponseWrapper<CartMutationResponse> = {
  [key in ResponseKeys]: CartMutationResponse
}

type CartResponse = CartMutationResponseWrapper<CartMutationResponse>

export type CreateCartResponse = CartResponse
export type ConsumerCartFulfillmentUpdateResponse = CartResponse
export type ConsumerCartApplyDiscountResponse = CartResponse
export type ConsumerCartRemoveDiscountResponse = CartResponse
export type ConsumerCartApplyRewardResponse = CartResponse
export type ConsumerCartUpdateResponse = CartResponse
export type ConsumerCartRemoveOrderItemsResponse = CartResponse
export type ConsumerCartUpdateOrderItemQuantityResponse = CartResponse
export type ConsumerCartCreateOrderItemResponse = CartResponse

export interface CartMutationResponse {
  cart: ConsumerCart
  warnings: ConsumerCartWarning[]
  errors: ConsumerCartError[]
}

export interface ConsumerCartResponse {
  cart: ConsumerCart
}

export interface ConsumerCartValidationsResponse {
  validateCart: ConsumerCartValidations
}

export enum CartErrorMessage {
  CART_ALREADY_ORDERED = 'CART_ALREADY_ORDERED',
  COULD_NOT_GEOCODE = 'COULD_NOT_GEOCODE',
  DELIVERY_ADDRESS_REQUIRED = 'DELIVERY_ADDRESS_REQUIRED',
  DISCOUNT_EXPIRED = 'DISCOUNT_EXPIRED',
  DISCOUNT_INVALID = 'DISCOUNT_INVALID',
  DISCOUNT_LIMIT_REACHED = 'DISCOUNT_LIMIT_REACHED',
  DISCOUNT_NOT_APPLICABLE = 'DISCOUNT_NOT_APPLICABLE',
  DISCOUNT_NOT_APPLICABLE_TO_STORE = 'DISCOUNT_NOT_APPLICABLE_TO_STORE',
  INVALID_MODIFIERS = 'INVALID_MODIFIERS',
  PRODUCT_PRICES_CHANGED = 'PRODUCT_PRICES_CHANGED',
  PRODUCTS_OUT_OF_STOCK = 'PRODUCTS_OUT_OF_STOCK',
  PRODUCTS_UNAVAILABLE = 'PRODUCTS_UNAVAILABLE',
  REWARD_ALREADY_REDEEMED = 'REWARD_ALREADY_REDEEMED',
  REWARD_EXPIRED = 'REWARD_EXPIRED',
  REWARD_REVOKED = 'REWARD_REVOKED',
  TABLE_NUMBER_REQUIRED = 'TABLE_NUMBER_REQUIRED'
}

export enum CartWarningMessage {
  ADDRESS_OUTSIDE_DELIVERY_AREA = 'ADDRESS_OUTSIDE_DELIVERY_AREA',
  CUSTOMER_LOGIN_REQUIRED = 'CUSTOMER_LOGIN_REQUIRED',
  DISCOUNT_NOT_APPLICABLE_TO_STORE = 'DISCOUNT_NOT_APPLICABLE_TO_STORE',
  INVALID_ADDRESS = 'INVALID_ADDRESS',
  INVALID_TIMESLOT = 'INVALID_TIMESLOT',
  DISCOUNT_MINIMUM_VALUE_NOT_MET = 'DISCOUNT_MINIMUM_VALUE_NOT_MET',
  NO_TARGET_CATEGORIES_IN_CART = 'NO_TARGET_CATEGORIES_IN_CART',
  NO_TARGET_PRODUCTS_IN_CART = 'NO_TARGET_PRODUCTS_IN_CART',
  NO_TARGET_VARIANTS_IN_CART = 'NO_TARGET_VARIANTS_IN_CART',
  TOTAL_DISCOUNT_IS_ZERO = 'TOTAL_DISCOUNT_IS_ZERO',
  SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE = 'SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE',
  TOTAL_BELOW_MINIMUM_ORDER_VALUE = 'TOTAL_BELOW_MINIMUM_ORDER_VALUE',
  DISCOUNT_INVALID_FULFILLMENT_TYPE = 'DISCOUNT_INVALID_FULFILLMENT_TYPE'
}

export type CheckoutErrorCode = CartWarningMessage | CartErrorMessage

export interface BaseCartValidation {
  message: CartErrorMessage | CartWarningMessage
}

export interface GenericCartError extends BaseCartValidation {}
export interface GenericCartWarning extends BaseCartValidation {}

export interface PriceComparison {
  newPriceWithVat: number
  oldPriceWithVat: number
  orderItemId: string
}

export interface InvalidModifiersError {
  message: CartErrorMessage.INVALID_MODIFIERS
  breakdown: {
    productVariantId: string
    quantity: number
  }[]
}

interface ProductErrorVariant {
  id: string
  name: string
}

export interface ProductsOutOfStockError {
  message: CartErrorMessage.PRODUCTS_OUT_OF_STOCK
  variants: ProductErrorVariant[]
}

export interface ProductsUnavailableError {
  message: CartErrorMessage.PRODUCTS_UNAVAILABLE
  variants: ProductErrorVariant[]
}

export type CartProductError =
  | InvalidModifiersError
  | PriceChangedError
  | ProductsOutOfStockError
  | ProductsUnavailableError

export interface InvalidTimeslotWarning extends GenericCartWarning {
  nextAvailableTimeslot: RichTimeSlot
  isStoreOpen: boolean
}

export type DiscountWarningCode =
  | CartWarningMessage.CUSTOMER_LOGIN_REQUIRED
  | CartWarningMessage.NO_TARGET_CATEGORIES_IN_CART
  | CartWarningMessage.NO_TARGET_PRODUCTS_IN_CART
  | CartWarningMessage.NO_TARGET_VARIANTS_IN_CART
  | CartWarningMessage.TOTAL_DISCOUNT_IS_ZERO
  | CartWarningMessage.DISCOUNT_MINIMUM_VALUE_NOT_MET
  | CartWarningMessage.DISCOUNT_INVALID_FULFILLMENT_TYPE
  | CartWarningMessage.DISCOUNT_NOT_APPLICABLE_TO_STORE

export type MinimumValueDiscountWarning = {
  message: CartWarningMessage.DISCOUNT_MINIMUM_VALUE_NOT_MET
  minimumValue: string
}

export type TargetCategoriesDiscountWarning = {
  message: CartWarningMessage.NO_TARGET_CATEGORIES_IN_CART
  categories: { id: string; name: string }[]
}

export type TargetProductsDiscountWarning = {
  message: CartWarningMessage.NO_TARGET_PRODUCTS_IN_CART
  products: { id: string; name: string }[]
}

export type TargetVariantsDiscountWarning = {
  message: CartWarningMessage.NO_TARGET_VARIANTS_IN_CART
  variants: { id: string; name: string }[]
}

export type CustomerLoginDiscountWarning = {
  message: CartWarningMessage.CUSTOMER_LOGIN_REQUIRED
}

export type TotalDiscountDiscountWarning = {
  message: CartWarningMessage.TOTAL_DISCOUNT_IS_ZERO
}

export type DiscountNotApplicableWarning = {
  message: CartWarningMessage.DISCOUNT_NOT_APPLICABLE_TO_STORE
}

export type FulfillmentTypeInvalidDiscountWarning = {
  fulfillmentTypes: FulfillmentType[]
  message: CartWarningMessage.DISCOUNT_INVALID_FULFILLMENT_TYPE
}

export type DiscountInvalid = {
  message: CartErrorMessage.DISCOUNT_INVALID
}

export type DiscountExpired = {
  message: CartErrorMessage.DISCOUNT_EXPIRED
}

export type DiscountLimitReached = {
  message: CartErrorMessage.DISCOUNT_LIMIT_REACHED
}

export type DiscountError =
  | DiscountInvalid
  | DiscountExpired
  | DiscountLimitReached

export type DiscountWarning =
  | TargetCategoriesDiscountWarning
  | TargetProductsDiscountWarning
  | TargetVariantsDiscountWarning
  | MinimumValueDiscountWarning
  | CustomerLoginDiscountWarning
  | TotalDiscountDiscountWarning
  | DiscountNotApplicableWarning
  | FulfillmentTypeInvalidDiscountWarning

interface RichTimeSlot {
  datetime: string
  range: string
  value: string
}

export interface MinimumValueWarning {
  message:
    | CartWarningMessage.SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE
    | CartWarningMessage.TOTAL_BELOW_MINIMUM_ORDER_VALUE
  minimumValue: string
}

export interface PriceChangedError {
  message: CartErrorMessage.PRODUCT_PRICES_CHANGED
  priceComparison: PriceComparison[]
}

export type ConsumerCartError = GenericCartError | CartProductError
export type ConsumerCartWarning =
  | GenericCartWarning
  | InvalidTimeslotWarning
  | DiscountWarning
  | MinimumValueWarning

export interface ConsumerCartValidations {
  warnings: ConsumerCartWarning[]
  errors: ConsumerCartError[]
}

export interface LoyaltyCard {
  description: string
  id: string
  name: string
  stamps: LoyaltyStamps
  nextRewards: NextReward[] | null
  availableRewards: AvailableReward[]
  stampsFromOrder: StampsFromOrder
}

export enum RewardStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  REDEEMED = 'REDEEMED',
  REVOKED = 'REVOKED'
}

export interface AvailableReward {
  details: RewardDetail
  isApplicable: boolean
  status: RewardStatus
  id: string
  isNew: boolean
}

export interface RewardDetail {
  description: string
  name: string
}

export interface NextReward {
  rewardDetails: RewardDetail
  stampsForNextReward: number
}

interface LoyaltyStamps {
  currentStamps: number
  maximumStamps: number
  stampLabel: string
  stampSource: string
}

export interface Loyalty {
  cards: LoyaltyCard[]
}

interface StampsFromOrder {
  isEarned: boolean
  total: number
}

export interface LoyaltyCardStamps {
  id: string
  stamps: number
}
