import { Product } from 'schema-dts'
import { helmetJsonLdProp } from 'react-schemaorg'
import { useAppContent, useShop, useSlerpMerchant } from 'shop/hooks'
import { Helmet } from 'react-helmet'
import { createOffers, trimUrl } from './utils'
import { CategoryProduct } from '../Shop/Categories'

interface Props {
  product: CategoryProduct
}

export const ProductMeta = ({ product }: Props) => {
  const { isSeoEnabled } = useSlerpMerchant()
  const { merchantName } = useAppContent()
  const { currentPartnerStore } = useShop()
  const { name: storeName } = currentPartnerStore || {}

  const baseUrl = trimUrl(window.location.origin + window.location.pathname)

  if (!isSeoEnabled) {
    return <></>
  }

  return (
    <Helmet
      script={[
        helmetJsonLdProp<Product>({
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: product.name,
          url: baseUrl,
          image: product.images.map((image) => image.standard),
          description: product.description || '',
          sku: product.id,
          brand: {
            '@type': 'Brand',
            name: merchantName
          },
          offers: createOffers(product, `${merchantName} ${storeName}`, baseUrl)
        })
      ]}
    />
  )
}
