import gql from 'graphql-tag'

const CategoryFragment = `
    id
    name
    description
`

const ProductFragment = `
    id
    allergens
    calorieData {
      caloriesPerServing
    }
    defaultVariantId
    description
    images {
      original
      standard
      thumb
    }
    inStock
    limit
    dietaryRequirements
    modifierGroups {
      id
      name
      maximum
      minimum
      modifiers {
        id
        allergens
        image {
          original
          standard
          thumb
        }
        inStock
        name
        price {
          base
        }
        restrictions {
          alcoholic
        }
      }
    }
    name
    options {
      name
      values
    }
    pricing {
      lowestVariant {
        base
      }
      maximum { 
        base 
      }
      minimum {
        base
      }  
    }
    quickAddAllowed
    seoDescription
    slug
    restrictions {
      alcoholic
    }
    variants {
      allergens
      id
      inStock
      name
      limit
      pricing {
        absolute {
          base
        }
        relative {
          base
        }
      }
      restrictions {
        alcoholic
      }
      options {
        name
        value
      }
    }
`

export const QUERY_GET_STORE_CATEGORIES = gql`
  query Categories(
    $id: UUID
    $slug: String
    $fulfillmentDatetime: UTCTimestamp
    $fulfillmentType: FulfillmentType
  ) {
    store(id: $id, slug: $slug) {
      categories(
        fulfillmentDatetime: $fulfillmentDatetime
        fulfillmentType: $fulfillmentType
      ) {
        ${CategoryFragment}
        products {
          ${ProductFragment}
        }
      }
    }
  }
`
