import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { DIETS } from '../../Product/types'
import TooltipPopup from '../../Tooltip/TooltipPopup'
import { useMediaQueries } from 'shop/hooks'

interface DietaryRequirementsProps {
  dietaryRequirements?: string[]
}

const DietaryRequirements = ({
  dietaryRequirements = []
}: DietaryRequirementsProps) => {
  const [showToolip, setShowToolip] = useState(false)
  const { isMobile } = useMediaQueries()
  const elRef = useRef(null)

  const handleClose = () => {
    setTimeout(() => {
      setShowToolip(false)
    }, 50)
  }

  const handleToggle = () => {
    setShowToolip(!showToolip)
  }

  const handleToggleHover = (mouseAction: 'enter' | 'leave') => {
    if (isMobile) return
    if (mouseAction === 'enter') return setShowToolip(true)
    if (mouseAction === 'leave') return handleClose()
  }

  if (!dietaryRequirements.length) return <></>

  return (
    <Container
      ref={elRef}
      onMouseEnter={() => handleToggleHover('enter')}
      onMouseLeave={() => handleToggleHover('leave')}
      onClick={() => handleToggle()}
    >
      {dietaryRequirements.map((diet) => (
        <Diet key={diet} diet={diet} />
      ))}
      {showToolip && (
        <TooltipPopup
          headerTitle={'Special Dietary'}
          onClose={() => {
            handleClose()
          }}
          targetRef={elRef}
          tooltipMessage={
            <span>
              <strong>
                {dietaryRequirements
                  .map((diet) => DIETS[diet]?.name)
                  .join(', ')}
              </strong>
            </span>
          }
        />
      )}
    </Container>
  )
}

const Diet = ({ diet }: { diet: string }) => {
  if (!DIETS[diet]?.label) return <></>

  return <DietInfo>{DIETS[diet].label}</DietInfo>
}

const Container = styled.span<StyledHTMLElement, Required<Theme>>({
  color: 'black',
  position: 'relative',
  fontSize: '12px',
  display: 'inline-flex',
  border: '2px solid #F0F0F0',
  borderRadius: '8px',
  padding: '4px'
})

const DietInfo = styled.span<StyledHTMLElement, Required<Theme>>({
  color: 'black',
  borderRight: '2px solid #F0F0F0',
  padding: '0 4px',
  marginLeft: 0,
  '&:last-of-type': {
    borderRight: 'none'
  }
})

export default DietaryRequirements
