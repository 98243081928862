import { useState } from 'react'
import { LatLng } from 'shop/types'
import {
  trackGA4CustomUserDeclinesLocationService,
  trackGA4CustomUserEnablesLocationService
} from 'tracker/GA/custom'
import { useShop } from './useGlobalContext'

type LocalCurrentLocation = {
  latLng: LatLng
  formattedAddress: string
  addressComponents: google.maps.GeocoderAddressComponent[]
}

export const useNavigatorGeolocation = () => {
  const [geolocationPermission, setGeolocationPermission] =
    useState<PermissionState>('denied')
  const [currentPositionCoords, setCurrentPositionCoords] = useState<LatLng>()
  const [currentPositionAddress, setCurrentPositionAddress] = useState<{
    formattedAddress: string
    addressComponents: google.maps.GeocoderAddressComponent[]
  }>()
  const { isLocationServiceEnabled, setIsLocationServiceEnabled } = useShop()

  const getLocallyStoredLastLocation = (): LocalCurrentLocation | null => {
    const lastCurrentLocation = localStorage.getItem('last-current-location')
    if (lastCurrentLocation) {
      return JSON.parse(lastCurrentLocation) as LocalCurrentLocation
    }
    return null
  }

  const fetchGeolocationPermission = () => {
    if (navigator.geolocation && navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((res) => {
        const { state } = res
        if (state) {
          setGeolocationPermission(state)

          if (state === 'granted') {
            setIsLocationServiceEnabled(true)
          }
        }
      })
    } else {
      console.log('Geolocation is not supported by this browser.')
      setGeolocationPermission('denied')
      setIsLocationServiceEnabled(false)
    }
  }

  const options: PositionOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  }
  const success = (pos: GeolocationPosition) => {
    var crd = pos.coords

    // first time "enabling" the location service
    if (!isLocationServiceEnabled) {
      trackGA4CustomUserEnablesLocationService('slerpGA4Tracker')
      trackGA4CustomUserEnablesLocationService('merchantGA4Tracker')
    }

    setGeolocationPermission('granted')
    setIsLocationServiceEnabled(true)
    setCurrentPositionCoords({
      lat: crd.latitude,
      lng: crd.longitude
    })
  }

  const errors = (err: GeolocationPositionError) => {
    if (err.message === 'User denied Geolocation') {
      setGeolocationPermission('denied')
      setIsLocationServiceEnabled(false)
      trackGA4CustomUserDeclinesLocationService('slerpGA4Tracker')
      trackGA4CustomUserDeclinesLocationService('merchantGA4Tracker')
    }
    console.warn(`ERROR(${err.code}): ${err.message}`)
  }

  const getGeolocationPosition = () => {
    navigator.geolocation.getCurrentPosition(success, errors, options)
  }

  const geocodeLatLngIntoAddress = (coords: LatLng) => {
    // compare coords with local storage values, and if we already have an address return that.
    const locallyStoredLastLocation = getLocallyStoredLastLocation()
    if (locallyStoredLastLocation) {
      const { latLng, formattedAddress, addressComponents } =
        locallyStoredLastLocation
      if (latLng.lat && latLng.lng && coords.lat && coords.lng) {
        if (latLng.lat === coords.lat && latLng.lng === coords.lng) {
          setCurrentPositionAddress({
            formattedAddress,
            addressComponents
          })
          return
        }
      }
    }

    if (!!google) {
      const geocoder = new google.maps.Geocoder()

      if (geocoder) {
        geocoder
          .geocode({ location: { lat: coords.lat, lng: coords.lng } })
          .then(({ results }) => {
            // find most relevant locations from the results
            // priority -> street_address, premise, postal_code, results[0]
            const findAddressByType = (type: string) => {
              return results.find((res) => res.types.includes(type))
            }
            let bestAddress = findAddressByType('street_address')
            if (!bestAddress) bestAddress = findAddressByType('premise')
            if (!bestAddress) bestAddress = findAddressByType('postal_code')
            if (!bestAddress) bestAddress = results[0]

            const formattedAddress = bestAddress.formatted_address
            const addressComponents = bestAddress.address_components
            setCurrentPositionAddress({
              formattedAddress,
              addressComponents
            })

            // Save to local storage
            // use user's coords and not geometry.location as bestAddress may have different lat lng
            // and we don't want to keep geocoding
            const localStoredLocation: LocalCurrentLocation = {
              latLng: coords,
              formattedAddress,
              addressComponents
            }
            localStorage.setItem(
              'last-current-location',
              JSON.stringify(localStoredLocation)
            )
          })
      }
    }
  }

  return {
    geolocationPermission,
    isLocationServiceEnabled,
    fetchGeolocationPermission,
    getGeolocationPosition,
    currentPositionCoords,
    currentPositionAddress,
    geocodeLatLngIntoAddress
  }
}
