import React from 'react'
import { CategoryWithProducts } from '../Shop/Categories/types'
import { Products } from './Products'
import {
  List,
  CategoryTitle,
  ProductsContainer,
  CategoryDescription
} from './commonStyles'
import styled from '@emotion/styled'
import { Theme } from 'styled-system'
import { StyledHTMLElement } from 'shop/theme/types'

interface CategoryGroupingProps {
  categories: CategoryWithProducts[]
}

export const CategoryGrouping = ({ categories }: CategoryGroupingProps) => {
  return (
    <>
      {categories.map((category) => {
        return (
          category && (
            <React.Fragment key={category.id || category.name}>
              {category.products.length > 0 && (
                <ProductsContainer
                  id={`product-category-${category.id || category.name}`}
                >
                  <CategoryContent>
                    <CategoryTitle>{category.name}</CategoryTitle>
                    {category.description && (
                      <CategoryDescription>
                        {category.description}
                      </CategoryDescription>
                    )}
                  </CategoryContent>
                  <List>
                    <Products
                      products={category.products}
                      productCategory={category.name}
                    />
                  </List>
                </ProductsContainer>
              )}
            </React.Fragment>
          )
        )
      })}
    </>
  )
}

export const CategoryContent = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    [theme.mediaQueries.viewport7]: {
      paddingLeft: '12px'
    },
    [theme.mediaQueries.viewport9]: {
      paddingLeft: '8px'
    }
  })
)
