import gql from 'graphql-tag'

const CONSUMER_CART_FRAGMENT = `
id 
additionalItems {
  giftWrapping {
    message
    price {
      base
    }
  }
}
customField {
  name
  value
}
customerDetails {
  contactNumber
  email
  firstName
  lastName
}
deliveryAddress {
  city
  country
  flatNumber
  coordinates {
    lat
    lng
  }
  lineOne
  lineTwo
  zip
  confidence
}
dropoffNotes
fulfillment {
  type
  orderType
  tableNumber
  window {
    from
    to
  }
}
loyaltyCards {
    description
    id
    name
    status
    availableRewards {
      id
      isApplicable
      details {
          description
          name
      }
      status
    }
}
saveAddress
marketingOptIn
minimumOrderValue
orderItems {
  id
  image {
    original
    standard
    thumb
  }
  modifierGroups {
    name
    modifiers {
      name
      price {
        base
        discounted
        reduction
      }
      quantity
    }
  }
  name
  options {
    name
    values
  }
  product {
    id
    name
  }
  quantity
  restrictions {
    alcoholic
  }
  total {
    base
    discounted
    reduction
  }
  variantId
  variantPrice {
    base
    discounted
    reduction
  }
}
orderNotes
recipientDetails {
  contactNumber
  firstName
  lastName
}
store {
  id
  address {
    city
    country
    lineOne
    lineTwo
    zip
  }
  name
  slug
}
tipValues {
  amount
  percentage
}
summary {
  delivery {
    base
    discounted
    reduction
  }
  deliveryChargeInfo {
    reductionReason
    pricingBand {
      id
      lowerThreshold
      percentageDiscount
      upperThreshold
    }
  }
  discount {
      amount
      code
      description
  }
  reward {
    id
    amount
    description
    name
  }
  storeFee {
    price { 
      base
      discounted
      reduction
    }
  }
  subtotal {
    base
    discounted
    reduction
  }
  tip
  total {
    base
    discounted
    reduction
  }
}`

const CONSUMER_CART_ERRORS_FRAGMENT = `
__typename
message
... on GenericCartError {
  message
}
... on InvalidModifiersError {
    message
    breakdown {
        productVariantId
        quantity
    }
}
... on ProductsOutOfStockError {
  message
  variants {
    id
    name
  }
}
... on ProductsUnavailableError {
      message
      variants {
        id
        name
      }
}
... on PriceChangedError {
    message
    priceComparison {
        newPriceWithVat
        oldPriceWithVat
        orderItemId
    }
}
`

const CONSUMER_CART_WARNINGS_FRAGMENT = `
__typename
message
... on GenericCartWarning {
  message
}
... on InvalidTimeslotWarning {
message
isStoreOpen
nextAvailableTimeslot {
  datetime
  range
  value
}
}
... on MinimumValueWarning {
    message
    minimumValue
}
... on TargetCategoriesDiscountWarning {
    message
    categories {
        id
        name
    }
}
... on TargetProductsDiscountWarning {
    message
    products {
        id
        name
    }
}
... on TargetVariantsDiscountWarning {
    message
    variants {
        id
        name
    }
}
... on FulfillmentTypeInvalidDiscountWarning {
    message
    fulfillmentTypes
}
`

const MUTATE_CREATE_CART_CONSUMER = gql`
  mutation createCart(
    $customerDetails: CustomerDetailsParams
    $deliveryAddress: DeliveryAddressParams
    $fulfillmentDate: Date!
    $fulfillmentTime: HourMinute
    $fulfillmentType: FulfillmentType!
    $storeId: UUID!
    $tableNumber: String
    $addressId: UUID
  ) {
    createCart(
      customerDetails: $customerDetails
      deliveryAddress: $deliveryAddress
      fulfillmentDate: $fulfillmentDate
      fulfillmentTime: $fulfillmentTime
      fulfillmentType: $fulfillmentType
      storeId: $storeId
      tableNumber: $tableNumber
      addressId: $addressId
    ) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }

    }
  }
`

/** Consumer API get cart query */
const QUERY_GET_CART_CONSUMER = gql`
  query cart {
    cart {
      ${CONSUMER_CART_FRAGMENT}
    }
  }
`

/** Consume API switch cart mutation through createCart */
const MUTATE_SWITCH_CART_STORE_CONSUMER = gql`
  mutation createCart($storeId: UUID!) {
    createCart(storeId: $storeId) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

/** Consumer API update cart mutation */
const MUTATE_UPDATE_CART_CONSUMER = gql`
  mutation updateCart(
        $customerDetails: CustomerDetailsParams
        $dropoffNotes: String
        $customFieldValue: String
        $giftWrapMessage: String
        $marketingOptIn: Boolean
        $orderNotes: String 
        $saveAddress: Boolean
        $tipValue: Decimal
        $recipientDetails: RecipientDetailsParams
    ) {
    updateCart(
        customerDetails: $customerDetails
        dropoffNotes: $dropoffNotes
        customFieldValue: $customFieldValue
        giftWrapMessage: $giftWrapMessage
        marketingOptIn: $marketingOptIn
        orderNotes: $orderNotes
        saveAddress: $saveAddress
        tipValue: $tipValue
        recipientDetails: $recipientDetails
    ) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

/** Consumer API update cart fulfillment mutation */
const MUTATE_UPDATE_FULFILLMENT_CART_CONSUMER = gql`
  mutation updateCartFulfillment(
        $deliveryAddress: DeliveryAddressParams
        $fulfillmentDate: Date
        $fulfillmentTime: HourMinute
        $fulfillmentType: FulfillmentType
        $proceedWithNewPrices: Boolean
        $storeId: UUID
        $tableNumber: String 
    ) {
    updateCartFulfillment(
        deliveryAddress: $deliveryAddress
        fulfillmentDate: $fulfillmentDate
        fulfillmentTime: $fulfillmentTime
        fulfillmentType: $fulfillmentType
        proceedWithNewPrices: $proceedWithNewPrices
        storeId: $storeId
        tableNumber: $tableNumber
    ) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

const MUTATE_APPLY_DISCOUNT_CART_CONSUMER = gql`
  mutation applyDiscount(
        $discountCode: String!
    ) {
    applyDiscount(
        discountCode: $discountCode
    ) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

const MUTATE_REMOVE_DISCOUNT_CART_CONSUMER = gql`
  mutation removeDiscount {
    removeDiscount {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

const MUTATE_APPLY_REWARD_CART_CONSUMER = gql`
  mutation applyReward(
        $rewardId: UUID!
    ) {
    applyReward(
        rewardId: $rewardId
    ) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

const QUERY_VALIDATE_CART_CONSUMER = gql`
  query validateCart {
    validateCart {
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

const MUTATE_CREATE_ORDER_ITEM_CONSUMER = gql`
  mutation createOrderItem(
    $modifiers: [OrderItemModifierParams!]
    $quantity: Int!
    $variantId: UUID!
    $proceedWithNewOrderItems: Boolean
  ) {
    createOrderItem(modifiers: $modifiers, quantity: $quantity, variantId: $variantId, proceedWithNewOrderItems: $proceedWithNewOrderItems) 
    {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }`

const MUTATE_REMOVE_ORDER_ITEMS_CONSUMER = gql`
  mutation removeOrderItems(
    $ids: [UUID]!
  ) {
    removeOrderItems(ids: $ids) 
    {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
}`

const MUTATE_UPDATE_ORDER_ITEM_QUANTITY_CONSUMER = gql`
  mutation updateOrderItemQuantity(
    $id: UUID!
    $quantity: Int!
    $proceedWithNewOrderItems: Boolean
  ) {
    updateOrderItemQuantity(id: $id, quantity: $quantity, proceedWithNewOrderItems: $proceedWithNewOrderItems) 
    {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
}`

export {
  MUTATE_APPLY_DISCOUNT_CART_CONSUMER,
  MUTATE_APPLY_REWARD_CART_CONSUMER,
  MUTATE_CREATE_CART_CONSUMER,
  MUTATE_CREATE_ORDER_ITEM_CONSUMER,
  MUTATE_REMOVE_DISCOUNT_CART_CONSUMER,
  MUTATE_REMOVE_ORDER_ITEMS_CONSUMER,
  MUTATE_SWITCH_CART_STORE_CONSUMER,
  MUTATE_UPDATE_CART_CONSUMER,
  MUTATE_UPDATE_FULFILLMENT_CART_CONSUMER,
  MUTATE_UPDATE_ORDER_ITEM_QUANTITY_CONSUMER,
  QUERY_GET_CART_CONSUMER,
  QUERY_VALIDATE_CART_CONSUMER
}
