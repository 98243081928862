import { AddressComponent } from './types'

type Address = {
  line1: string
  line2: string
  country: string
  zip: string
  city: string
  streetNumber: string
}

export const extractGoogleAddressComponents = (
  components: AddressComponent[]
) => {
  const isNonEssential = (componentType: string) => {
    return [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ].includes(componentType)
  }

  const reducer = (address: Address, component: AddressComponent) => {
    const { types } = component

    if (types.includes('route')) {
      return { ...address, line1: component.long_name }
    }
    if (types.includes('premise')) {
      return { ...address, line2: component.long_name }
    }
    if (types.includes('street_number')) {
      return { ...address, streetNumber: component.long_name }
    }
    if (types.includes('postal_code')) {
      return { ...address, zip: component.long_name }
    }
    if (types.includes('country')) {
      return { ...address, country: component.long_name }
    }
    if (types.includes('locality') || types.includes('postal_town')) {
      return { ...address, city: component.long_name }
    }
    if (types.some(isNonEssential)) return address

    return address
  }

  return components.reduce(reducer, {
    line1: '',
    line2: '',
    country: '',
    zip: '',
    city: '',
    streetNumber: ''
  })
}

export const buildAddressFromComponents = (components: AddressComponent[]) => {
  const { line1, line2, country, zip, city, streetNumber } =
    extractGoogleAddressComponents(components)

  const finalLine1 = streetNumber ? `${streetNumber} ${line1}` : line1
  const finalLine2 = line2

  return {
    line_1: finalLine1,
    line_2: finalLine2,
    flatNumber: null,
    country,
    zip,
    city
  }
}
