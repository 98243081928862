import styled from '@emotion/styled'
import React from 'react'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { ProductLoader, CategoryDescriptionLoader } from '../Loader'
import { LineBreak } from '../Shop/commonStyles'
import { ProductsContainer, List, LineBreakWrapper } from './commonStyles'

export const LoadingContainer = () => (
  <ProductsContainer>
    <LoaderWrapper>
      <CategoryDescriptionLoader />
    </LoaderWrapper>
    <List>
      {Array(6)
        .fill(null)
        .map((value, index) => (
          <React.Fragment key={`product-loader-${index + 1}`}>
            <ProductCardLoader>
              <ProductLoader />
            </ProductCardLoader>
            <LineBreakWrapper isLast={index === 5}>
              <LineBreak />
            </LineBreakWrapper>
          </React.Fragment>
        ))}
    </List>
  </ProductsContainer>
)

const LoaderWrapper = styled.div({
  width: '214px',
  height: '32px',
  marginBottom: '8px'
})

const ProductCardLoader = styled.li<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    marginTop: '8px',

    [theme.mediaQueries.viewport9]: {
      border: 'solid #FAFAFA 1px',
      padding: '8px 8px 0',
      borderRadius: '12px'
    }
  })
)
