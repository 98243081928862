import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { fadeIn } from '../Shop/commonStyles'
import { ProductImageURLs } from './types'
import { useImages } from 'shop/hooks'
import { ImageTagType } from 'shop/types'

interface Props {
  imageUrls: ProductImageURLs[] | null
  productName: string
}

const MOBILE_QUERY = '(max-width: 768px)'
const DESKTOP_QUERY = '(min-width: 768.1px)'

const ProductImages = ({ imageUrls, productName }: Props) => {
  // Handles fade-in transition for initial image shown on load
  const [isLoaded, setIsLoaded] = useState(false)

  // Handles transition for the active image when switching between thumbnails
  const [isSwitchingImage, setIsSwitchingImage] = useState(true)

  const placeholderImage = `https://placehold.co/601x400/random/random?text=${(
    productName.toUpperCase() || 'Slerp'
  ).substring(0, 1)}`

  const [activeImage, setActiveImage] = useState<ProductImageURLs>({
    original: imageUrls?.[0].original || placeholderImage,
    standard: imageUrls?.[0].standard || placeholderImage,
    thumb: imageUrls?.[0].thumb || placeholderImage
  })

  const thumbClickHandler = (imageUrl: ProductImageURLs) => () => {
    if (imageUrl.original === activeImage.original) return
    setIsSwitchingImage(true)
    setActiveImage(imageUrl)
  }

  useEffect(() => {
    setIsSwitchingImage(false)
  }, [activeImage])

  // Dynamically sets the image data for the useImages hook based on current activeImage
  const imageDataForHook = useMemo(
    () => [{ imageUrl: activeImage.original, mediaQuery: DESKTOP_QUERY }],
    [activeImage.original]
  )

  /** Uses source img instead of transformed image on error */
  const { getImage, handleImageError } = useImages(
    imageDataForHook,
    ImageTagType.Responsive
  )

  return (
    <Container data-testid='product-images' id='product-modal-images'>
      <ImageContainer>
        {!isSwitchingImage && activeImage && (
          <picture data-testid='product-images-picture-tag'>
            <source media={MOBILE_QUERY} srcSet={activeImage.standard} />
            <source
              media={DESKTOP_QUERY}
              srcSet={getImage(1024, 1024, DESKTOP_QUERY)}
            />
            <ActiveImage
              src={activeImage.original}
              alt={`Order ${productName} Online`}
              isLoaded={isLoaded}
              onLoad={() => setIsLoaded(true)}
              onError={handleImageError}
            />
          </picture>
        )}
      </ImageContainer>
      {!!imageUrls && imageUrls?.length > 1 && (
        <List>
          {imageUrls.map((imageUrl, index) => {
            return (
              <li key={index}>
                <ProductImage
                  src={imageUrl.thumb || placeholderImage}
                  data-testid='productImage'
                  onClick={thumbClickHandler(imageUrl)}
                  alt={`Order ${productName} Online`}
                />
              </li>
            )
          })}
        </List>
      )}
    </Container>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    position: 'sticky',
    top: 0,
    flex: '0 0 100%',
    aspectRatio: '1/1',
    maxWidth: '100%',
    margin: '0 auto',
    [theme.mediaQueries.viewport9]: {
      position: 'relative',
      height: 'auto',
      flex: '0 0 auto',
      alignItems: 'center',
      justifyContent: 'center',
      aspectRatio: '1/1',
      margin: '0 auto',
      maxWidth: '1024px',
      maxHeight: '1024px'
    }
  })
)

const ImageContainer = styled.div(({ theme }: any) => ({
  width: '100%',
  height: '100%',
  position: 'relative',

  [theme.mediaQueries.viewport9]: {
    '& picture': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'block'
    }
  }
}))

const ActiveImage = styled.img<{ isLoaded: boolean }>(({ isLoaded }) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  background: 'white',
  opacity: 0,
  animation: isLoaded ? `${fadeIn} 0.5s ease-in-out forwards` : 'none'
}))

const List = styled.ul(({ theme }: any) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  bottom: 0,
  margin: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: theme.space[2],
  width: '100%',
  overflowX: 'auto',

  [theme.mediaQueries.viewport9]: {
    padding: '12px',
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: 0
  }
}))

const ProductImage = styled.img(({ theme }: any) => ({
  display: 'block',
  height: '48px',
  width: '48px',
  objectFit: 'cover',
  border: '2px solid white',
  borderRadius: '4px',
  opacity: 0,
  animation: `${fadeIn} 0.5s ease-in-out forwards`,

  [theme.mediaQueries.viewport7]: {
    height: '64px',
    width: '64px'
  },
  '&:hover': {
    cursor: 'pointer'
  }
}))

export default ProductImages
