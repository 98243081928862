import { ReactElement, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import LocationSelectModal from './LocationSelectModal'
import { BiEditAlt as EditIcon } from 'react-icons/bi'
import { CustomerAddress } from 'shop/types'
import { GeocodedAddress } from './utils'
import { PartnerStoreBrowse } from '../types'

type PseudoFormInputProps = {
  searchValue: string
  address: string
  onChange: (newAddress: string) => void
  onSelect: ({
    newAddress,
    geocodedAddress
  }: {
    newAddress?: string
    geocodedAddress?: GeocodedAddress
  }) => void
  label?: string
  id?: string
  customerAddresses?: CustomerAddress[]
  selectCustomerAddress: (customerAddress: CustomerAddress) => void
  inputPlaceholder: string
  leftIcon: React.ReactNode
  stores?: PartnerStoreBrowse[]
  closeModalOnSelect?: boolean
}

export const PseudoFormInput = ({
  searchValue,
  address,
  onChange,
  onSelect,
  id,
  customerAddresses,
  selectCustomerAddress,
  inputPlaceholder,
  leftIcon,
  stores,
  closeModalOnSelect = true
}: PseudoFormInputProps) => {
  const [showLocationSelect, setShowLocationSelect] = useState(false)
  const [renderLocationSelect, setRenderLocationSelect] = useState(false)

  /** Render Modal then show it after 50ms */
  const handleOpenModal = () => {
    setRenderLocationSelect(true)
    setTimeout(() => setShowLocationSelect(true), 50) // wait 50ms for Modal to render before transition start
  }

  /** Hide Modal after 200ms then unmount it after 300ms */
  const handleCloseModal = () => {
    // if the search value has provided no stores, reset the list on close
    if (searchValue && !address && !stores?.length) {
      onChange('')
    }
    setTimeout(() => {
      setShowLocationSelect(false)
      setTimeout(() => setRenderLocationSelect(false), 300) // wait 300ms for Modal transition to finish
    }, 200) // wait 200ms for form to populate data
  }

  const handleSelect = ({
    newAddress,
    geocodedAddress
  }: {
    newAddress?: string
    geocodedAddress?: GeocodedAddress
  }) => {
    onSelect({ newAddress, geocodedAddress })
    if (closeModalOnSelect) handleCloseModal()
  }

  const handleSelectCustomerAddress = (customerAddress: CustomerAddress) => {
    selectCustomerAddress(customerAddress)
    if (closeModalOnSelect) handleCloseModal()
  }

  return (
    <>
      <StyledPseduoFormInput
        id={id}
        onContainerClick={handleOpenModal}
        showEditIcon={!!searchValue}
        leftIcon={leftIcon}
      >
        {/* Read only input for Fulfillment Form */}
        <input
          placeholder={inputPlaceholder}
          value={searchValue}
          readOnly={true}
        />
      </StyledPseduoFormInput>
      {renderLocationSelect && (
        <LocationSelectModal
          searchValue={searchValue}
          address={address}
          onChange={onChange}
          onSelect={handleSelect}
          onClose={handleCloseModal}
          customerAddresses={customerAddresses}
          selectCustomerAddress={handleSelectCustomerAddress}
          inputPlaceholder={inputPlaceholder}
          showLocationSelect={showLocationSelect}
          stores={stores}
          closeModalOnSelect={closeModalOnSelect}
        />
      )}
    </>
  )
}

type StyledPseduoFormInputProps = {
  onContainerClick?: () => void
  children: ReactElement
  showEditIcon?: boolean
  id?: string
  leftIcon: React.ReactNode
}

export const StyledPseduoFormInput = ({
  onContainerClick,
  children,
  showEditIcon = false,
  id,
  leftIcon
}: StyledPseduoFormInputProps) => {
  return (
    <InputContainer
      id={id}
      data-testid={id}
      isClickable={!!onContainerClick}
      onClick={onContainerClick && onContainerClick}
    >
      <IconContainer>{leftIcon}</IconContainer>
      <ContentContainer>{children}</ContentContainer>
      {showEditIcon && <EditIcon />}
    </InputContainer>
  )
}

const InputContainer = styled.div<
  StyledHTMLElement & { isClickable: boolean },
  Required<Theme>
>(({ theme, isClickable }) => ({
  display: 'flex',
  outline: 0,
  borderRadius: '12px',
  border: `1px solid ${theme.colors['lineColor']}`,
  padding: '9px 20px 9px 10px',
  alignItems: 'center',
  backgroundColor: 'white',
  cursor: isClickable ? 'pointer' : 'unset',
  lineHeight: '30px',

  // disable blue hightlight of user clicks
  WebkitTapHighlightColor: 'transparent',

  // disable lastpass
  '& [data-lastpass-icon-root]': {
    display: 'none'
  }
}))

const ContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    display: 'flex',
    gap: '12px',
    flexGrow: 1,
    fontSize: '14px',
    fontWeight: theme.fontWeights.light,
    fontFamily: theme.fonts.body.family,

    '& input': {
      width: '100%',
      outline: 0,
      border: 0,
      padding: 0,
      fontSize: '14px'
    }
  })
)

const IconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    aspectRatio: '1/1',

    '& svg': {
      color: '#2A2A2A'
    }
  })
)
