import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { TfiLocationArrow as NavigateIcon } from 'react-icons/tfi'
import { useEffect } from 'react'
import { useNavigatorGeolocation } from 'shop/hooks'
import { GeocodedAddress } from './utils'
import ContentLoader from 'react-content-loader'
import { trackGA4CustomSearchByLocation } from 'tracker/GA/custom'

type Props = {
  onSelect: ({
    newAddress,
    geocodedAddress
  }: {
    newAddress?: string
    geocodedAddress?: GeocodedAddress
  }) => void
}

const GeolocationAddressItem = ({ onSelect }: Props) => {
  const {
    geolocationPermission,
    isLocationServiceEnabled,
    fetchGeolocationPermission,
    getGeolocationPosition,
    geocodeLatLngIntoAddress,
    currentPositionCoords,
    currentPositionAddress
  } = useNavigatorGeolocation()

  // on load, fetch user's current location service permission
  useEffect(() => {
    fetchGeolocationPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEnableLocation = () => {
    if (
      isLocationServiceEnabled &&
      currentPositionAddress?.formattedAddress &&
      currentPositionCoords
    ) {
      trackGA4CustomSearchByLocation('slerpGA4Tracker')
      trackGA4CustomSearchByLocation('merchantGA4Tracker')
      onSelect({
        geocodedAddress: {
          latLng: currentPositionCoords,
          ...currentPositionAddress
        }
      })
      return
    }
    // trigger enable pop up from 'denied' or 'prompt'
    getGeolocationPosition()
  }

  useEffect(() => {
    if (!isLocationServiceEnabled) return

    if (currentPositionCoords) {
      geocodeLatLngIntoAddress(currentPositionCoords)
    } else {
      getGeolocationPosition()
    }

    // do not add any more dependencies at risk of extra geocoding.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocationServiceEnabled, currentPositionCoords])

  if (geolocationPermission === 'denied') return <></>

  return (
    <ItemContainer onClick={handleEnableLocation}>
      <ContentContainer>
        {isLocationServiceEnabled && (
          <>
            <AddressLabel variant='title'>Current Location</AddressLabel>
            <AddressContent>
              {!currentPositionAddress?.formattedAddress && (
                <ContentLoader
                  style={{
                    width: '120px',
                    height: '16px',
                    borderRadius: '4px'
                  }}
                >
                  <rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
                </ContentLoader>
              )}
              {currentPositionAddress?.formattedAddress}
            </AddressContent>
          </>
        )}
        {!isLocationServiceEnabled && (
          <AddressLabel variant='body'>
            Use your current location to find your closest stores.
          </AddressLabel>
        )}
      </ContentContainer>
      <EnableLabel isLocationServiceEnabled={isLocationServiceEnabled}>
        <NavigateIcon style={{ transform: 'scale(-1,1)' }} />
        <span>Enable</span>
      </EnableLabel>
    </ItemContainer>
  )
}

const ItemContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '48px',
    padding: '4.5px 16px',
    borderRadius: '0',
    gap: '8px',
    fontFamily: theme.fonts['body'].family,
    cursor: 'pointer',

    // disable blue hightlight of user clicks
    WebkitTapHighlightColor: 'transparent',

    '& svg': {
      height: '20px',
      width: '20px'
    },

    '&:hover': {
      background: '#FAFAFA'
    },

    [theme.mediaQueries.viewport7]: {
      padding: '4.5px 12px',
      borderRadius: '12px'
    }
  })
)

const ContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '4px',
    minWidth: 0 // for ellipsis to work with flex
  })
)
const AddressLabel = styled.div<
  StyledHTMLElement & { variant: 'title' | 'body' },
  Required<Theme>
>(({ theme, variant }) => ({
  fontSize: '14px',
  fontFamily: theme.fonts['body'].family,
  lineHeight: variant === 'title' ? '19px' : '16px',
  fontWeight: 500,
  color: '#2A2A2A'
}))
const AddressContent = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: '14px',
    fontFamily: theme.fonts['body'].family,
    lineHeight: '16px',
    maxHeight: '16px',
    color: '#59595A',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
)

const EnableLabel = styled.div<
  StyledHTMLElement & { isLocationServiceEnabled: boolean },
  Required<Theme>
>(({ theme, isLocationServiceEnabled }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: theme.fonts['body'].family,
  color: '#2A2A2A',
  padding: '4px 8px',
  textDecoration: 'underline',
  marginRight: '20px',

  [theme.mediaQueries.viewport7]: {
    marginRight: isLocationServiceEnabled ? '20px' : '0'
  },

  '& span': {
    display: 'none',

    [theme.mediaQueries.viewport7]: {
      display: isLocationServiceEnabled ? 'none' : 'inline-block'
    }
  }
}))

export default GeolocationAddressItem
