import styled from '@emotion/styled'
import ContentLoader from 'react-content-loader'
import { StoreContainer } from '../Landing/Stores/Store'

export const ProductImageLoader = () => (
  <ContentLoader>
    <rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
  </ContentLoader>
)

export const CategoryLoader = ({ ...props }) => (
  <ContentLoader width={140} height={1} {...props}>
    <rect x='62' y='0' rx='0' ry='0' width='8' height={4} />
    <rect x='72' y='0' rx='0' ry='0' width='8' height={4} />
  </ContentLoader>
)

export const CategoryDescriptionLoader = () => (
  <ContentLoader speed={2} width={214} height={32}>
    <rect x='0' y='0' rx='10' ry='10' width='214' height='32' />
  </ContentLoader>
)

export const ProductLoader = () => (
  <ContentLoader speed={2} width={412} height={116}>
    <rect x='1' y='0' rx='10' ry='10' width='242' height='32' />
    <rect x='0' y='36' rx='10' ry='10' width='214' height='18' />
    <rect x='0' y='58' rx='10' ry='10' width='144' height='18' />
    <rect x='0' y='85' rx='10' ry='10' width='55' height='20' />
    <rect x='300' y='0' rx='10' ry='10' width='110' height='110' />
  </ContentLoader>
)

export const CartLoader = () => (
  <ContentLoader speed={1.5} width={10} height={6}>
    <rect x='30' y='0' rx='0' ry='0' width={80} height={6} />
  </ContentLoader>
)

export const StoreLoader = () => (
  <Store>
    <ContentLoader
      speed={2}
      width={400}
      height={44}
      primaryOpacity={0.3}
      secondaryOpacity={0.5}
    />
  </Store>
)

export const StoreListItemLoader = () => (
  <ContentLoader speed={2} width={672} height={212}>
    <rect x='0' y='0' rx='8' ry='8' width='244' height='24' />
    <rect x='0' y='28' rx='8' ry='8' width='70' height='20' />
    <rect x='0' y='56' rx='8' ry='8' width='100%' height='32' />
    <rect x='0' y='104' rx='8' ry='8' width='100%' height='2' />
    <rect x='0' y='130' rx='8' ry='8' width='140' height='16' />
    <rect
      x='calc(100% - 190px)'
      y='122'
      rx='8'
      ry='8'
      width='190'
      height='37'
    />
    <rect x='0' y='184' rx='8' ry='8' width='140' height='16' />
    <rect
      x='calc(100% - 190px)'
      y='176'
      rx='8'
      ry='8'
      width='190'
      height='37'
    />
  </ContentLoader>
)

export const StoreListSortedByLabelLoader = () => (
  <ContentLoader speed={2} width={672} height={16}>
    <rect x='547' y='0' rx='8' ry='8' width='125' height='16' />
  </ContentLoader>
)

const Store = styled(StoreContainer)(({ theme }: any) => ({
  borderColor: 'transparent',
  cursor: 'auto',
  background: '#f7f7f7',
  padding: 0,
  '&:hover': {
    borderColor: 'transparent'
  },
  [theme.mediaQueries.viewport7]: {
    borderColor: 'transparent',
    padding: 0
  }
}))
