import { useEffect, useState } from 'react'
import { ImageTagType } from 'shop/types'

interface ImageData {
  imageUrl: string
  mediaQuery?: string
}

interface FallbackImageData {
  [mediaQuery: string]: { useFallback: boolean; imageUrl: string }
}

/** Hook for managing image loading with CDN transformations and onerror fallback handling */
export const useImages = (imageData: ImageData[], type: ImageTagType) => {
  // Initialize fallback image state given initial parameters
  const initialFallbackState = imageData.reduce(
    (acc, { imageUrl, mediaQuery }) => {
      acc[mediaQuery || 'default'] = { useFallback: false, imageUrl }
      return acc
    },
    {} as FallbackImageData
  )

  const [useFallbackImage, setUseFallbackImage] =
    useState<FallbackImageData>(initialFallbackState)

  // If new imageData is passed in, update image data state
  useEffect(() => {
    const newFallbackState = imageData.reduce(
      (acc, { imageUrl, mediaQuery }) => {
        const key = mediaQuery || 'default'
        acc[key] = {
          useFallback: false,
          imageUrl
        }
        return acc
      },
      {} as FallbackImageData
    )

    // Only update state if the new imageUrl is different to the current one
    const shouldSetImage = Object.keys(newFallbackState).some((key) => {
      return newFallbackState[key].imageUrl !== useFallbackImage[key]?.imageUrl
    })

    // If the imageUrl has changed, update the state
    if (shouldSetImage) {
      setUseFallbackImage(newFallbackState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData])

  /** Handles error for single images. Sets the fallback image state to true for the default key */
  const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    if (type === ImageTagType.Standard) {
      // Handles error For standard img tag, updates the default fallback
      e.currentTarget.onerror = null
      setUseFallbackImage({
        ...useFallbackImage,
        default: {
          ...useFallbackImage['default'],
          useFallback: true
        }
      })
      return
    }

    // Handles error for responsive picture tags. Sets the fallback image state to true for the corresponding media query */
    const pictureElement = e.currentTarget.parentNode as HTMLPictureElement
    if (pictureElement) {
      const sources = pictureElement.getElementsByTagName('source')

      for (let i = 0; i < sources.length; i++) {
        const sourceElement = sources[i]
        const mediaQuery = sourceElement.media

        if (
          useFallbackImage[mediaQuery] &&
          !useFallbackImage[mediaQuery]?.useFallback
        ) {
          setUseFallbackImage({
            ...useFallbackImage,
            [mediaQuery]: {
              ...useFallbackImage[mediaQuery],
              useFallback: true
            }
          })
          break
        }
      }
    }
  }

  /** Gets CDN transformed image or fallback image based on state boolean */
  const getImage = (width: number, height: number, mediaQuery?: string) => {
    const { imageUrl, useFallback } = useFallbackImage[mediaQuery || 'default']
    return useFallback
      ? imageUrl
      : createTransformedImageUrl(imageUrl, width, height)
  }

  /** Takes original image and applies CDN image transformations with width/height params */
  const createTransformedImageUrl = (
    sourceImageUrl: string,
    width: number,
    height: number
  ) =>
    `https://assets.${process.env.REACT_APP_ASSET_HOST}/cdn-cgi/image/width=${width},height=${height},format=auto/${sourceImageUrl}`

  return { handleImageError, useFallbackImage, getImage }
}
