import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
export const ProductsContainer = styled.ul(({ theme }: any) => ({
  margin: '0',
  fontSize: theme.fontSizes[0],
  listStyleType: 'none',
  backgroundColor: 'white',
  width: '100%',
  padding: '16px 16px 0',
  '&:last-of-type': {
    marginBottom: '16px'
  },
  [theme.mediaQueries.viewport7]: {
    padding: '16px 16px 4px',
    borderRadius: '12px'
  },
  [theme.mediaQueries.viewport9]: {
    padding: '32px 16px 0'
  }
}))

export const List = styled.ul(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  listStyleType: 'none',
  display: 'grid',
  gridTemplateColumns: 'auto',
  marginTop: '16px',
  [theme.mediaQueries.viewport9]: {
    gap: '16px',
    marginBottom: 0,
    gridTemplateColumns: 'repeat(2, minmax(426px, 1fr))'
  },
  [theme.mediaQueries.viewport14]: {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'
  },
  [theme.mediaQueries.viewport16]: {
    gridTemplateColumns: 'repeat(3, minmax(426px, 1fr))',
    gap: '32px'
  }
}))

export const CategoryTitle = styled.div(({ theme }: any) => {
  return {
    fontWeight: 'bold',
    fontFamily: theme.fonts.heading.family,
    fontSize: theme.fontSizes[3],
    marginBottom: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word'
  }
})

export const CategoryDescription = styled.div(({ theme }: any) => {
  return {
    fontFamily: theme.fonts.heading.family,
    fontSize: theme.fontSizes[1]
  }
})

export const LineBreakWrapper = styled.div<
  StyledHTMLElement & { isLast: boolean },
  Required<Theme>
>(({ theme, isLast }) => ({
  display: isLast ? 'none' : 'block',
  [theme.mediaQueries.viewport9]: {
    display: 'none'
  }
}))
