import styled from '@emotion/styled'
import { RefObject, useEffect, useState } from 'react'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { IoChevronBack as BackChevron } from 'react-icons/io5'
import { useMediaQueries } from 'shop/hooks'

type Props = {
  inputValue: string
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  inputRef: RefObject<HTMLInputElement>
  handleClearInput: () => void
  onClose: () => void
  inputPlaceholder: string
}

const AddressInputBar = ({
  inputValue,
  handleInputChange,
  inputRef,
  handleClearInput,
  onClose,
  inputPlaceholder
}: Props) => {
  const { isMobile } = useMediaQueries()
  const [isInputFocused, setIsInputFocused] = useState(false)

  const addScrollListeners = (blurInput: () => void) => {
    // use 'touchmove' as scroll events are too unpredictable on native devices
    window.addEventListener('touchmove', blurInput)
  }

  const removeScrollListeners = (blurInput: () => void) => {
    window.removeEventListener('touchmove', blurInput)
  }

  // On FullScreenModal scroll, blur the input to hide the keyboard
  useEffect(() => {
    if (!isMobile) return
    const blurInput = () => inputRef.current?.blur()

    if (!isInputFocused) {
      removeScrollListeners(blurInput)
      return
    }

    // add a delay so the virtual keyboard has time to scroll up
    setTimeout(() => addScrollListeners(blurInput), 300)

    return () => {
      removeScrollListeners(blurInput)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputFocused])

  return (
    <AddressInputHeader>
      <IconContainer onClick={onClose}>
        <BackChevron />
      </IconContainer>
      <AddressInput
        data-testid='address-input-bar'
        ref={inputRef}
        onChange={handleInputChange}
        value={inputValue}
        autoFocus={false}
        placeholder={inputPlaceholder}
        autoComplete='off'
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
      />
      {!!inputValue.length && (
        <ClearControl onClick={handleClearInput}>Clear</ClearControl>
      )}
    </AddressInputHeader>
  )
}

const AddressInputHeader = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #D9D9D9',

    // disable lastpass
    '& [data-lastpass-icon-root]': {
      display: 'none'
    }
  })
)

const IconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    padding: '19px 8px 19px 16px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    // disable blue hightlight of user clicks
    WebkitTapHighlightColor: 'transparent',

    [theme.mediaQueries.viewport7]: {
      padding: '19px 8px 19px 32px'
    },

    '& svg': {
      height: '20px',
      width: '20px'
    }
  })
)

const AddressInput = styled.input<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    flex: 1,
    border: 0,
    outline: 0,
    padding: 0,
    width: '100%',
    fontSize: '14px',
    fontFamily: theme.fonts['body'].family,

    // disable blue hightlight of user clicks
    WebkitTapHighlightColor: 'transparent'
  })
)

const ClearControl = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    cursor: 'pointer',
    fontSize: '14px',
    height: '56px',
    alignContent: 'center',
    padding: '0 16px 0 12px',

    // disable blue hightlight of user clicks
    WebkitTapHighlightColor: 'transparent',

    [theme.mediaQueries.viewport7]: {
      padding: '0 32px 0 12px'
    }
  })
)

export default AddressInputBar
