import styled from '@emotion/styled'

const SoldOutOverlay = () => {
  return (
    <>
      <Badge data-testid='sold-out-badge'>Sold out</Badge>
      <Overlay />
    </>
  )
}

const Overlay = styled.div(({ theme }: any) => ({
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  opacity: 0.5,
  zIndex: 5,
  pointerEvents: 'none',
  borderRadius: '12px'
}))

const Badge = styled.div(({ theme }: any) => ({
  position: 'absolute',
  top: '4px',
  right: '4px',
  height: '64px',
  width: '64px',
  color: 'white',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontWeight: 'bold',
  padding: '8px',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 6
}))

export default SoldOutOverlay
