import { useState } from 'react'
import { MdInfoOutline as InfoIcon } from 'react-icons/md'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import TooltipPopup from './TooltipPopup'

const IconTooltip = ({
  tooltipMessage,
  iconSize = '16px'
}: {
  tooltipMessage: string
  iconSize?: string
}) => {
  const [showToolip, setShowToolip] = useState(false)
  return (
    <TooltipContainer
      onMouseEnter={() => setShowToolip(true)}
      onMouseLeave={() => setShowToolip(false)}
    >
      {showToolip && <TooltipPopup tooltipMessage={tooltipMessage} />}
      <InfoIcon data-testid='info-icon' size={iconSize} />
    </TooltipContainer>
  )
}

const TooltipContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'relative',

    '& svg': {
      color: '#595959'
    }
  })
)

export default IconTooltip
