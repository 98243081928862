import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { createAddressString } from 'shop/utils/address'
import { CustomerAddress } from 'shop/types'
import { trackGA4CustomSearchBySavedAddress } from 'tracker/GA/custom'

type Props = {
  customerAddress: CustomerAddress
  selectCustomerAddress: (customerAddress: CustomerAddress) => void
}

const SavedAddressItem = ({
  customerAddress,
  selectCustomerAddress
}: Props) => {
  const hasLabel = !!customerAddress.name
  const formattedAddress = createAddressString(customerAddress.address, {
    withCommas: true
  })
  return (
    <ItemContainer
      hasLabel={hasLabel}
      onClick={() => {
        trackGA4CustomSearchBySavedAddress('slerpGA4Tracker')
        trackGA4CustomSearchBySavedAddress('merchantGA4Tracker')
        selectCustomerAddress(customerAddress)
      }}
    >
      <AddressContentContainer>
        {hasLabel && (
          <AddressNameLabel>{customerAddress.name}</AddressNameLabel>
        )}
        <AddressContent>{formattedAddress}</AddressContent>
      </AddressContentContainer>
    </ItemContainer>
  )
}

const ItemContainer = styled.div<
  StyledHTMLElement & { hasLabel: boolean },
  Required<Theme>
>(({ theme, hasLabel }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: hasLabel ? '4.5px 16px' : '14px 16px',
  borderRadius: '0',
  gap: '8px',
  fontFamily: theme.fonts['body'].family,
  cursor: 'pointer',

  // disable blue hightlight of user clicks
  WebkitTapHighlightColor: 'transparent',

  '&:hover': {
    background: '#FAFAFA'
  },

  [theme.mediaQueries.viewport7]: {
    padding: hasLabel ? '4.5px 12px' : '14px 12px',
    borderRadius: '12px'
  }
}))

const AddressContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '4px',
    minWidth: 0 // for ellipsis to work with flex
  })
)
const AddressNameLabel = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 500,
    fontFamily: theme.fonts['body'].family,
    color: '#2A2A2A',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
)
const AddressContent = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: '14px',
    fontFamily: theme.fonts['body'].family,
    lineHeight: '16px',
    color: '#59595A',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
)

export default SavedAddressItem
