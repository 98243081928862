import { createContext, useContext, useEffect, useState } from 'react'
import {
  consumerClientContext,
  getClientLinks,
  QUERY_GET_FILTERED_PARTNER,
  QUERY_GET_MERCHANT,
  QUERY_GET_PARTNER,
  QUERY_GET_PARTNER_AND_CUSTOMER,
  useShopClient
} from 'shop/client'
import { ApolloClient } from '@apollo/client'
import {
  Store,
  Partner,
  PartnerStoreBrowse,
  FilteredPartnerParams
} from 'shop/components/Landing'
import { PartnerStore } from 'shop/components'
import config from 'shop/config'
import { CustomerDetails, LatLng, ShopConfig } from 'shop/types'
import {
  initializeMerchantGA4,
  initializeTagManager,
  initializePixel,
  initializeSlerpGA4,
  initializeGoogleConversion
} from 'tracker'
import AllTrackers from 'tracker/AllTrackers'
import { expandEnrichedEventData } from 'tracker/utils'
import { useRwg } from '../useRwg'
import { mapStoreSettingsIntoObject } from './helpers'
import { CategoryWithProducts } from 'shop/components/Shop/Categories'

/**
 *  The shop context interface
 */
export type ShopInterface = {
  config: ShopConfig
  merchant: any
  setMerchant: React.Dispatch<any>
  partner: Partner | null
  setPartner: React.Dispatch<Partner | null>
  allStores: Store[]
  setAllStores: React.Dispatch<Store[]>
  customerId: string
  setCustomerId: React.Dispatch<any>
  isStoreLoading: boolean
  setIsStoreLoading: React.Dispatch<any>
  isProductsLoading: boolean
  setIsProductsLoading: React.Dispatch<any>
  useShopClient(): ApolloClient<object>
  customerApiKey: string
  setCustomerApiKey: React.Dispatch<any>
  customerDetails: CustomerDetails | null
  setCustomerDetails: React.Dispatch<Partial<CustomerDetails> | null>
  currentStore: Store | undefined
  setCurrentStore: React.Dispatch<Store>
  refetchMerchantAndStores: () => void
  isPartnerLoading: boolean
  setIsPartnerLoading: React.Dispatch<boolean>
  fetchPartnerAndCustomer: () => void
  setCurrentPartnerStore: React.Dispatch<PartnerStore>
  currentPartnerStore: PartnerStore | null
  categories: CategoryWithProducts[]
  setCategories: React.Dispatch<CategoryWithProducts[]>
  isLocationServiceEnabled: boolean
  setIsLocationServiceEnabled: React.Dispatch<boolean>
  isSortedPartnerStoresLoading: boolean
  setIsSortedPartnerStoresLoading: React.Dispatch<boolean>
  fetchSortedPartnerStores: (
    args: FilteredPartnerParams
  ) => Promise<PartnerStoreBrowse[]>
}

const initialState = {
  appContent: null,
  config,
  useShopClient,
  merchant: null,
  setMerchant: () => {},
  partner: null,
  setPartner: () => {},
  allStores: [],
  setAllStores: () => {},
  customerId: '',
  setCustomerId: () => {},
  store: null,
  setStore: () => {},
  isStoreLoading: false,
  setIsStoreLoading: () => {},
  isProductsLoading: false,
  setIsProductsLoading: () => {},
  customerApiKey: '',
  setCustomerApiKey: () => {},
  customerDetails: null,
  setCustomerDetails: () => {},
  currentStore: undefined,
  setCurrentStore: () => {},
  refetchMerchantAndStores: () => {},
  isPartnerLoading: true,
  setIsPartnerLoading: () => {},
  fetchPartnerAndCustomer: () => {},
  setCurrentPartnerStore: () => {},
  currentPartnerStore: null,
  categories: [],
  setCategories: () => {},
  isLocationServiceEnabled: false,
  setIsLocationServiceEnabled: () => {},
  isSortedPartnerStoresLoading: false,
  setIsSortedPartnerStoresLoading: () => {},
  fetchSortedPartnerStores: () => Promise.resolve([])
}

export const SetupSlerpShop = () => {
  const client = useShopClient()
  const [merchant, setMerchant] = useState<any>() // No Type for the Hasura Merchant :'(
  const [partner, setPartner] = useState<Partner | null>(null)
  const [isPartnerLoading, setIsPartnerLoading] = useState<boolean>(true)
  const [allStores, setAllStores] = useState<Store[]>([])
  const [currentStore, setCurrentStore] = useState<Store>()
  const [customerId, setCustomerId] = useState(
    localStorage.getItem('customerId') || ''
  )
  const [customerApiKey, setCustomerApiKey] = useState(
    localStorage.getItem('customerApiKey') || ''
  )
  const [isStoreLoading, setIsStoreLoading] = useState(true)
  const [isProductsLoading, setIsProductsLoading] = useState(true)
  const [customerDetails, setCustomerDetails] =
    useState<CustomerDetails | null>(null)
  const [hasRecentlyFetchedMerchant, setHasRecentlyFetchedMerchant] =
    useState(false)
  const [hasRecentlyFetchedPartner, setHasRecentlyFetchedPartner] =
    useState(false)
  const [currentPartnerStore, setCurrentPartnerStore] =
    useState<PartnerStore | null>(null)
  const [categories, setCategories] = useState<CategoryWithProducts[]>([])
  const [isLocationServiceEnabled, setIsLocationServiceEnabled] =
    useState(false)
  const [isSortedPartnerStoresLoading, setIsSortedPartnerStoresLoading] =
    useState(false)

  const { setRWGOnEnrichedEventData } = useRwg()

  /** Update Trackers with new Merchant data */
  useEffect(() => {
    if (!!merchant?.id) {
      expandEnrichedEventData(AllTrackers.getInstance().slerpGA4Tracker, {
        merchant_id: merchant.id,
        merchant_slug: merchant.slug
      })
      expandEnrichedEventData(AllTrackers.getInstance().merchantGA4Tracker, {
        merchant_id: merchant.id,
        merchant_slug: merchant.slug
      })
    }
  }, [merchant?.id, merchant?.slug])

  useEffect(() => {
    setHasRecentlyFetchedMerchant(true)
    client
      .query({
        query: QUERY_GET_MERCHANT,
        variables: { slug: config.domain }
      })
      .then((result) => {
        const [merchant] = result?.data?.merchants
        let stores = result?.data?.stores

        if (!!stores.length) stores = mapStoreSettingsIntoObject(stores)

        // Initializing these analytics properties should only happen once
        initializeMerchantGA4(merchant, customerId)
        initializeTagManager(merchant)
        initializePixel(merchant)

        initializeGoogleConversion(merchant)
        initializeSlerpGA4(merchant, customerId)
        setMerchant(merchant)
        setAllStores(stores)
        setRWGOnEnrichedEventData() // has to be called once GA4 has been initialised

        setTimeout(() => {
          setHasRecentlyFetchedMerchant(false)
        }, 10000) // 10 secs
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Fetch the Partner and Customer query and set it in the Context.
   * Has a debounce of 5 mins
   */
  const fetchPartnerAndCustomer = () => {
    if (hasRecentlyFetchedPartner) return
    setHasRecentlyFetchedPartner(true)
    setIsPartnerLoading(true)

    const query = customerId
      ? QUERY_GET_PARTNER_AND_CUSTOMER
      : QUERY_GET_PARTNER

    client
      .query({
        query: query,
        ...consumerClientContext,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      })
      .then(({ data }) => {
        setPartner(data.partner)

        if (customerId) {
          setCustomerDetails(data.customer)
        }
      })
      .finally(() => {
        setIsPartnerLoading(false)
      })

    const SECOND = 1000
    setTimeout(() => {
      setHasRecentlyFetchedPartner(false)
    }, 300 * SECOND) // 5 mins = 300 secs
  }

  const fetchSortedPartnerStores = async (
    filterArgs: FilteredPartnerParams
  ) => {
    setIsSortedPartnerStoresLoading(true)

    const query = QUERY_GET_FILTERED_PARTNER

    return client
      .query({
        query: query,
        ...consumerClientContext,
        variables: filterArgs,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      })
      .then(({ data }) => {
        return (data.partner?.stores as PartnerStoreBrowse[]) || []
      })
      .catch((err) => {
        console.error(err)
        return []
      })
      .finally(() => {
        setTimeout(() => setIsSortedPartnerStoresLoading(false), 100)
      })
  }

  useEffect(() => {
    // get partner data on load
    fetchPartnerAndCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Update client with Merchant data */
  useEffect(() => {
    if (!!merchant?.id) {
      const setMerchantRequestHeader = (merchantId: string) => {
        const { domain, apiKey } = config
        const clientLinks = getClientLinks(domain, apiKey, merchantId)
        client.setLink(clientLinks)
      }
      // refresh client link with merchant.id
      setMerchantRequestHeader(merchant.id)
    }
  }, [merchant?.id])

  const refetchMerchantAndStores = () => {
    if (hasRecentlyFetchedMerchant) return
    setHasRecentlyFetchedMerchant(true)
    client
      .query({
        query: QUERY_GET_MERCHANT,
        variables: { slug: config.domain }
      })
      .then((result) => {
        const [merchant] = result?.data?.merchants
        let stores = result?.data?.stores

        if (!!stores.length) stores = mapStoreSettingsIntoObject(stores)

        setMerchant(merchant)
        setAllStores(stores)
        setTimeout(() => {
          setHasRecentlyFetchedMerchant(false)
        }, 10000) // 10 secs
      })
  }

  return {
    config,
    useShopClient,
    merchant,
    setMerchant,
    partner,
    setPartner,
    allStores,
    setAllStores,
    customerId,
    setCustomerId,
    isStoreLoading,
    setIsStoreLoading,
    isProductsLoading,
    setIsProductsLoading,
    customerApiKey,
    setCustomerApiKey,
    customerDetails,
    setCustomerDetails,
    currentStore,
    setCurrentStore,
    refetchMerchantAndStores,
    isPartnerLoading,
    setIsPartnerLoading,
    fetchPartnerAndCustomer,
    setCurrentPartnerStore,
    currentPartnerStore,
    categories,
    setCategories,
    isLocationServiceEnabled,
    setIsLocationServiceEnabled,
    isSortedPartnerStoresLoading,
    setIsSortedPartnerStoresLoading,
    fetchSortedPartnerStores
  }
}

export const ShopContext = createContext<ShopInterface>(initialState)

export const useShop = () => {
  return useContext(ShopContext)
}
