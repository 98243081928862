import { SelectedModifier } from '..'
import { ProductModifierGroup } from '../Product/types'
import { ProductModifier } from '../Shop/Categories/types'

interface ProductModifierGroupMinMax
  extends Omit<ProductModifierGroup, 'modifiers'> {
  maximum: number
  minimum: number
  maximum_enabled: boolean
  minimum_enabled: boolean
}

export const getInputType = ({
  minimum,
  maximum,
  maximum_enabled,
  minimum_enabled
}: ProductModifierGroupMinMax) => {
  if (minimum_enabled && minimum === 1 && maximum_enabled && maximum === 1)
    return 'radio'
  return 'checkbox'
}

export const getSubheading = ({
  minimum,
  maximum,
  maximum_enabled,
  minimum_enabled
}: ProductModifierGroupMinMax) => {
  if (minimum_enabled && maximum_enabled && minimum === maximum)
    return `Choose ${minimum}`

  if (minimum_enabled && maximum_enabled)
    return `Choose between ${minimum} and ${maximum}`

  if (minimum_enabled && !maximum_enabled) return `Choose at least ${minimum}`

  if (!minimum_enabled && maximum_enabled) return `Choose up to ${maximum}`

  return ''
}

export const isValid = (
  group: ProductModifierGroupMinMax,
  quantity: number
) => {
  const { minimum, maximum, maximum_enabled, minimum_enabled } = group

  if (minimum_enabled && !maximum_enabled) return quantity >= minimum

  if (!minimum_enabled && maximum_enabled) return quantity <= maximum

  if (minimum_enabled && maximum_enabled && minimum !== maximum)
    return minimum <= quantity && quantity <= maximum

  if (minimum_enabled && maximum_enabled && minimum === maximum)
    return minimum === quantity && quantity === maximum

  return true
}

export const enableIncrement = (
  group: ProductModifierGroupMinMax,
  quantity: number
) => {
  const { minimum, maximum, maximum_enabled, minimum_enabled } = group

  if (minimum_enabled && !maximum_enabled) return true

  if (!minimum_enabled && maximum_enabled) return quantity <= maximum

  if (minimum_enabled && maximum_enabled && minimum !== maximum)
    return quantity <= maximum

  if (minimum_enabled && maximum_enabled && minimum === maximum)
    return minimum >= quantity && quantity <= maximum

  return true
}

export const calculateGroupQuantity = (selectedItems: any) => {
  return Object.keys(selectedItems).reduce((acc, key) => {
    return (acc += selectedItems[key].quantity)
  }, 0)
}

export const formatSelectedModifierV2 = (
  modifier: ProductModifier,
  groupId: string,
  quantity: number = 1
): SelectedModifier => {
  const { id, price } = modifier
  return {
    id: '', // malalagyan to ng uuid bago ma-add yung product
    modifier_id: id,
    quantity: Number(quantity),
    amount: Number(price.base) * quantity || 0,
    price: Number(price.base),
    modifier_group_id: groupId
  }
}
