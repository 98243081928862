import styled from '@emotion/styled'
import { Button } from '..'
import { ProductsContainer } from './commonStyles'

interface EmptyStateProps {
  searchValue: string
  setSearchValue: (input: string) => void
}

export const EmptyState = ({
  searchValue,
  setSearchValue
}: EmptyStateProps) => {
  const clearSearchTerm = () => setSearchValue('')

  return (
    <ProductsContainer>
      <Container data-testid='empty-products-list' searchValue={searchValue}>
        {searchValue ? (
          <>
            <p>There are no results for "{searchValue}"</p>
            <Button variant='secondary' width='auto' onClick={clearSearchTerm}>
              Clear Search
            </Button>
          </>
        ) : (
          <>
            <h2>No Products Available</h2>
            <p>
              It looks like there are no products available on this store based
              on your selected store, fulfilment type, and time.
            </p>
          </>
        )}
      </Container>
    </ProductsContainer>
  )
}

const Container = styled.div(({ theme, searchValue }: any) => ({
  padding: '40px 16px 0',
  height: '300px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '16px',
  '> h2': {
    fontSize: theme.fontSizes[2],
    fontWeight: 500
  },
  '> p': {
    fontSize: theme.fontSizes[2],
    fontWeight: searchValue ? 500 : 400,
    margin: 0
  }
}))
