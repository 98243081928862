import styled from '@emotion/styled'
import LoginIcon from './LoginIcon'
import { useConsumerCart, useModal, useShop } from 'shop/hooks'
import ViewCartButton from './ViewCartButton'
import { CheckoutButton, CartTotal } from '.'

const CartActions = () => {
  const { isStoreLoading } = useShop()
  const { isModalOpen, openModal, portalModal } = useModal()
  const { cart: consumerCart, cartLoading } = useConsumerCart()
  const { orderItems, summary } = consumerCart || {}

  const cartSubtotal = summary?.subtotal.base

  const handleOpenCartModal = () => {
    if (cartLoading) return
    openModal('cart')
  }

  const handleViewCart = () => {
    if (cartLoading) return
    openModal('cart')
  }

  return !isStoreLoading ? (
    <Actions>
      <CartTotal
        itemCount={orderItems?.length}
        totalAmount={cartSubtotal || '0.00'}
        handleOpenCartModal={handleOpenCartModal}
      />
      <LoginIcon />
      <ViewCartButton onClick={handleViewCart} isCartLoading={cartLoading} />
      <CheckoutButton />
      {isModalOpen('cart') && portalModal('cart')}
    </Actions>
  ) : null
}

const Actions = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  gap: '12px'
}))

export default CartActions
