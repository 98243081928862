import styled from '@emotion/styled'
import { ProductDescription } from '../../Description'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Allergens, DietaryRequirements } from '../'

interface Props {
  description: string | null
  calorieData?: number
  allergens: string[]
  dietaryRequirements: string[]
  forceShowDescription: boolean
}

const ProductDetails = ({
  description = '',
  calorieData,
  allergens,
  forceShowDescription = false,
  dietaryRequirements
}: Props) => {
  const noDetails = !description && !calorieData && !allergens?.length
  if (noDetails) return <></>

  return (
    <Container data-testid='product-details'>
      <DietaryInformation>
        {!!calorieData && (
          <CalorieDetails>
            {calorieData}kcal<span> *Adults need around 2000kcal a day</span>
          </CalorieDetails>
        )}
        <DietaryRequirements dietaryRequirements={dietaryRequirements} />
      </DietaryInformation>
      {!!description && (
        <ProductDescription
          description={description}
          forceShowDescription={forceShowDescription}
        />
      )}
      {!!allergens?.length && <Allergens allergens={allergens} />}
      {/* when we force show, it means nothing below, so no need for a divider */}
      {!forceShowDescription && <Divider />}
    </Container>
  )
}

const Divider = styled.hr<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  border: 0,
  margin: 0,
  padding: 0,
  borderBottom: '2px solid #F0F0F0'
}))

const Container = styled.div<StyledHTMLElement>(() => ({
  padding: '0 0 24px'
}))

const DietaryInformation = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    margin: '0 0 8px',
    color: theme.colors['textMute'],
    fontSize: '14px'
  })
)

const CalorieDetails = styled.span<StyledHTMLElement, Required<Theme>>({
  fontSize: '12px',
  fontStyle: 'italic',
  marginRight: '8px'
})

export default ProductDetails
