import React from 'react'
import {
  StoreListItemLoader,
  StoreListSortedByLabelLoader
} from 'shop/components/Loader'
import { LandingStoreListItemContainer } from './LandingStoreListItem'

const BrowseStoresListLoader = () => {
  return (
    <>
      <StoreListSortedByLabelLoader />
      {Array(6)
        .fill(null)
        .map((value, index) => (
          <React.Fragment key={`store-list-item-loader-${index + 1}`}>
            <LandingStoreListItemContainer>
              <StoreListItemLoader />
            </LandingStoreListItemContainer>
          </React.Fragment>
        ))}
    </>
  )
}

export default BrowseStoresListLoader
