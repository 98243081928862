import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useEffect, useLayoutEffect, useState } from 'react'
import { IoChevronDownCircleOutline as ChevronIcon } from 'react-icons/io5'
import {
  PartnerStore,
  StoreRankReasons,
  FulfillmentRankReasonsTags,
  UserRankReasonsTags
} from '../types'
import { formatPartnerAddressToString } from './utils'
import OpeningHoursSection from './OpeningHoursSection'
import ExpandableDiv from 'shop/components/ExpandableContainer/ExpandableDiv'
import FulfillmentInfo from './FulfillmentInfo'
import { fadeIn } from 'shop/components/Shop/commonStyles'

type Props = {
  store: PartnerStore
  isDeliveryEnabled: boolean
  isPickupEnabled: boolean
  onLoadDateTime: Date
  distance?: number
  rankReasons: StoreRankReasons[]
}

const LandingStoreListItem = ({
  store,
  isDeliveryEnabled,
  isPickupEnabled,
  onLoadDateTime,
  distance,
  rankReasons
}: Props) => {
  const [lineClamp, setLineClamp] = useState<number>(2)
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)
  const [descriptionClampedHeight, setDescriptionClampedHeight] = useState<
    number | null
  >(null)
  const [isStoreInfoExpanded, setIsStoreInfoExpanded] = useState<boolean>(false)

  const sortedRankReasons =
    rankReasons.length >= 2 ? rankReasons.slice().sort() : rankReasons

  const hasPreviouslyOrdered = sortedRankReasons.find(
    (rankReason) => rankReason === 'CUSTOMER_ORDERED_FROM_STORE'
  )

  useLayoutEffect(() => {
    // set height of clamped <p> so we can animate it later
    const descriptionEl = document.getElementById(`${store.slug}-description`)
    if (descriptionEl) {
      const descriptionElHeight = descriptionEl.clientHeight
      setDescriptionClampedHeight(descriptionElHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    // delay lineClamp so we can animate the collapse of the text
    if (isStoreInfoExpanded) return
    const timeout = setTimeout(() => {
      setLineClamp(2)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStoreInfoExpanded])

  const handleExpandToggle = () => {
    // set lineClamp to 0 here before expand render
    if (!isStoreInfoExpanded) setLineClamp(0)
    setIsStoreInfoExpanded(!isStoreInfoExpanded)
  }

  // Wait for component to load after fade in to not trigger other CSS transitions.
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasLoaded(true)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <LandingStoreListItemContainer>
      <StoreInfo>
        <HeaderContent>
          <LeftHeaderContent>
            {hasPreviouslyOrdered && (
              <ul>
                <StoreTag usePrimaryColor={true}>
                  {UserRankReasonsTags['CUSTOMER_ORDERED_FROM_STORE']}
                </StoreTag>
              </ul>
            )}
            <h3>
              {store.name}
              {!!store.address.zip && (
                <span>&nbsp;&nbsp;{store.address.zip}</span>
              )}
            </h3>
          </LeftHeaderContent>
          <RightHeaderContent isStoreInfoExpanded={isStoreInfoExpanded}>
            {distance && (
              <DistanceContainer>
                <div>{distance}</div>
                <span>miles</span>
              </DistanceContainer>
            )}
            <ChevronIcon
              data-testid={`${store.slug}-expand-icon`}
              onClick={handleExpandToggle}
            />
          </RightHeaderContent>
        </HeaderContent>
        <BodyContent>
          {!!store.description && (
            <ExpandableDiv
              isExpanded={isStoreInfoExpanded}
              collapsedHeight={
                !!descriptionClampedHeight
                  ? descriptionClampedHeight
                  : 'content'
              }
              transition={hasLoaded ? undefined : 'none'}
            >
              <Description
                id={`${store.slug}-description`}
                lineClamp={lineClamp}
                isStoreInfoExpanded={isStoreInfoExpanded}
                onClick={!isStoreInfoExpanded ? handleExpandToggle : () => {}}
              >
                {store.description}
              </Description>
            </ExpandableDiv>
          )}
          <ExpandableDiv
            id={`${store.slug}-expandable-info`}
            isExpanded={isStoreInfoExpanded}
            collapsedHeight={0}
            transition={hasLoaded ? undefined : 'none'}
          >
            <ContentContainer
              style={{
                paddingTop: !!store.description ? '16px' : '8px'
              }}
            >
              <ContentItem>
                <h4>Address</h4>
                <p>{formatPartnerAddressToString(store.address)}</p>
              </ContentItem>
              <ContentItem>
                <h4>Opening Hours</h4>
                <OpeningHoursSection
                  openingHours={store.openingHours}
                  storeSlug={store.slug}
                />
              </ContentItem>
            </ContentContainer>
          </ExpandableDiv>
          {!!sortedRankReasons.length && (
            <StoreTagsContainer>
              {sortedRankReasons
                .filter((reason) => !!FulfillmentRankReasonsTags[reason])
                .map((reason) => (
                  <StoreTag key={reason}>
                    {FulfillmentRankReasonsTags[reason]}
                  </StoreTag>
                ))}
            </StoreTagsContainer>
          )}
        </BodyContent>
      </StoreInfo>
      <Divider />
      <FulfillmentInfo
        store={store}
        onLoadDateTime={onLoadDateTime}
        isDeliveryEnabled={isDeliveryEnabled}
        isPickupEnabled={isPickupEnabled}
      />
    </LandingStoreListItemContainer>
  )
}

export const LandingStoreListItemContainer = styled.li<
  StyledHTMLElement,
  Required<Theme>
>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px 12px',
  border: '1px solid #D9D9D9',
  borderRadius: '12px',
  boxShadow: `0px 3px 8px -4px rgba(0, 0, 0, 0.12)`,
  animation: `${fadeIn} 0.3s ease-in-out forwards`
}))

const HeaderContent = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    fontFamily: theme.fonts['heading'].family,
    justifyContent: 'space-between',
    gap: '12px'
  })
)

const LeftHeaderContent = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignSelf: 'center',

    // store name
    '>h3': {
      fontSize: '18px',
      lineHeight: '21px',
      fontWeight: 600,

      // postcode
      '>span': {
        fontSize: '16px',
        color: '#8B8B8B',
        lineHeight: '19px',
        fontWeight: 600
      }
    }
  })
)

const RightHeaderContent = styled.div<
  StyledHTMLElement & { isStoreInfoExpanded: boolean },
  Required<Theme>
>(({ theme, isStoreInfoExpanded }) => ({
  display: 'flex',
  gap: '4px',

  '>svg': {
    minHeight: '24px',
    minWidth: '24px',
    cursor: 'pointer',
    rotate: isStoreInfoExpanded ? '180deg' : '0deg',
    transition: 'all 500ms ease-in-out',
    // disable blue hightlight of user clicks
    WebkitTapHighlightColor: 'transparent',

    '&:hover': {
      opacity: 0.8
    },
    '&:active': {
      opacity: 0.6
    }
  }
}))

const Description = styled.p<
  StyledHTMLElement & { isStoreInfoExpanded: boolean; lineClamp: number },
  Required<Theme>
>(({ theme, isStoreInfoExpanded, lineClamp }) => ({
  fontSize: '14px',
  padding: '0',
  paddingTop: '8px',
  margin: '0',
  fontFamily: theme.fonts['body'].family,
  color: '#59595A',
  cursor: isStoreInfoExpanded ? 'inherit' : 'pointer',
  // set max lines to 2
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: lineClamp
}))

const StoreInfo = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const Divider = styled.hr(() => ({
  width: '100%',
  margin: 0,
  border: 0,
  borderBottom: `1px solid #EFEFF0`,
  borderRadius: '1px'
}))

const BodyContent = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column'
  })
)

const ContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  })
)

const ContentItem = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontFamily: theme.fonts['body'].family,
    '>h4': {
      fontWeight: 600,
      fontSize: '16px',
      margin: 0,
      marginBottom: '4px',
      padding: 0,
      color: '#2A2A2A'
    },

    '& p': {
      margin: 0,
      padding: 0,
      fontSize: '14px',
      color: '#59595A'
    }
  })
)

const DistanceContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    lineHeight: '16px',
    alignItems: 'center',
    fontWeight: 500,

    '& span': {
      lineHeight: '9px',
      fontSize: '9px'
    }
  })
)

const StoreTagsContainer = styled.ul<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '16px',
    gap: '8px'
  })
)

const StoreTag = styled.li<
  StyledHTMLElement & { usePrimaryColor?: boolean },
  Required<Theme>
>(({ theme, usePrimaryColor = false }) => ({
  padding: '4px 8px',
  border: `1px solid ${usePrimaryColor ? theme.colors['primary'] : '#000'}`,
  borderRadius: '6px',
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: theme.fonts['body'].family,
  color: usePrimaryColor ? theme.colors['primary'] : '#000',
  width: 'fit-content'
}))

export default LandingStoreListItem
