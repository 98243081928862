import { useEffect, useState, useCallback, useRef } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { StickyCard } from '../StickyCard'
import { ViewMenuHeader } from '../ViewMenuHeader'
import { LocationMap } from './LocationMap'
import { ShopAddress } from './ShopAddress'
import { ShopSchedule } from './ShopSchedule'
import { ShopAbout } from './ShopAbout'
import { useAppContent, useShop } from 'shop/hooks'
import throttle from 'lodash/throttle'
import { SHOP_INFO } from '../commonStyles'

export const ShopInformation = () => {
  const { currentPartnerStore } = useShop()
  const { merchantName } = useAppContent()
  const [mapInView, setMapInView] = useState(false)

  const { name: storeName } = currentPartnerStore || {}
  const mainSectionRef = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(() => {
    if (!mainSectionRef?.current || mapInView) {
      return
    }

    const pageViewPointOffset = 200

    const upperBound =
      mainSectionRef.current.getBoundingClientRect().top + window.pageYOffset

    if (
      window.scrollY + window.innerHeight + pageViewPointOffset >=
      upperBound
    ) {
      setMapInView(true)
    }
  }, [mapInView])

  useEffect(() => {
    const onScroll = throttle(handleScroll, 300)

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [handleScroll])

  return (
    <StickyCard
      header={
        <ViewMenuHeader>
          Information - {merchantName} {storeName}
        </ViewMenuHeader>
      }
      type={SHOP_INFO}
    >
      <MainSection ref={mainSectionRef}>
        <MapWrapper>{mapInView && <LocationMap />}</MapWrapper>
        <AddressWrapper>
          <ShopAddress />
        </AddressWrapper>
        <ScheduleWrapper>
          <ShopSchedule />
        </ScheduleWrapper>
      </MainSection>
      <Divider></Divider>
      <Section>
        <ShopAbout />
      </Section>
    </StickyCard>
  )
}

const MainSection = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    rowGap: '24px',
    gridTemplateAreas: `
    'map'
    'address'
    'schedule'
  `,

    [theme.mediaQueries.viewport7]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: '20px',
      gridTemplateAreas: `
      'map map'
      'address schedule'
    `
    },

    [theme.mediaQueries.viewport10]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateAreas: `
      'map address schedule'
    `
    },

    [theme.mediaQueries.viewport12]: {
      columnGap: '52px'
    }
  })
)

const MapWrapper = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    gridArea: 'map / map',

    height: '300px',
    width: '100%',
    borderRadius: '12px',
    overflow: 'hidden',
    backgroundColor: '#F0F0F0',

    [theme.mediaQueries.viewport10]: {
      width: '400px'
    },

    [theme.mediaQueries.viewport12]: {
      width: '500px'
    }
  })
)

const AddressWrapper = styled.div(() => ({
  gridArea: 'address / address'
}))

const ScheduleWrapper = styled.div(() => ({
  gridArea: 'schedule / schedule'
}))

const Section = styled.div(() => ({}))

const Divider = styled.div(() => ({
  width: '100%',
  borderBottom: '1px solid #F0F0F0',
  margin: '24px 0'
}))
