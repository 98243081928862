import { useCallback } from 'react'
import { CurrentLocationState } from 'shop/components'
import { LandingState } from 'shop/components/Landing/LandingContext'
import {
  GeocodedAddress,
  isAddressSameAsCurrentLocation,
  handleAddressSelect
} from 'shop/components/Landing/LocationSelect/utils'
import { trackPostCodeChange } from 'shop/components/Landing/utils'
import { CustomerAddress } from 'shop/types'

type Props = {
  setUseCustomerAddress: LandingState['setUseCustomerAddress']
  currentLocation: LandingState['currentLocation']
  setCurrentLocation: LandingState['setCurrentLocation']
}

export const useLandingLocation = ({
  setUseCustomerAddress,
  currentLocation,
  setCurrentLocation
}: Props) => {
  const handleLocationChange = (
    newAddress: string,
    options?: { clearLocationOnChange?: boolean }
  ) => {
    setUseCustomerAddress(false)
    // Reset currentLocation when newAddress is empty. Used for "Clear" control.
    // also resets when we don't want to persist the currentLocation between user input changes.
    if (!newAddress || !!options?.clearLocationOnChange)
      setCurrentLocation(undefined)
  }

  const handleLocationSelect = useCallback(
    ({
      newAddress,
      geocodedAddress
    }: {
      newAddress?: string
      geocodedAddress?: GeocodedAddress
    }) => {
      if (!newAddress && !geocodedAddress) {
        setCurrentLocation(undefined)
        return
      }
      // if we are selecting the same address, skip geocode.
      if (
        isAddressSameAsCurrentLocation(currentLocation, newAddress) ||
        isAddressSameAsCurrentLocation(
          currentLocation,
          geocodedAddress?.formattedAddress
        )
      )
        return

      handleAddressSelect({
        singleLineAddress: newAddress,
        geocodedAddress
      }).then((selectedAddress) => {
        if (!selectedAddress) return
        const { formattedAddress, addressComponents, latLng } =
          selectedAddress as GeocodedAddress
        setCurrentLocation({
          address: formattedAddress,
          addressComponents: addressComponents,
          latLng: latLng
        })
        trackPostCodeChange('landing')
      })
    },
    [setCurrentLocation, currentLocation]
  )

  /** Handles the location select in the case of a Customer's Address */
  const selectCustomerAddress = (customerAddress: CustomerAddress) => {
    handleAddressSelect({ customerAddress }).then((selectedAddress) => {
      if (!selectedAddress) return
      const { address, addressId, addressComponents, latLng } =
        selectedAddress as CurrentLocationState
      // even though we are relying on `currentLocation.addressId` on the Landing page.
      // continue to set the `useCustomerAddress` state for the LandingContext.
      setUseCustomerAddress(true)
      setCurrentLocation({
        address: address,
        addressId: addressId,
        addressComponents: addressComponents,
        latLng: latLng
      })
      trackPostCodeChange('landing')
    })
  }

  return {
    handleLocationChange,
    handleLocationSelect,
    selectCustomerAddress
  }
}
