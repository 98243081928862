import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'

export interface SeeMoreProps {
  hasExpandedText: boolean
  handleClick: () => void
  children: React.ReactNode
}

const SeeMoreControl = ({ handleClick, children }: SeeMoreProps) => (
  <SeeMoreContainer data-testid='expand-control' onClick={handleClick}>
    {children}
  </SeeMoreContainer>
)

export const SeeMoreContainer = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  fontSize: '14px',
  cursor: 'pointer',
  margin: '0',
  gap: '3px',

  '& svg': {
    height: '10px',
    width: '10px'
  }
}))

export default SeeMoreControl
