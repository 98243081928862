import { SetStateAction } from 'react'
import styled from '@emotion/styled'
import { SelectedOption, AppliedModifiers } from '../../Product/types'
import { Modifiers } from 'shop/components/Modifiers'
import { VariantOptions } from 'shop/components/Variants'

import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'
import { OptionsExtraPrices } from '../ProductInfo'
import { MissingRequiredSection } from 'shop/types'
import {
  CategoryProduct,
  CategoryProductVariant,
  ProductModifierGroup,
  ProductOption
} from 'shop/components/Shop/Categories'

interface Props {
  product: CategoryProduct
  modifiers?: ProductModifierGroup[]
  isProductInStock: boolean
  productOptions: ProductOption[] | null
  selectedOptions: SelectedOption[]
  setSelectedOptions: React.Dispatch<SetStateAction<SelectedOption[] | []>>
  invalidModifierGroups: string[]
  setInvalidModifierGroups: React.Dispatch<SetStateAction<string[] | []>>
  missingRequiredSections: MissingRequiredSection[]
  setAppliedModifiers: React.Dispatch<
    SetStateAction<AppliedModifiers | undefined>
  >
  selectedVariant?: CategoryProductVariant
  optionsExtraPrices?: OptionsExtraPrices
}

const OptionSelect = ({
  product,
  modifiers,
  isProductInStock,
  productOptions,
  selectedOptions,
  setSelectedOptions,
  invalidModifierGroups,
  setInvalidModifierGroups,
  missingRequiredSections,
  setAppliedModifiers,
  optionsExtraPrices
}: Props) => {
  const selectOption = (name: string, value: string) => {
    // remove previously selected option with the same name.
    const tempSelectedOptions = selectedOptions.filter(
      (option) => option.name !== name
    )
    // add the new selection
    tempSelectedOptions.push({ name, value })
    setSelectedOptions(tempSelectedOptions)

    // TRACKING OPTIONS CUSTOMISING
    if (!!productOptions?.length) {
      const body = {
        category: 'Product',
        action: TrackableEvent.ProductCustomized,
        label: product.name,
        value: 1
      }

      trackUserActionsGA4(body, 'slerpGA4Tracking')

      // legacy tracking
      trackUserActionsFBPixel(TrackableEvent.ProductCustomized, {
        content_name: product.name,
        content_type: 'product',
        contents: [{ id: product.id, quantity: 1 }]
      })
      trackUserActionsGA4(body, 'merchantGA4Tracking')
    }
  }

  const removeFromInvalidModifierGroups = (id: string) => {
    setInvalidModifierGroups(
      invalidModifierGroups.filter((groupId) => groupId !== id)
    )
  }

  const addToInvalidModifierGroups = (id: string) => {
    setInvalidModifierGroups([...invalidModifierGroups, id])
  }

  return (
    <ContentContainer>
      <Container data-testid='product-options'>
        <OptionsContainer>
          <VariantOptions
            disabled={!isProductInStock}
            options={productOptions}
            selectOption={selectOption}
            missingRequiredSections={missingRequiredSections}
            selectedOptions={selectedOptions}
            optionsExtraPrices={optionsExtraPrices}
            variants={product.variants}
          />

          {!!modifiers?.length && (
            <Modifiers
              modifiers={modifiers}
              invalidModifierGroups={invalidModifierGroups}
              removeFromInvalidModifierGroups={(id) =>
                removeFromInvalidModifierGroups(id)
              }
              addToInvalidModifierGroups={(id) =>
                addToInvalidModifierGroups(id)
              }
              missingRequiredSections={missingRequiredSections}
              setAppliedModifiers={setAppliedModifiers}
              disabled={!isProductInStock}
            />
          )}
        </OptionsContainer>
      </Container>
    </ContentContainer>
  )
}

const ContentContainer = styled.div(() => ({
  marginBottom: 'auto'
}))

const Container = styled.div(() => ({
  position: 'sticky',
  bottom: 0
}))

const OptionsContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: `0 0 ${theme.space[4]}px`,
  [theme.mediaQueries.viewport7]: {
    padding: `0`
  }
}))

export default OptionSelect
