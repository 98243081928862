import React, { useEffect, useCallback, forwardRef } from 'react'
import styled from '@emotion/styled'
import { IoIosSearch, IoIosCloseCircleOutline } from 'react-icons/io'
import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'
import { useMediaQueries, useShopPage } from 'shop/hooks'

interface SearchBarProps {
  isShopSearchVisible: boolean
}

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  ({ isShopSearchVisible }, ref) => {
    const { isSearching, searchValue, setSearchValue } = useShopPage()
    const { isMobile } = useMediaQueries()

    const onSearch = (e: React.ChangeEvent<HTMLInputElement> | null) => {
      const input = e?.target?.value || ''
      window.scrollTo({ top: 0 })

      setSearchValue(input)
    }

    const track = useCallback(() => {
      setTimeout(() => {
        if (!!searchValue.length) {
          const trackable = {
            content_name: searchValue,
            content_type: 'product'
          }
          const body = {
            category: 'Product',
            action: TrackableEvent.ProductsSearched,
            label: searchValue
          }

          trackUserActionsGA4(body, 'slerpGA4Tracking')

          // legacy tracking
          trackUserActionsFBPixel('Search', trackable)
          trackUserActionsGA4(body, 'merchantGA4Tracking')
        }
      }, 2000)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    useEffect(() => {
      track()
    }, [track])

    // Remove search values when shop search collapses
    useEffect(() => {
      if (!isShopSearchVisible && searchValue.length > 0) {
        onSearch(null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShopSearchVisible])

    // Hide keyboard on touch user touch e.g. scroll down
    useEffect(() => {
      if (!isMobile) return
      const handleBlur = () => {
        ref?.current.blur()
      }
      window.addEventListener('touchstart', handleBlur)
      return () => {
        window.removeEventListener('touchstart', handleBlur)
      }
    }, [])

    return (
      <InputContainer id='searchBar'>
        <SearchBarInput
          value={searchValue}
          placeholder='Search for an item'
          onChange={onSearch}
          ref={ref}
        />
        <IconContainer>
          <IoIosSearch />
        </IconContainer>
        {isSearching && (
          <IconContainer position='right'>
            <IoIosCloseCircleOutline
              cursor={'pointer'}
              onClick={() => {
                onSearch(null)
                // Keep focus on input even after clearing
                if (ref) ref.current?.focus() // eslint-disable-line
              }}
            />
          </IconContainer>
        )}
      </InputContainer>
    )
  }
)

const SearchBarInput = styled.input(({ theme }: any) => ({
  width: '100%',
  height: '36px',
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.light,
  outline: 0,
  border: `1px solid #D9D9D9`,
  borderRadius: `10px`,
  paddingLeft: '40px',
  paddingRight: '24px',

  backfaceVisibility: 'hidden', // stop iOS flickering
  WebkitBackfaceVisibility: 'hidden', // stop iOS flickering
  MozBackfaceVisibility: 'hidden', // stop iOS flickering
  transform: 'translate3d(0, 0, 0)', // stop iOS flickering
  WebkitTransform: 'translate3d(0, 0, 0)', // stop iOS flickering
  MozTransform: 'translate3d(0, 0, 0)' // stop iOS flickering
}))

const InputContainer = styled.div(() => ({
  position: 'relative',
  height: 'fit-content',
  width: '100%',

  svg: {
    fill: '#59595A'
  },

  '&:focus-within': {
    svg: {
      fill: '#59595A'
    }
  }
}))

const IconContainer = styled.div(
  ({ position = 'left' }: { position?: 'left' | 'right' }) => ({
    margin: 0,
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: position === 'right' ? '12px' : 'auto',
    left: position === 'right' ? 'auto' : '12px',
    top: '50%',
    bottom: 0,
    transform: 'translateY(-50%)',

    '>svg': {
      width: '100%',
      height: '100%'
    }
  })
)

export default SearchBar
