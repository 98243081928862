import { useHistory, useParams } from 'react-router-dom'
import { ProductModal } from 'shop/components'
import { CategoryProduct } from 'shop/components/Shop/Categories'
import { useShop } from 'shop/hooks'
import { useEffect, useState } from 'react'

interface LocationProps {
  state?: { product: CategoryProduct }
}
interface ProductProps {
  location?: LocationProps
}

const Product = ({ location }: ProductProps) => {
  const history = useHistory()
  const { categories, currentPartnerStore } = useShop()
  const { product: productSlug } = useParams<{ product: string }>()
  const [product, setProduct] = useState<CategoryProduct | null>(null)

  useEffect(() => {
    if (categories) {
      if (location?.state?.product) {
        // location.state comes from react-router
        // which we use to pass a full Product record from the previous query
        // to this page for faster loading
        // See ProductCard.tsx for more details
        setProduct(location.state.product)
      } else {
        // If we don't have product in location state (for example when deeplinking), find product in category products
        const productFromCategories = categories
          .flatMap((category) => category.products)
          .find((product) => product.slug === productSlug)

        setProduct(productFromCategories || null)

        // If we can't find the product in the categories, redirect to the store
        if (categories.length && !productFromCategories) {
          if (currentPartnerStore) {
            history.push(`/store/${currentPartnerStore.slug}`)
          } else {
            history.push('/')
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  return <ProductModal {...product} />
}

export default Product
