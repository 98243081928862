import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useAccount, useMediaQueries, useShop } from 'shop/hooks'
import FullscreenModal from 'shop/components/Modal/FullscreenModal'
import { useContext, useEffect, useRef, useState } from 'react'
import AddressSuggestions, { isAddressEligible } from './AddressSuggestions'
import AddressInputBar from './AddressInputBar'
import { CustomerAddress } from 'shop/types'
import SavedAddressItem from './SavedAddressItem'
import GeolocationAddressItem from './GeolocationAddressItem'
import { GeocodedAddress } from './utils'
import { PartnerStoreBrowse } from '../types'
import BrowseStoresList from '../LandingStoreList/BrowseStoresList'
import LineTextDivider from 'shop/components/Dividers/LineTextDivider'
import { LandingContext } from '../LandingContext'
import LoginCTA from './LoginCTA'
import StoreSortControl from '../LandingStoreList/StoreSortControl'
import { getPartnerEnabledFulfillmentTypes } from '../utils'

type Props = {
  searchValue: string
  address: string
  onChange: (newAddress: string) => void
  onSelect: ({
    newAddress,
    geocodedAddress
  }: {
    newAddress?: string
    geocodedAddress?: GeocodedAddress
  }) => void
  onClose: () => void
  customerAddresses?: CustomerAddress[]
  selectCustomerAddress: (customerAddress: CustomerAddress) => void
  inputPlaceholder: string
  showLocationSelect: boolean
  stores?: PartnerStoreBrowse[]
  closeModalOnSelect: boolean
}

const LocationSelectModal = ({
  searchValue,
  address,
  onChange,
  onSelect,
  onClose,
  customerAddresses,
  selectCustomerAddress,
  inputPlaceholder,
  showLocationSelect,
  stores,
  closeModalOnSelect
}: Props) => {
  const { isMobile } = useMediaQueries()
  const [inputValue, setInputValue] = useState(searchValue || address || '')
  const [hasUserChangedInput, setHasUserChangedInput] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const { isUserLoggedIn } = useAccount()
  const isLoggedIn = isUserLoggedIn()
  const { isSortedPartnerStoresLoading, partner } = useShop()
  const { setDefaultLoadCustomerAddress } = useContext(LandingContext)

  const { isDeliveryEnabled, isPickupEnabled } =
    getPartnerEnabledFulfillmentTypes(partner?.stores || [])

  const hasStores = !!stores?.length

  const hasSavedAddresss = !!customerAddresses?.length
  const showUserAddressOptions = !isAddressEligible(inputValue)

  const showSuggestions = !!isAddressEligible(inputValue) && hasUserChangedInput

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (!hasUserChangedInput) setHasUserChangedInput(true)
    setInputValue(inputValue)
    onChange(inputValue)
  }

  const handleClearInput = () => {
    setInputValue('')
    onChange('')
    setDefaultLoadCustomerAddress(false)
    if (inputRef.current) inputRef.current.focus()
  }

  // On Load, autofocus input after animation finishes
  // 300ms slide up animation + 50ms render delay (see ./FormControls.tsx)
  // + 100ms for component to settle
  useEffect(() => {
    window.scrollTo({ top: 0 })
    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus()
    }, 450)
  }, [])

  useEffect(() => {
    // when the modal doesn't close, set the input value to the selected address
    if (!closeModalOnSelect && address) setInputValue(address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  return (
    <FullscreenModal
      onClose={onClose}
      isLandingColumn={!isMobile}
      hideRootOnModalShow={false}
      styles={{
        padding: '0',
        transform: `translateY(${showLocationSelect ? '0' : '100vh'})`
      }}
    >
      <AddressInputBar
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        inputRef={inputRef}
        handleClearInput={handleClearInput}
        onClose={onClose}
        inputPlaceholder={inputPlaceholder}
      />
      {isDeliveryEnabled && isPickupEnabled && hasStores && (
        <StoreSortControl />
      )}
      <ContentContainer>
        <AddressSection>
          {showUserAddressOptions && (
            <>
              <GeolocationAddressItem onSelect={onSelect} />
              {hasSavedAddresss && (
                <SavedAddressItemsContainer>
                  {customerAddresses?.map((customerAddress, index) => (
                    <SavedAddressItem
                      key={`saved-address-${index}`}
                      customerAddress={customerAddress}
                      selectCustomerAddress={selectCustomerAddress}
                    />
                  ))}
                </SavedAddressItemsContainer>
              )}
            </>
          )}
          {/* Do not render persisting suggestions when input is empty or if a user is yet to edit */}
          {showSuggestions && (
            <AddressSuggestions
              inputValue={inputValue}
              onChange={onChange}
              onSelect={onSelect}
              setHasUserChangedInput={setHasUserChangedInput}
            />
          )}
          {!isLoggedIn && <LoginCTA />}
        </AddressSection>
        {hasStores && (
          <StoresContainer>
            {!stores[0].distanceFromPoint &&
              isAddressEligible(inputValue) &&
              !isSortedPartnerStoresLoading && (
                <LineTextDivider text='Matching Store Names' />
              )}
            <BrowseStoresList
              stores={!isSortedPartnerStoresLoading ? stores : []}
            />
          </StoresContainer>
        )}
      </ContentContainer>
    </FullscreenModal>
  )
}

const ContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px 0 0 0',

    [theme.mediaQueries.viewport7]: {
      padding: '16px 20px 0 20px'
    }
  })
)

const SavedAddressItemsContainer = styled.div<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
}))

const StoresContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    padding: '0 16px',

    [theme.mediaQueries.viewport7]: {
      padding: '0 12px'
    }
  })
)

const AddressSection = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  })
)

export default LocationSelectModal
