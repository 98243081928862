import styled from '@emotion/styled'
import {
  useAppContent,
  useConsumerCart,
  useModal,
  useReactRouter,
  useShop
} from 'shop/hooks'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { AiOutlineInfoCircle as InfoIcon } from 'react-icons/ai'
import { FulfillmentTime } from '../shared/FulfillmentTime/FulfillmentTime'
import { Semibold } from '../common'
import { IoChevronForward } from 'react-icons/io5'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HeaderLoader, RowLoader } from './Loaders'
import { StoreDescription } from '../Description'
import { Message, MessagesModal } from '../Messages'
import { getFulfillmentDetails } from './utils'
import { getFulfillmentFlags } from 'shop/utils'
import {
  findDefaultFulfillment,
  isAnyStoreAcceptingOrders
} from 'shop/utils/common'

const DeliverySummary = () => {
  const { openModal, isModalOpen, fulfillmentModal, closeModal } = useModal()
  const { history } = useReactRouter()
  const { pathname } = useLocation()
  const { currentPartnerStore, partner } = useShop()
  const [shouldShowFulfillmentModal, setShouldShowFulfillmentModal] =
    useState(false)
  const [showMessagesModal, setShowMessagesModal] = useState(false)

  const { merchantName } = useAppContent()
  const { cart, cartLoading } = useConsumerCart()

  const cartMinimumOrderValue = cart && (cart.minimumOrderValue || '0')

  const isAnyStoreAvailable = isAnyStoreAcceptingOrders(partner?.stores)

  // If current store is closed and is not taking preorders then the store is not accepting orders
  const isStoreAcceptingOrders =
    currentPartnerStore && isAnyStoreAcceptingOrders([currentPartnerStore])

  const defaultFulfillmentInfo =
    findDefaultFulfillment(currentPartnerStore?.orderFulfillmentTypes) ?? null

  const cartFulfillment = cart?.fulfillment || null

  const { isOrderAtTable } = getFulfillmentFlags(cartFulfillment?.type)

  const {
    isDelivery,
    defaultFulfillment,
    fulfillmentWindow,
    orderType,
    isOat
  } = getFulfillmentDetails(cartFulfillment, defaultFulfillmentInfo)

  const storeName = currentPartnerStore?.name
  const messages = partner?.content.messages
  const storeDescription = currentPartnerStore?.description

  // returns Sameday messages for orderType ASAP
  const messageData = useMemo(() => {
    if (!messages || !Array.isArray(messages)) return null

    const filteredMessages = messages
      .filter((message) => {
        const isRelevantMessageType =
          message.messageType === 'ANNOUNCEMENT' ||
          message.messageType === 'WARNING'
        const isRelevantOrderType =
          orderType === 'ASAP'
            ? message.orderType === 'SAMEDAY'
            : message.orderType === orderType

        return isRelevantMessageType && isRelevantOrderType
      })
      .sort((a, b) => {
        if (a.messageType < b.messageType) {
          return 1
        }
        if (a.messageType > b.messageType) {
          return -1
        }
        return 0
      })

    return filteredMessages.length > 0 ? filteredMessages : null
  }, [messages, orderType])

  // Use minorder value from cart if it exists, otherwise use default fulfillment min order value
  const minimumOrderValue = parseFloat(
    cartMinimumOrderValue || defaultFulfillment?.minimumOrderValue || '0'
  )

  const userCanChangeFulfillment = cartFulfillment
    ? !isOrderAtTable && isAnyStoreAvailable
    : isAnyStoreAvailable

  const openFulfilmentModal = () => {
    if (!isModalOpen('fulfillment')) {
      setShouldShowFulfillmentModal(true)
      openModal('fulfillment')
    }
  }

  const handleFulfillmentModalClose = () => {
    setShouldShowFulfillmentModal(false)
    closeModal('fulfillment')
  }

  useEffect(() => {
    const queryParam = new URLSearchParams(history.location.search)
    // If fulfillment query param from login modal flow exists, remove it
    if (queryParam.get('fulfillment') && !isModalOpen('fulfillment')) {
      setShouldShowFulfillmentModal(true)
      openModal('fulfillment')
      history.push({
        pathname: pathname
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Root>
      {isModalOpen('fulfillment') &&
        shouldShowFulfillmentModal &&
        fulfillmentModal({
          onClose: handleFulfillmentModalClose
        })}
      {!merchantName || !storeName ? (
        <HeaderLoader />
      ) : (
        <>
          <Header>
            {merchantName && <Merchant>{merchantName}</Merchant>}{' '}
            {storeName && <Store>{storeName}</Store>}
          </Header>
          {!!storeDescription?.length && (
            <StoreDescription description={storeDescription} />
          )}
        </>
      )}

      {isOat ? (
        <></>
      ) : cartLoading && !cartFulfillment?.type ? (
        <RowLoader />
      ) : (
        <Row>
          {(fulfillmentWindow ||
            minimumOrderValue > 0 ||
            !isStoreAcceptingOrders) && (
            <IconWrapper>
              <InfoIcon />
            </IconWrapper>
          )}

          {!isStoreAcceptingOrders ? (
            <Line>Currently not Accepting Orders</Line>
          ) : (
            <span>
              {fulfillmentWindow && (
                <Line>
                  {isDelivery ? 'Deliver ' : 'Pickup '}
                  <FulfillmentTime
                    fulfillmentWindow={fulfillmentWindow}
                    orderType={orderType}
                  />
                </Line>
              )}

              {fulfillmentWindow && minimumOrderValue > 0 && <Line> • </Line>}

              {minimumOrderValue > 0 && (
                <Line>
                  <Semibold>£{minimumOrderValue.toFixed(2)}</Semibold> minimum
                  order
                </Line>
              )}
            </span>
          )}

          {userCanChangeFulfillment && (
            <ChangeTextContainer onClick={openFulfilmentModal}>
              <ChangeText>Change</ChangeText>
              <IoChevronForward />
            </ChangeTextContainer>
          )}
        </Row>
      )}

      {messageData && (
        <>
          {messageData.map((message) => (
            <Message
              {...message}
              key={`${message.orderType}-${message.messageType}`}
              handleOpenModal={() => setShowMessagesModal(true)}
              canOpenModal={true}
            />
          ))}
          {showMessagesModal && (
            <MessagesModal
              messages={messageData}
              handleCloseModal={() => setShowMessagesModal(false)}
            />
          )}
        </>
      )}
    </Root>
  )
}

const Root = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  flexFlow: 'column',
  paddingBottom: '4px',

  [theme.mediaQueries.viewport7]: {
    paddingBottom: '8px',
    gap: '16px'
  }
}))

export const Row = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  minHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&:not(:last-child)': {
    marginBottom: '8px'
  }
}))

const IconWrapper = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  marginRight: '8px',
  justifyContent: 'center',
  alignItems: 'center',

  '>svg': {
    width: '20px',
    height: '20px',
    fill: '#666'
  }
}))

const Line = styled.span<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    fontFamily: theme.fonts.body.family,
    fontWeight: 400,
    fontSize: '14px',
    margin: 0,
    color: '#262626',
    lineHeight: '20px',
    height: '20px'
  })
)

export const Header = styled.h1<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    fontFamily: theme.fonts.heading.family,
    fontSize: theme.fontSizes[3],
    marginTop: 0,
    color: theme.colors.primary,
    marginBottom: 0,

    [theme.mediaQueries.viewport7]: {
      fontSize: theme.fontSizes[5]
    }
  })
)

const Merchant = styled.span<StyledHTMLElement, Required<Theme>>(() => ({
  fontWeight: 600,
  textTransform: 'uppercase'
}))

const Store = styled.span<StyledHTMLElement, Required<Theme>>(() => ({
  fontWeight: 400
}))

const ChangeTextContainer = styled.div<StyledHTMLElement>(() => ({
  cursor: 'pointer',
  minWidth: '62px',
  display: 'flex',
  alignItems: 'center'
}))

const ChangeText = styled.span<StyledHTMLElement>(() => ({
  textDecoration: 'underline',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  height: '20px',
  '> span': {
    textDecoration: 'none',
    display: 'inline-block',
    whiteSpace: 'pre'
  }
}))

export default DeliverySummary
