import React, { useEffect, useState, useRef, useMemo } from 'react'
import { SelectedOption, AppliedModifiers } from '../Product/types'
import { CartPayload } from 'shop/components/Cart/types'
import { useShop, useReactRouter, useConsumerCart } from 'shop/hooks'
import { Container } from './index'
import { ControlsBar, OptionSelect } from '.'
import { matchVariantToSelectedOptions, totalV2 } from './utils'
import {
  formatProductForTrackParamsV2,
  merchantGA4EcommTrackItemView,
  slerpGA4EcommTrackItemView
} from 'tracker/GA/ecommerce'
import { trackViewContentCallback } from './tracking'
import ProductDetails from './Content/ProductDetails'
import { HeaderV2 } from './Header/Header'
import { MissingRequiredSection } from 'shop/types'
import { CategoryProduct, CategoryProductVariant } from '../Shop/Categories'
import {
  Canonical,
  ProductMeta,
  MetaDescription,
  PageTitle
} from 'shop/components'

interface ProductModalProps {
  product: CategoryProduct
  closeModal?: () => void
  mobileScrollRef: React.RefObject<HTMLElement>
}

// Only used for single sets of Options
export type OptionsExtraPrices = {
  // e.g. ["Small": "0.00", "Medium": "1.00", "Large": "2.00"]
  [key: string]: string
}

const ProductInfo = ({ product }: ProductModalProps) => {
  const { match } = useReactRouter()
  const { partner } = useShop()
  const { cart: consumerCart, cartLoading } = useConsumerCart()
  const productScrollRef = useRef<HTMLDivElement>(null)

  const { store } = consumerCart || {}
  const { name: partnerName } = partner || {}

  // TODO: Move this route generator to a better and centralised location
  const getParentUrl = () => {
    if (!!store?.slug) return `/store/${store?.slug}`
    if (!!match.params['slug']) return `/store/${match.params['slug']}`
    return `/`
  }
  const parentUrl = getParentUrl()

  const [isSelectedVariantInStock, setIsSelectedVariantInStock] = useState(true)

  const [isContentViewed, setIsContentViewed] = useState(false)

  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([])
  const [optionsExtraPrices, setOptionsExtraPrices] =
    useState<OptionsExtraPrices>()

  const [selectedVariant, setSelectedVariant] =
    useState<CategoryProductVariant>()
  const [variantQuantity, setVariantQuantity] = useState(1)

  const [appliedModifiers, setAppliedModifiers] = useState<AppliedModifiers>()

  const [cartParams, setCartParams] = useState<CartPayload>()

  const [invalidModifierGroups, setInvalidModifierGroups] = useState<string[]>(
    []
  )
  const [hasFailedAddToCart, setHasFailedAddToCart] = useState<boolean>(false)
  const [missingRequiredSections, setMissingRequiredSections] = useState<
    MissingRequiredSection[]
  >([])

  const {
    inStock,
    variants,
    calorieData,
    options: productOptions,
    modifierGroups,
    options
  } = product

  const productAllergens = product?.allergens || []
  const modifierAllergens =
    product?.modifierGroups?.flatMap((modifierGroup) =>
      modifierGroup.modifiers.flatMap((modifier) => modifier.allergens)
    ) || []
  const variantAllergens =
    product?.variants?.flatMap((variant) => variant.allergens) || []
  const uniqueAllergens = Array.from(
    new Set([...productAllergens, ...variantAllergens, ...modifierAllergens])
  )

  /** CALCULATE SELECTED VARIANT */
  // Filter down possible variants based upon options selected AND set variant inStock state
  useEffect(() => {
    // no product - return
    if (!product?.id) return
    // not enough options selected - return
    if (productOptions?.length !== selectedOptions.length) return

    const matchedVariant = matchVariantToSelectedOptions(
      product.variants,
      selectedOptions
    )
    if (matchedVariant) {
      setSelectedVariant(matchedVariant)
      setIsSelectedVariantInStock(matchedVariant.inStock)
    } else {
      // unable to find variant based upon selection.
      // set it to "Out of Stock" just to be sure
      setSelectedVariant(undefined)
      setIsSelectedVariantInStock(false)
    }

    // Only want to change when a user selects an option
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  /** Keep updating the total product price including variants, modifers, quantity */
  const calculatedTotal = useMemo(() => {
    const startPrice = product?.pricing?.lowestVariant.base
    const initialPrice =
      selectedVariant?.pricing?.absolute.base || startPrice || 0

    const total = totalV2(
      Number(initialPrice),
      variantQuantity,
      appliedModifiers
    )
    return total
    // Only change on the following: the product, the selected variant, quanitity, applied modifiers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.id, selectedVariant?.id, variantQuantity, appliedModifiers])

  /** Keep updating the payload for Add to Cart */
  useEffect(() => {
    if (!product?.id) return
    let payload: CartPayload = {
      variantId: '',
      amount: calculatedTotal,
      quantity: variantQuantity,
      variantVat: 0,
      variantPrice: 0,
      appliedModifiers: appliedModifiers ? appliedModifiers : {}
    }
    if (selectedVariant) {
      payload = {
        ...payload,
        variantId: selectedVariant.id,
        variantPrice: Number(selectedVariant.pricing.absolute.base)
      }
      setCartParams(payload)
    } else if (product) {
      payload = {
        ...payload,
        variantId: product.defaultVariantId,
        variantPrice: Number(product.pricing.minimum.base)
      }
      setCartParams(payload)
    }
    // Only change on the following: the product, the selected variant, quantity, applied modifiers, total
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    product?.id,
    selectedVariant?.id,
    variantQuantity,
    appliedModifiers,
    calculatedTotal
  ])

  useEffect(() => {
    // if no variants, use generic product values which represent the default variant
    if (variants?.length === 0) {
      setIsSelectedVariantInStock(inStock)
    }

    // only calculate option extra pricing if there is only 1 set of Options.
    if (options?.length === 1) {
      let optionsPrices: OptionsExtraPrices = {}
      options[0].values.forEach((optionValue) => {
        const matchedVariant = variants.find((variant) =>
          variant.options.find(
            (variantOption) => variantOption.value === optionValue
          )
        )
        optionsPrices = {
          ...optionsPrices,
          [optionValue]: matchedVariant?.pricing.relative.base || '0.00'
        }
      })
      setOptionsExtraPrices(optionsPrices)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  useEffect(() => {
    if (cartLoading || isContentViewed || !product.id) return

    setIsContentViewed(true)

    const formattedProduct = formatProductForTrackParamsV2({
      product,
      storeName: store?.name || '',
      merchantName: partnerName || ''
    })
    slerpGA4EcommTrackItemView(formattedProduct)
    merchantGA4EcommTrackItemView(formattedProduct)
    trackViewContentCallback({
      product_id: product.id,
      name: product.name,
      category: 'Product',
      currency: 'GBP',
      image_url: product.images[0]?.standard,
      price: Number(product.pricing.minimum.base)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, cartLoading])

  const noOptionsOrModifiers: boolean = useMemo(() => {
    return !productOptions?.length && !modifierGroups?.length
  }, [productOptions, modifierGroups])

  const OptionSelectComponent = product ? (
    <OptionSelect
      product={product}
      modifiers={modifierGroups}
      isProductInStock={product.inStock}
      productOptions={productOptions}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      invalidModifierGroups={invalidModifierGroups}
      setInvalidModifierGroups={setInvalidModifierGroups}
      missingRequiredSections={missingRequiredSections}
      setAppliedModifiers={setAppliedModifiers}
      selectedVariant={selectedVariant}
      optionsExtraPrices={optionsExtraPrices}
    />
  ) : (
    <></>
  )

  return (
    <Container ref={productScrollRef}>
      <Canonical url={window.location.origin + window.location.pathname} />
      <MetaDescription
        seoDescription={product?.seoDescription}
        type='product'
      />
      <PageTitle productName={product?.name} />
      <ProductMeta product={product} />
      <HeaderV2 name={product?.name || ''} parentUrl={parentUrl} />
      <ProductDetails
        description={product.description}
        dietaryRequirements={product.dietaryRequirements}
        calorieData={calorieData?.caloriesPerServing}
        allergens={uniqueAllergens}
        forceShowDescription={noOptionsOrModifiers}
      />
      {OptionSelectComponent}
      <ControlsBar
        product={product}
        productVariants={product.variants}
        modifiers={modifierGroups}
        isProductInStock={product.inStock}
        isSelectedVariantInStock={isSelectedVariantInStock}
        parentUrl={parentUrl}
        productScrollRef={productScrollRef}
        productOptions={productOptions || []}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        cartParams={cartParams}
        setCartParams={setCartParams}
        invalidModifierGroups={invalidModifierGroups}
        setInvalidModifierGroups={setInvalidModifierGroups}
        hasFailedAddToCart={hasFailedAddToCart}
        setHasFailedAddToCart={setHasFailedAddToCart}
        missingRequiredSections={missingRequiredSections}
        setMissingRequiredSections={setMissingRequiredSections}
        selectedVariant={selectedVariant}
        variantQuantity={variantQuantity}
        setVariantQuantity={setVariantQuantity}
      />
    </Container>
  )
}

export default ProductInfo
