import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { ProductInfo } from '../ProductInfo'
import ProductImages from './ProductImages'
import { useReactRouter, useModal } from 'shop/hooks'
import { CategoryProduct } from '../Shop/Categories'
import CloseButton from '../ProductInfo/Header/CloseButton'
import useEscModalHandler from 'shop/hooks/useEscModalHandler'
import { slideUp } from '../Shop/commonStyles'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

const ProductModal = (product: CategoryProduct) => {
  const { match, history } = useReactRouter()
  const mobileScrollRef = useRef<HTMLDivElement>(null)
  const { closeModal } = useModal()

  const productName = product.name
  const imageUrls = product.images

  const parentUrl = match.params['slug']
    ? `/store/${match.params['slug']}`
    : `/`

  const handleCloseModal = (e?: React.MouseEvent) => {
    closeModal('fulfillment')
    e?.preventDefault()
    e?.stopPropagation()
    history.push(parentUrl)
  }

  useEffect(() => {
    // onMount lock scroll behind ProductModal
    document.body.style.overflow = 'hidden'
    return () => {
      // onDismount unlock scroll that was behind ProductModal
      document.body.style.overflow = 'visible'
    }
  }, [])

  useEscModalHandler({ onClose: handleCloseModal })

  // Do not render product modal if product is not defined
  if (!product?.id) return null

  return (
    <>
      <ContainerOverlay data-testid='productModal' onClick={handleCloseModal} />
      <Container
        hasImages={!!imageUrls?.length}
        ref={mobileScrollRef}
        id='product-modal-container'
      >
        <CloseButton parentUrl={parentUrl} />
        {!!imageUrls?.length && (
          <ProductImages imageUrls={imageUrls} productName={productName} />
        )}
        <ProductInfo
          product={product}
          closeModal={handleCloseModal}
          mobileScrollRef={mobileScrollRef}
        />
      </Container>
    </>
  )
}

const ContainerOverlay = styled.section(({ theme }: any) => ({
  position: 'relative',
  background: 'white',
  [theme.mediaQueries.viewport6]: {
    background: 'rgba(0, 0, 0, 0.8)',
    height: '100%',
    maxHeight: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
    overflow: 'hidden'
  }
}))

export const Container = styled.section<
  StyledHTMLElement & { hasImages: boolean },
  Required<Theme>
>(({ theme, hasImages }) => ({
  animation: `${slideUp} 50ms ease - out`,
  zIndex: theme.zIndex.productModal,
  maxWidth: theme.designMode ? '1220px' : '100%',
  display: 'block',
  flexDirection: 'column',
  position: 'fixed',
  height: '100%',
  top: '0',
  width: '100%',
  overflow: 'scroll',
  background: 'white',
  [theme.mediaQueries.viewport6]: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100%',
    margin: 'auto',
    width: '62vw'
  },

  [theme.mediaQueries.viewport9]: {
    margin: '0',
    height: hasImages ? '46vw' : '90%',
    display: 'flex',
    border: '0 0 24px #2626268c',
    borderRadius: '8px',
    boxShadow: '12px 24px 24px #262626b0',
    overflow: 'hidden',
    flexDirection: 'row',
    position: 'fixed',
    width: hasImages ? '90vw' : '680px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '1024px',
    maxWidth: '1600px'
  }
}))

export default ProductModal
