import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { IoClose as CloseIcon } from 'react-icons/io5'
import { createPortal } from 'react-dom'
import { useEffect, useState } from 'react'
import { useMediaQueries } from 'shop/hooks'

const TooltipPopup = ({
  tooltipMessage,
  headerTitle,
  onClose,
  targetRef
}: {
  tooltipMessage: string | React.ReactChild
  headerTitle?: string
  onClose?: () => void
  targetRef: React.RefObject<HTMLElement>
}) => {
  const [position, setPosition] = useState(null)
  const { isMobile } = useMediaQueries()

  useEffect(() => {
    if (targetRef?.current) {
      const rect = targetRef.current.getBoundingClientRect()

      setPosition({
        top: rect.bottom + 10, // 10px below the trigger
        left: rect.left // Align left with the trigger
      })
    }
  }, [targetRef])

  const renderToolTip = (
    <>
      <TooltipTip top={position?.top} left={position?.left}>
        <Header>
          <p>{headerTitle}</p>
          <CloseContainer onClick={onClose}>
            <CloseIcon />
          </CloseContainer>
        </Header>
        <Message>{tooltipMessage}</Message>
      </TooltipTip>
      <Underlay></Underlay>
    </>
  )

  if (!position) {
    return <></>
  }

  if (isMobile) {
    return renderToolTip
  }
  return createPortal(renderToolTip, document.body)
}

const Underlay = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'block',
    background: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.tooltipUnderlay,
    overflow: 'hidden',
    [theme.mediaQueries.viewport7]: {
      display: 'none'
    }
  })
)

const Header = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '20px',
  fontWeight: 600,
  borderBottom: '2px solid #F5F5F5',
  '& > p': {
    padding: '16px',
    margin: '0'
  },
  [theme.mediaQueries.viewport7]: {
    display: 'none'
  }
}))

const CloseContainer = styled.div<StyledHTMLElement, Required<Theme>>({
  marginRight: '16px',
  cursor: 'pointer'
})

const Message = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  fontSize: '16px',
  margin: '24px 16px',
  color: '#262626',
  [theme.mediaQueries.viewport7]: {
    margin: '0',
    fontSize: '12px'
  }
}))

const TooltipTip = styled.div<
  StyledHTMLElement & {
    top?: number
    left?: number
  },
  Required<Theme>
>(({ theme, top, left }) => ({
  background: 'white',
  position: 'fixed',
  left: '0',
  bottom: '0',
  right: '0',
  zIndex: theme.zIndex.tooltip,
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  [theme.mediaQueries.viewport7]: {
    position: 'fixed',
    borderRadius: '12px',
    top: top ? `${top}px` : '0',
    right: 'auto',
    bottom: 'auto',
    left: left ? `${left}px` : '0',
    transform: 'translateX(0)',
    padding: '16px',
    lineHeight: '20px',
    zIndex: theme.zIndex.tooltip,
    minWidth: '124px',
    minHeight: 'auto',
    fontSize: '14px',
    boxShadow: `
        0px 4px 10px rgba(0, 0, 0, 0.08),
        -10px 8px 15px rgba(0, 0, 0, 0.08),
        10px 8px 15px rgba(0, 0, 0, 0.08)
      `,
    '&::before': {
      content: '" "',
      background: 'white',
      left: '4px',
      borderLeft: '1px solid #F0F0F0',
      borderTop: '1px solid #F0F0F0',
      height: '16px',
      width: '16px',
      position: 'absolute',
      pointerEvents: 'none',
      transform: 'rotate(45deg) translateY(-50%)',
      top: '-2px'
    }
  }
}))

export default TooltipPopup
