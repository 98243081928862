import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import CloseButton from './CloseButton'
import Backdrop from './Backdrop'
import useEscModalHandler from 'shop/hooks/useEscModalHandler'

interface Props {
  children: React.ReactNode
  handleCloseModal?: () => void
  canClose?: boolean
  isMessage?: boolean
  zIndex?: number
}

const BottomModal = ({
  children,
  handleCloseModal,
  canClose = true,
  isMessage = false,
  zIndex = 30
}: Props) => {
  useEscModalHandler({ onClose: handleCloseModal })

  return (
    <ModalContainer data-testid='bottomModal' zIndex={zIndex}>
      <Dialog isMessage={isMessage}>
        {handleCloseModal && canClose && (
          <CloseButton handleCloseModal={handleCloseModal} />
        )}
        {children}
      </Dialog>
      <Backdrop onClick={handleCloseModal ? handleCloseModal : () => {}} />
    </ModalContainer>
  )
}

const ModalContainer = styled.div(({ theme, zIndex }: any) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: zIndex,
  display: 'flex',
  [theme.mediaQueries.viewport7]: {
    top: theme.components.navbar.height
  }
}))

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(360px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const Dialog = styled.div(({ theme, isMessage }: any) => ({
  overflowY: 'auto',
  backgroundColor: 'white',
  maxWidth: '576px',
  width: '100%',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  zIndex: theme.zIndex.genericModal,
  fontFamily: theme.fonts.normal,
  padding: '0',
  animation: `${slideUp} 300ms ease-out`,
  borderTopRightRadius: isMessage ? '12px' : '20px',
  borderTopLeftRadius: isMessage ? '12px' : '20px',
  '> h1': {
    marginBottom: '24px'
  }
}))

export default BottomModal
