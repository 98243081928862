import {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  Fragment
} from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { IoIosCalendar as CalendarIcon } from 'react-icons/io'
import { BiEditAlt as EditIcon } from 'react-icons/bi'
import { FulfillmentProps } from '../types'
import { LandingContext } from '../LandingContext'
import { InvalidTimeSlot, ValidStore } from 'shop/components'
import {
  FulfillmentDateContainer,
  FulfillmentDatePicker
} from '../FulfillmentDatePicker'
import TimeSlotSelector from '../TimeSlot'
import { isDateAvailable } from './../datePickerUtils'
import { StoreSelector } from '../Stores'
import { formatStoreTimeslots } from '../TimeSlot/utils'
import { format, isToday } from 'date-fns'
import { findNextAvailablePreOrderDate } from '../preOrderUtils'
import SelectInput from 'shop/components/Inputs/SelectInput'
import { useMediaQueries, useShop, useLandingLocation } from 'shop/hooks'
import { RequiredTag } from '../FulfillmentOptions'
import { Divider } from '../../common'
import {
  trackDateChanged,
  trackOrderDayChanged,
  trackStoreChanged,
  trackTimeslotChanged
} from '../utils'
import SelectablePill from '../SelectablePill'
import Spinner from '../../Loader/Spinner'
import { SpinnerContainer } from '../commonStyles'
import { PseudoFormInput } from '../LocationSelect/FormControls'
import { IoLocationOutline } from 'react-icons/io5'

const PickupForm = (_props: FulfillmentProps) => {
  const {
    stores,
    setCurrentStore,
    currentLocation,
    setCurrentLocation,
    fulfillmentDate,
    setFulfillmentDate,
    fulfillmentType,
    setFulfillmentTime,
    merchant,
    validStores,
    currentStore,
    fulfillmentTime,
    setFulfillmentTimeRange,
    setUseCustomerAddress,
    loadingStores,
    loadingDates,
    availableDates,
    setIsExtendedHour,
    sameDayOnly,
    preOrderOnly,
    hasFailedSubmit,
    showFulfillmentOptions,
    inModal
  } = useContext(LandingContext)
  const { customerDetails } = useShop()

  const { handleLocationChange, handleLocationSelect, selectCustomerAddress } =
    useLandingLocation({
      setCurrentLocation,
      setUseCustomerAddress,
      currentLocation
    })

  const { isMobile } = useMediaQueries()

  const { pickup_address_enabled } = merchant

  const datesOrStoresLoading = useMemo(
    () => loadingDates[fulfillmentType] || loadingStores[fulfillmentType],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDates[fulfillmentType], loadingStores[fulfillmentType]]
  )

  useEffect(() => {
    if (preOrderOnly && !sameDayOnly) setHideCalendar(false)
  }, [preOrderOnly, sameDayOnly, currentLocation])

  const [showInvalidTimeslotModal, setShowInvalidTimeslotModal] =
    useState(false)
  const [selectedOrderDay, setSelectedOrderDay] = useState('today')
  const [hideCalendar, setHideCalendar] = useState(true)

  useEffect(() => {
    if (isToday(fulfillmentDate) && selectedOrderDay !== 'today') {
      setSelectedOrderDay('today')
    }

    if (!isToday(fulfillmentDate) && selectedOrderDay !== 'future date') {
      setSelectedOrderDay('future date')
    }
  }, [fulfillmentDate, selectedOrderDay])

  const handleDateChange = (date: Date) => {
    // do not allow same day selection on the calendar for pre-orders
    if ((preOrderOnly && isToday(date)) || loadingStores[fulfillmentType]) {
      return
    }
    if (date) {
      setFulfillmentDate(date)
      if (isToday(date)) {
        setSelectedOrderDay('today')
      }
      setHideCalendar(true)
      trackDateChanged('landing', date)
    }
  }

  const showStoresAndTimeslots =
    !datesOrStoresLoading && fulfillmentDate && validStores.length > 0

  const timeSlots = formatStoreTimeslots(
    currentStore?.fulfillmentTimeslots || [],
    fulfillmentDate
  )

  const selectFirstTimeslot = useCallback(() => {
    const timeslot = timeSlots[0]
    setFulfillmentTime(timeslot.value)
    setFulfillmentTimeRange(timeslot.range)
  }, [setFulfillmentTime, setFulfillmentTimeRange, timeSlots])

  useEffect(() => {
    if (timeSlots.length === 1) selectFirstTimeslot()
  }, [timeSlots, selectFirstTimeslot])

  const sameDayAndPreOrderAvailable = !preOrderOnly && !sameDayOnly

  const handleSetCurrentStore = (store: ValidStore) => {
    setCurrentStore(store)
    trackStoreChanged(store, 'landing')
  }

  const startIndex = showFulfillmentOptions ? 2 : 1

  const AddressInputSection = ({ index }: { index: number }) => (
    <Fragment key='address-section'>
      <h5>
        {index + startIndex}.<span>Enter your postcode</span>
        <RequiredTag>*optional</RequiredTag>
      </h5>
      <PseudoFormInput
        onChange={handleLocationChange}
        onSelect={handleLocationSelect}
        searchValue={currentLocation?.address || ''}
        address={currentLocation?.address || ''}
        id={'address-input'}
        customerAddresses={customerDetails?.addresses}
        selectCustomerAddress={selectCustomerAddress}
        inputPlaceholder='Where are you coming from?'
        leftIcon={<IoLocationOutline />}
      />
      <Divider />
    </Fragment>
  )

  const PickupDateSection = ({ index }: { index: number }) => (
    <Fragment key='pickup-section'>
      <h5>
        {index + startIndex}.<span>Confirm pickup date </span>
        <RequiredTag
          id={`${
            selectedOrderDay === 'today' || (!!fulfillmentDate && hideCalendar)
              ? ''
              : 'invalid-'
          }required-tag-date`}
          {...{
            isValid:
              selectedOrderDay === 'today' ||
              (!!fulfillmentDate && hideCalendar),
            hasFailed: hasFailedSubmit
          }}
        >
          *required
        </RequiredTag>
      </h5>
      {sameDayAndPreOrderAvailable && !loadingDates[fulfillmentType] && (
        <SelectablePill
          options={[
            {
              title: 'today',
              type: 'today',
              selected: selectedOrderDay === 'today',
              onClick: () => {
                if (loadingStores[fulfillmentType]) return
                handleDateChange(new Date())
                setSelectedOrderDay('today')
                trackOrderDayChanged('today', 'landing')
              }
            },
            {
              title: 'future date',
              type: 'future-date',
              selected: selectedOrderDay === 'future date',
              onClick: () => {
                if (loadingStores[fulfillmentType]) return
                const nextDate = findNextAvailablePreOrderDate(
                  stores,
                  fulfillmentType,
                  availableDates
                )
                handleDateChange(nextDate)
                setSelectedOrderDay('future date')
                setHideCalendar(false)
                trackOrderDayChanged('future date', 'landing')
              }
            }
          ]}
        />
      )}
      {sameDayOnly && selectedOrderDay === 'today' && (
        <>
          <SelectInput
            testId='change-calendar-date'
            disabled={true}
            onClick={() => {}}
            ToSelectIcon={CalendarIcon}
            HasSelectedIcon={EditIcon}
            hasSelected={!!fulfillmentDate}
            textFallback={`${format(fulfillmentDate, 'EEEE')} ${format(
              fulfillmentDate,
              'dd/MM/yyyy'
            )}`}
          />
        </>
      )}
      {loadingDates[fulfillmentType] && (
        <SpinnerContainer>
          <Spinner size='40px' />
        </SpinnerContainer>
      )}
      {selectedOrderDay === 'future date' && !loadingDates[fulfillmentType] && (
        <>
          {hideCalendar && (
            <>
              <SelectInput
                testId='change-calendar-date'
                onClick={() => setHideCalendar(false)}
                ToSelectIcon={CalendarIcon}
                HasSelectedIcon={EditIcon}
                hasSelected={!!fulfillmentDate}
                textFallback={`${format(fulfillmentDate, 'EEEE')} ${format(
                  fulfillmentDate,
                  'dd/MM/yyyy'
                )}`}
              />
            </>
          )}
          {!hideCalendar && (
            <FulfillmentDateContainer data-testid='datepickerContainer'>
              <FulfillmentDatePicker
                selected={fulfillmentDate}
                onChange={handleDateChange}
                onSelect={handleDateChange}
                minDate={new Date()}
                filterDate={(date: Date) =>
                  isDateAvailable(availableDates, date, 'pickup')
                }
                placeholderText='dd/mm/yyyy'
                autoFocus={fulfillmentDate === null}
                dateFormat='dd/MM/yyyy'
                disabled={loadingStores[fulfillmentType]}
              />
            </FulfillmentDateContainer>
          )}
        </>
      )}
      <Divider />
    </Fragment>
  )

  const StoreSelectorSection = ({ index }: { index: number }) => (
    <Fragment key='store-section'>
      {showStoresAndTimeslots && (
        <>
          <h5>
            {index + startIndex}.<span>Pick a store</span>
            <RequiredTag
              id={`${!!currentStore ? '' : 'invalid-'}required-tag-store`}
              {...{ isValid: !!currentStore, hasFailed: hasFailedSubmit }}
            >
              *required
            </RequiredTag>
          </h5>
          <StoreSelector
            validStores={validStores}
            setCurrentStore={handleSetCurrentStore}
            currentStore={currentStore}
            inModal={inModal}
          />
          <Divider />
        </>
      )}
      {loadingStores[fulfillmentType] && (
        <SpinnerContainer>
          <Spinner size='40px' />
        </SpinnerContainer>
      )}
    </Fragment>
  )

  const ShowTimeslotsSection = ({ index }: { index: number }) => (
    <Fragment key='timeslots-section'>
      <h5>
        {index + startIndex}.<span>Choose pickup timeslot</span>
        <RequiredTag
          id={`${!!fulfillmentTime ? '' : 'invalid-'}required-tag-time`}
          {...{ isValid: !!fulfillmentTime, hasFailed: hasFailedSubmit }}
        >
          *required
        </RequiredTag>
      </h5>
      <TimeSlotContainer>
        <TimeSlotSelector
          timeSlots={timeSlots}
          value={fulfillmentTime}
          handleUpdate={(time, range, extended) => {
            setFulfillmentTime(time)
            setFulfillmentTimeRange(range)
            setIsExtendedHour(extended)
            if (range) {
              trackTimeslotChanged(range, 'landing')
            }
          }}
          inModal={inModal}
        />
      </TimeSlotContainer>
      <InvalidTimeSlot
        showModal={showInvalidTimeslotModal}
        onClose={() => setShowInvalidTimeslotModal(false)}
      />
    </Fragment>
  )

  const NoStoresErrorMessage = () => (
    <Fragment key='message-section'>
      <Message>
        Sorry, pickup is unavailable for this day as it may be disabled by the
        merchant. Try selecting another date, or selecting delivery.
      </Message>
    </Fragment>
  )

  const orderedRenderList = [
    pickup_address_enabled && !isMobile && AddressInputSection,
    PickupDateSection,
    pickup_address_enabled && isMobile && AddressInputSection,
    (showStoresAndTimeslots || loadingStores[fulfillmentType]) &&
      StoreSelectorSection,
    showStoresAndTimeslots && ShowTimeslotsSection,
    !datesOrStoresLoading && validStores.length === 0 && NoStoresErrorMessage
  ].filter((item) => !!item) as Array<React.FC<{ index: number }>>

  return orderedRenderList.map((Element, index) => Element({ index }))
}

const Message = styled.p<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  margin: '32px 0 8px',
  fontSize: theme.fontSizes[2],
  fontWeight: 'normal',
  textTransform: 'none',
  color: theme.colors['textMute']
}))

const TimeSlotContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  () => ({
    marginBottom: '36px'
  })
)

export default PickupForm
