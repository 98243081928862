import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'

export const BaseContainer = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const baseDescContainerStyles = styled.div<StyledHTMLElement>(() => ({
  position: 'relative',
  transition: 'max-height 0.5s ease-in-out',
  fontSize: '14px',
  marginBottom: '4px',
  overflow: 'hidden',
  color: '#262626',
  '& p:first-of-type': {
    marginTop: 0
  }
}))
