import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useModal } from 'shop/hooks'

const LoginCTA = () => {
  const { openModal } = useModal()

  return (
    <ItemContainer onClick={() => openModal('login')}>
      <CTAContent>
        To access saved addresses and exclusive offers, please Log in or Sign
        up.
      </CTAContent>
      <AccountLabel>Account</AccountLabel>
    </ItemContainer>
  )
}

const ItemContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '48px',
    borderRadius: '0',
    padding: '4.5px 16px',
    gap: '8px',
    fontFamily: theme.fonts['body'].family,
    cursor: 'pointer',

    // disable blue hightlight of user clicks
    WebkitTapHighlightColor: 'transparent',

    '& svg': {
      height: '20px',
      width: '20px'
    },

    '&:hover': {
      background: '#FAFAFA'
    },

    [theme.mediaQueries.viewport7]: {
      padding: '4.5px 12px',
      borderRadius: '12px'
    }
  })
)

const CTAContent = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: theme.fonts['body'].family,
    lineHeight: '16px',
    color: '#2A2A2A',
    margin: 0
  })
)

const AccountLabel = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: theme.fonts['body'].family,
    color: '#2A2A2A',
    padding: '4px 8px',
    textDecoration: 'underline'
  })
)

export default LoginCTA
