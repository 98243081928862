import { useRef } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { PartnerStoreBrowse } from '../types'
import LandingStoreListItem from './LandingStoreListItem'
import { getEnabledFulfillmentTypes } from '../utils'
import { parseTimezoneISO } from 'shop/utils'
import { useShop } from 'shop/hooks'
import BrowseStoresListLoader from './BrowseStoresListLoader'

type Props = {
  stores?: PartnerStoreBrowse[]
}

const BrowseStoresList = ({ stores }: Props) => {
  const { isSortedPartnerStoresLoading } = useShop()

  // Immutable Date that is saved on component load
  // This is to calculate minutes before next earliest slot,
  // due to Search mounting and unmounting children component and other re-renders.
  // Ensure time is set to UK timezone. 'Europe/London'
  const onLoadDateTime = useRef<Date>(
    parseTimezoneISO(new Date().toISOString())
  )

  return (
    <ListContainer>
      {isSortedPartnerStoresLoading && <BrowseStoresListLoader />}
      {!isSortedPartnerStoresLoading && (
        <>
          {(stores || []).length > 1 && (
            <SortLabel>
              Sorted by:&nbsp;
              <span>Best Match</span>
            </SortLabel>
          )}
          {stores?.map(({ store, distanceFromPoint, rankReasons }) => {
            const { isDeliveryEnabled, isPickupEnabled } =
              getEnabledFulfillmentTypes(store.orderFulfillmentTypes)
            // filter out stores that have no Fulfillment Options
            if (!isDeliveryEnabled && !isPickupEnabled) return null
            return (
              <LandingStoreListItem
                key={store.slug}
                store={store}
                isDeliveryEnabled={isDeliveryEnabled}
                isPickupEnabled={isPickupEnabled}
                onLoadDateTime={onLoadDateTime.current}
                distance={distanceFromPoint}
                rankReasons={rankReasons}
              />
            )
          })}
        </>
      )}
    </ListContainer>
  )
}

const ListContainer = styled.ul<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: 0,
    [theme.mediaQueries.viewport7]: {
      gap: '21px'
    }
  })
)

const SortLabel = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '14px',
    color: '#59595A',

    '& span': {
      fontWeight: 500,
      color: '#2A2A2A  '
    }
  })
)

export default BrowseStoresList
