import React, {
  useState,
  useEffect,
  useCallback,
  RefObject,
  SetStateAction
} from 'react'
import styled from '@emotion/styled'
import ModifierQuantity from './ModifierQuantity'
import { ProductModifier } from '../Shop/Categories/types'
import { includes, keyBy } from 'lodash'
import { formatSelectedModifierV2 } from './utils'
import { SelectedModifiers } from 'shop/components/Landing/types'
import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'
import { CheckboxInput, RadioInput } from '../Inputs'

interface Props {
  modifier: ProductModifier
  inputType: 'radio' | 'checkbox'
  selectedItems: SelectedModifiers
  setSelectedItems: React.Dispatch<SetStateAction<SelectedModifiers>>
  max: number
  enableIncrement: boolean
  groupId: string
  disabled?: boolean
  min?: number
  defaultChecked?: boolean
}

const Modifier = ({
  setSelectedItems,
  selectedItems,
  modifier,
  inputType,
  max,
  enableIncrement,
  groupId,
  disabled = false,
  min = 0,
  defaultChecked
}: Props) => {
  const { id, name, price, image, inStock } = modifier
  const [quantity, setQuantity] = useState<number>(0)
  // state to handle CSS onClick style changes.
  const [inputPressed, setInputPressed] = useState<string>('')

  const onChange = (
    checked: boolean,
    radioInput?: RefObject<HTMLInputElement>
  ) => {
    const formattedItem = formatSelectedModifierV2(modifier, groupId, quantity)

    const value = (() => {
      if (inputType === 'checkbox' && max !== 1)
        return { ...selectedItems, [id]: formattedItem }
      if (checked) return { [id]: formattedItem }
      return {}
    })()

    setSelectedItems(value)
    setQuantity(checked ? 1 : 0)
    if (radioInput && radioInput.current) radioInput.current.checked = checked
  }

  const track = useCallback(() => {
    if (quantity !== 0) {
      const body = {
        category: name,
        action: TrackableEvent.ProductCustomized,
        label: name,
        value: quantity
      }

      trackUserActionsGA4(body, 'slerpGA4Tracking')

      // legacy tracking
      trackUserActionsFBPixel(TrackableEvent.ProductCustomized, {
        content_name: name,
        content_type: 'modifier',
        contents: [{ id: id, quantity: quantity }]
      })
      trackUserActionsGA4(body, 'merchantGA4Tracking')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity])

  useEffect(() => {
    track()
  }, [track])

  useEffect(() => {
    if (quantity === 0) {
      return setSelectedItems((prevState) => {
        const filteredIds = Object.keys(prevState).filter((key) => key !== id)
        const values = filteredIds.map((modifierId) => prevState[modifierId])
        return { ...keyBy(values, (item) => item.modifier_id) }
      })
    }

    setSelectedItems((prevState) => {
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          amount: Number(price.base) * quantity,
          quantity
        }
      }
    })
  }, [quantity, id, price.base, setSelectedItems])

  const isSelected = includes(Object.keys(selectedItems), id)

  const inputProps = {
    name: `${name}-${groupId}`,
    id: `${id}-${groupId}`,
    checked: isSelected,
    value: id
  }

  const modifierInputDisabled =
    disabled ||
    !inStock ||
    (inputType === 'checkbox' && !enableIncrement && max !== 1)

  const Input = inputType === 'radio' ? RadioInput : CheckboxInput

  useEffect(() => {
    // if modifier within it's group is required and there is only 1, preselect it.
    if (min > 0 && defaultChecked) {
      onChange(true)
      setQuantity(min)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, defaultChecked])

  return (
    <Container
      {...{ selected: isSelected, disabled: !inStock || disabled }}
      data-testid='modifier-item'
    >
      <Controls>
        <InputWrapper>
          <Input
            data-testid='modifier-item-input'
            {...inputProps}
            type={inputType}
            disabled={modifierInputDisabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // to handle CSS onClick style changes.
              setInputPressed(inputProps.id)
              onChange(e.target.checked)
              setTimeout(() => setInputPressed(''), 100)
            }}
            inputPressed={inputPressed === inputProps.id}
          />
        </InputWrapper>
      </Controls>
      {image?.thumb && (
        <ImageContainer>
          <Image src={image.thumb} loading='lazy' />
        </ImageContainer>
      )}
      <Info
        htmlFor={`${id}-${groupId}`}
        {...{ selected: isSelected, disabled: !inStock || disabled }}
      >
        <Name>{name}</Name>
      </Info>

      {inputType === 'checkbox' && max !== 1 && isSelected ? (
        <ModifierQuantity
          quantity={quantity}
          setQuantity={setQuantity}
          enableIncrement={enableIncrement}
        />
      ) : (
        <PriceLabel price={Number(price.base)} inStock={inStock} />
      )}
    </Container>
  )
}

export const PriceLabel = ({
  price,
  inStock
}: {
  price: number
  inStock: boolean
}) => {
  if (!inStock) return <SoldOutLabel>Unavailable</SoldOutLabel>
  if (price === 0) return <></>
  return (
    <Price>
      +
      {price.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })}
    </Price>
  )
}

const Container = styled.div(({ theme, selected, disabled }: any) => ({
  WebkitTapHighlightColor: 'transparent',
  borderBottom: `1px solid ${theme.colors.lineColor}`,
  color: selected ? theme.colors.primary : 'initial',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '4px',
  transition: 'box-shadow 100ms ease-in',
  opacity: !disabled ? 1 : 0.5,
  cursor: !disabled ? 'auto' : 'not-allowed',
  minHeight: '64px',
  paddingBottom: '8px',
  '> div, > label': {
    minHeight: '56px',
    height: 'auto'
  }
}))

const Name = styled.p(({ theme }: any) => ({
  fontSize: theme.fontSizes[1],
  margin: '0'
}))

const Price = styled.p(({ theme }: any) => ({
  flexShrink: 0,
  color: theme.colors.textMute,
  fontSize: theme.fontSizes[1],
  margin: '0'
}))

const SoldOutLabel = styled.p(({ theme }: any) => ({
  flexShrink: 0,
  color: theme.colors.textMute,
  fontSize: theme.fontSizes[1],
  margin: '0'
}))

const ImageContainer = styled.div(() => ({
  height: '54px',
  minWidth: '54px',
  marginRight: '12px'
}))

const Image = styled.img(() => ({
  borderRadius: '4px',
  height: '54px',
  width: '54px',
  objectFit: 'cover'
}))

const Info = styled.label(({ selected, disabled }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: selected ? 'auto' : !disabled ? 'pointer' : 'not-allowed',
  flexGrow: 1,
  paddingRight: '16px'
}))

const Controls = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 18px 4px 0'
}))

const InputWrapper = styled.div(() => ({
  alignSelf: 'flex-start',
  display: 'flex',
  minHeight: '48px',
  alignItems: 'center'
}))

export default Modifier
