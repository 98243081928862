import styled from '@emotion/styled'
import { capitalize } from 'lodash'
import React, { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { Button } from 'shop/components/Controls'
import { RadioInput } from 'shop/components/Inputs'
import BottomModal from 'shop/components/Modal/BottomModal'
import CenterModal from 'shop/components/Modal/CenterModal'
import { useMediaQueries } from 'shop/hooks'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { DELIVERY_FULFILLMENT, PICKUP_FULFILLMENT } from 'shop/types'
import { LandingContext } from '../LandingContext'
import { StoreRankingCriteria } from '../types'

type Props = {
  handleClose: () => void
}

type FulfillmentTypeSorts =
  | typeof DELIVERY_FULFILLMENT
  | typeof PICKUP_FULFILLMENT
  | 'ALL'

const getDefaultSelectedFulfillmentType = (
  sortCriteria: StoreRankingCriteria
): FulfillmentTypeSorts => {
  const { fulfillmentTypes } = sortCriteria
  if (fulfillmentTypes.length === 1) {
    return fulfillmentTypes[0] as FulfillmentTypeSorts
  }
  return 'ALL'
}

const StoreSortModal = ({ handleClose }: Props) => {
  const { sortCriteria, setSortCriteria } = useContext(LandingContext)
  const [selectedFulfillmentType, setSelectedFulfillmentType] =
    useState<FulfillmentTypeSorts>(
      getDefaultSelectedFulfillmentType(sortCriteria)
    )
  const { isMobile } = useMediaQueries()

  const handleApplySort = () => {
    switch (selectedFulfillmentType) {
      case DELIVERY_FULFILLMENT:
        setSortCriteria({ fulfillmentTypes: [DELIVERY_FULFILLMENT] })
        break
      case PICKUP_FULFILLMENT:
        setSortCriteria({ fulfillmentTypes: [PICKUP_FULFILLMENT] })
        break
      default:
        setSortCriteria({
          fulfillmentTypes: [DELIVERY_FULFILLMENT, PICKUP_FULFILLMENT]
        })
    }

    setTimeout(() => handleClose(), 300)
  }

  const modalContent = (
    <Container>
      <Header>Sort by</Header>
      <OptionsContainer>
        <SortOption
          option={DELIVERY_FULFILLMENT}
          setSelectedFulfillmentType={setSelectedFulfillmentType}
          isChecked={selectedFulfillmentType === DELIVERY_FULFILLMENT}
        />
        <Divider />
        <SortOption
          option={PICKUP_FULFILLMENT}
          setSelectedFulfillmentType={setSelectedFulfillmentType}
          isChecked={selectedFulfillmentType === PICKUP_FULFILLMENT}
        />
        <Divider />
        <SortOption
          option='ALL'
          setSelectedFulfillmentType={setSelectedFulfillmentType}
          isChecked={selectedFulfillmentType === 'ALL'}
        />
      </OptionsContainer>
      <ButtonsContainer>
        <Button variant='primary' onClick={handleApplySort}>
          Apply
        </Button>
      </ButtonsContainer>
    </Container>
  )

  if (isMobile)
    return createPortal(
      <BottomModal handleCloseModal={handleClose} zIndex={301}>
        {modalContent}
      </BottomModal>,
      document.body
    )

  return createPortal(
    <CenterModal handleCloseModal={handleClose} zIndex={301}>
      {modalContent}
    </CenterModal>,
    document.body
  )
}

type SortOptionsProps = {
  option: FulfillmentTypeSorts
  setSelectedFulfillmentType: React.Dispatch<
    React.SetStateAction<FulfillmentTypeSorts>
  >
  isChecked: boolean
}

const SortOption = ({
  option,
  setSelectedFulfillmentType,
  isChecked = false
}: SortOptionsProps) => {
  const handleSelect = () => {
    setSelectedFulfillmentType(option)
  }
  return (
    <SortOptionItem>
      <RadioInput
        id={`${option}-sort-radio`}
        key={option}
        type='radio'
        name={option}
        value={option}
        onChange={handleSelect}
        disabled={false}
        checked={isChecked}
      />
      <SortOptionLabel htmlFor={`${option}-sort-radio`}>
        {capitalize(option.toLowerCase())}
      </SortOptionLabel>
    </SortOptionItem>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column'
  })
)
const Header = styled.h1<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 600,
  fontFamily: theme.fonts['heading'].family,
  padding: '18px 16px',
  borderBottom: '2px solid #EFEFF0',

  [theme.mediaQueries.viewport7]: {
    padding: '20px 24px',
    fontSize: '24px',
    paddingBottom: '20px'
  }
}))

const OptionsContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '16px 16px',

    [theme.mediaQueries.viewport7]: {
      padding: '24px 24px'
    }
  })
)

const SortOptionItem = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    gap: '22px',
    alignItems: 'center',
    padding: '0 10px'
  })
)
const SortOptionLabel = styled.label<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    height: '100%',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: theme.fonts['body'].family,
    padding: '12px 0',

    // disable blue hightlight of user clicks
    WebkitTapHighlightColor: 'transparent',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',

    [theme.mediaQueries.viewport7]: {
      fontSize: '16px'
    }
  })
)

const ButtonsContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    gap: '12px',
    flexDirection: 'row-reverse',
    padding: '16px 16px',

    [theme.mediaQueries.viewport7]: {
      padding: '8px 24px 24px',
      flexDirection: 'column'
    }
  })
)

const Divider = styled.hr<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  height: '1px',
  width: '100%',
  backgroundColor: '#F0F0F0',
  border: 'none',
  margin: 0
}))

export default StoreSortModal
