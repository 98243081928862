import ReactGA4 from 'react-ga4'
import {
  EcommCheckoutParams,
  EcommCheckoutParamsConsumerCart,
  EcommItemParams,
  EcommLoginSignupParams,
  EcommPurchaseParams
} from './types'

import AllTrackers from 'tracker/AllTrackers'
import { ConsumerCart } from 'shop/types/cart'
import { CategoryProduct } from 'shop/components/Shop/Categories'

export const formatProductForTrackParamsV2 = ({
  product,
  storeName,
  merchantName,
  quantity,
  totalPrice
}: {
  product: CategoryProduct
  storeName: String
  merchantName: String
  quantity?: Number
  totalPrice?: Number
}): EcommItemParams => {
  return {
    currency: 'GBP', // FIXME: Update this once multi-currency is supported
    value: totalPrice || Number(product.pricing.minimum.base) || 0,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        affiliation: storeName,
        item_brand: merchantName,
        item_variant: product.name,
        price: Number(product.pricing.minimum.base),
        quantity: quantity || 1
      }
    ]
  }
}

export const createGA4EcommPurchaseParams = (
  cart: ConsumerCart,
  {
    transactionId,
    merchantName
  }: {
    transactionId: string
    merchantName: string
  }
): EcommPurchaseParams => {
  const formattedItems = cart.orderItems.map((item) => ({
    item_id: item.product.id,
    item_name: item.product.name,
    affiliation: cart.store.name,
    item_brand: merchantName,
    item_variant: item.name,
    price:
      Number(item.variantPrice.discounted) || Number(item.variantPrice.base),
    quantity: item.quantity
  }))
  return {
    currency: 'GBP',
    transaction_id: transactionId,
    value: Number(
      cart.summary.subtotal.discounted || cart.summary.subtotal.base
    ),
    coupon: cart.summary.discount?.code || '',
    shipping: Number(
      cart.summary.delivery?.discounted || cart.summary.delivery?.base || 0
    ),
    items: formattedItems
  }
}

/** Uses consumer API cart to generate ecommerce event data, equivalent of the createEcommEventDataFromCart function */
export const createEcommEventDataFromConsumerCart = (
  cart: ConsumerCart,
  merchantName: string,
  storeName: string
) => {
  const subtotal = Number(
    cart.summary.subtotal.discounted || cart.summary.subtotal.base
  )
  const total = Number(cart.summary.total.discounted || cart.summary.total.base)

  const withCustomParams = {
    coupon: cart.summary.discount?.code || undefined,
    discount: cart.summary.discount?.amount || undefined,
    cart_subtotal: subtotal
  }

  const ecommerceEventData = {
    currency: 'GBP',
    value: total,
    ...withCustomParams,
    items: cart.orderItems.map((item) => {
      return {
        item_id: item.product.id,
        item_name: item.product.name,
        affiliation: storeName,
        item_brand: merchantName,
        item_variant: item.name,
        price:
          Number(item.variantPrice.discounted) ||
          Number(item.variantPrice.base),
        quantity: item.quantity
      }
    })
  }
  return ecommerceEventData
}

/** SLERP Track GA4 ecommerce "view_item"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#view_item
 */
export const slerpGA4EcommTrackItemView = (params: EcommItemParams) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'view_item', {
      currency: params.currency,
      value: params.value,
      items: params.items,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** SLERP Track GA4 ecommerce "add_to_cart"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#add_to_cart
 */
export const slerpGA4EcommTrackAddToCart = (params: EcommItemParams) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'add_to_cart', {
      currency: params.currency,
      value: params.value,
      items: params.items,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** SLERP Track GA4 ecommerce "remove_from_cart"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#remove_from_cart
 */
export const slerpGA4EcommTrackRemoveFromCart = (params: EcommItemParams) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'remove_from_cart', {
      currency: params.currency,
      value: params.value,
      items: params.items,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** SLERP Track GA4 ecommerce "purchase"
 * ref: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
 */
export const slerpGA4EcommTrackPurchase = (
  userTransaction: EcommPurchaseParams
) => {
  const isLoggedIn = localStorage.getItem('customerId') !== null
  let authVersion = {}

  if (isLoggedIn) {
    // authVersion is only set in local storage when user logs in using new consumer API
    if (localStorage.getItem('authVersion')) {
      authVersion = { authVersion: 'consumer' }
    } else {
      // if user is logged in & doesn't have localStorage value then logged in via hasura
      authVersion = { authVersion: 'hasura' }
    }
  }

  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'purchase', {
      currency: userTransaction.currency,
      // prepend SLERP to avoid "duplicates" being ignored
      transaction_id: `SLERP_${userTransaction.transaction_id}`,
      value: userTransaction.value,
      coupon: userTransaction.coupon,
      shipping: userTransaction.shipping,
      tax: userTransaction.tax,
      items: userTransaction.items,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId,
      ...authVersion
    })
  }
}

/** SLERP Track GA4 ecommerce "view_cart"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#view_cart
 */
export const slerpGA4EcommTrackViewCart = (params: EcommCheckoutParams) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'view_cart', {
      currency: params.currency,
      value: params.value,
      cart_subtotal: params.cart_subtotal,
      items: params.items,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** SLERP Track GA4 ecommerce "begin_checkout"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#begin_checkout
 */
export const slerpGA4EcommTrackBeginCheckout = (
  params: EcommCheckoutParams
) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'begin_checkout', {
      currency: params.currency,
      value: params.value,
      coupon: params.coupon,
      discount: params.discount,
      cart_subtotal: params.cart_subtotal,
      items: params.items,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** SLERP Track GA4 ecommerce "add_shipping_info" for consumer cart
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#add_shipping_info
 */
export const slerpGA4EcommTrackAddShippingInfoConsumerCart = (
  params: EcommCheckoutParamsConsumerCart
) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'add_shipping_info', {
      currency: params.currency,
      value: params.value,
      coupon: params.coupon,
      discount: params.discount,
      shipping_tier: params.shipping_tier,
      shipping: params.shipping,
      fulfillment_type: params.fulfillment_type,
      cart_subtotal: params.cart_subtotal,
      items: params.items,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** SLERP Track GA4 ecommerce "add_payment_info"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#add_payment_info
 */
export const slerpGA4EcommTrackAddPaymentInfo = (
  params: EcommCheckoutParams
) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'add_payment_info', {
      currency: params.currency,
      value: params.value,
      coupon: params.coupon,
      discount: params.discount,
      payment_type: params.payment_type,
      cart_subtotal: params.cart_subtotal,
      items: params.items,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** SLERP Track GA4 ecommerce "login"
 * refs: https://developers.google.com/tag-platform/gtagjs/reference/events#login
 */
export const slerpGA4EcommTrackLogin = (params: EcommLoginSignupParams) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'login', {
      method: params.method,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** SLERP Track GA4 ecommerce "sign_up"
 * https://developers.google.com/tag-platform/gtagjs/reference/events#sign_up
 */
export const slerpGA4EcommTrackSignUp = (params: EcommLoginSignupParams) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'sign_up', {
      method: params.method,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "view_item"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#view_item
 */
export const merchantGA4EcommTrackItemView = (params: EcommItemParams) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'view_item', {
      currency: params.currency,
      value: params.value,
      items: params.items,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "add_to_cart"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#add_to_cart
 */
export const merchantGA4EcommTrackAddToCart = (params: EcommItemParams) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'add_to_cart', {
      currency: params.currency,
      value: params.value,
      items: params.items,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "remove_from_cart"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#remove_from_cart
 */
export const merchantGA4EcommTrackRemoveFromCart = (
  params: EcommItemParams
) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'remove_from_cart', {
      currency: params.currency,
      value: params.value,
      items: params.items,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "purchase"
 * ref: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
 */

export const merchantGA4EcommTrackPurchase = (
  userTransaction: EcommPurchaseParams
) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'purchase', {
      currency: userTransaction.currency,
      transaction_id: userTransaction.transaction_id,
      value: userTransaction.value,
      coupon: userTransaction.coupon,
      shipping: userTransaction.shipping,
      tax: userTransaction.tax,
      items: userTransaction.items,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "view_cart"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#view_cart
 */
export const merchantGA4EcommTrackViewCart = (params: EcommCheckoutParams) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'view_cart', {
      currency: params.currency,
      value: params.value,
      cart_subtotal: params.cart_subtotal,
      items: params.items,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "begin_checkout"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#begin_checkout
 */
export const merchantGA4EcommTrackBeginCheckout = (
  params: EcommCheckoutParams
) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'begin_checkout', {
      currency: params.currency,
      value: params.value,
      coupon: params.coupon,
      discount: params.discount,
      cart_subtotal: params.cart_subtotal,
      items: params.items,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "add_shipping_info" for consumer cart
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#add_shipping_info
 */
export const merchantGA4EcommTrackAddShippingInfoConsumerCart = (
  params: EcommCheckoutParamsConsumerCart
) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'add_shipping_info', {
      currency: params.currency,
      value: params.value,
      coupon: params.coupon,
      discount: params.discount,
      shipping_tier: params.shipping_tier,
      shipping: params.shipping,
      fulfillment_type: params.fulfillment_type,
      cart_subtotal: params.cart_subtotal,
      items: params.items,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "add_payment_info"
 * ref: https://developers.google.com/tag-platform/gtagjs/reference/events#add_payment_info
 */
export const merchantGA4EcommTrackAddPaymentInfo = (
  params: EcommCheckoutParams
) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'add_payment_info', {
      currency: params.currency,
      value: params.value,
      coupon: params.coupon,
      discount: params.discount,
      payment_type: params.payment_type,
      cart_subtotal: params.cart_subtotal,
      items: params.items,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "login"
 * refs: https://developers.google.com/tag-platform/gtagjs/reference/events#login
 */
export const merchantGA4EcommTrackLogin = (params: EcommLoginSignupParams) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'login', {
      method: params.method,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}

/** MERCHANT Track GA4 ecommerce "sign_up"
 * https://developers.google.com/tag-platform/gtagjs/reference/events#sign_up
 */
export const merchantGA4EcommTrackSignUp = (params: EcommLoginSignupParams) => {
  if (AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'sign_up', {
      method: params.method,
      send_to: AllTrackers.getInstance().merchantGA4Tracker.trackingId
    })
  }
}
