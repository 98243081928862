import { useContext, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import StoreSortModal from './StoreSortModal'
import { LandingContext } from '../LandingContext'
import { StoreRankingCriteria } from '../types'

enum SortByLabels {
  DELIVERY = 'Delivery',
  PICKUP = 'Pickup',
  ALL = 'Delivery & Pickup'
}

const getSortByLabel = (sortCriteria: StoreRankingCriteria) => {
  const { fulfillmentTypes } = sortCriteria
  if (fulfillmentTypes.length === 1) {
    return SortByLabels[fulfillmentTypes[0]]
  }
  return SortByLabels['ALL']
}

const StoreSortControl = () => {
  const { sortCriteria } = useContext(LandingContext)
  const [showSortModal, setShowSortModal] = useState<boolean>(false)

  return (
    <>
      {showSortModal && (
        <StoreSortModal handleClose={() => setShowSortModal(false)} />
      )}
      <Container>
        <SortSummary>
          For&nbsp;<span>{getSortByLabel(sortCriteria)}</span>
        </SortSummary>
        <ChangeButton onClick={() => setShowSortModal(true)}>
          Change
        </ChangeButton>
      </Container>
    </>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    borderBottom: '1px solid #D9D9D9',

    [theme.mediaQueries.viewport7]: {
      padding: '18px 32px'
    }
  })
)

const SortSummary = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: '14px',
    lineHeight: '14px',
    fontFamily: theme.fonts['body'].family,
    fontWeight: 400,
    color: '#2A2A2A',

    '& span': {
      fontWeight: 600
    }
  })
)

const ChangeButton = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    padding: '8px 12px',
    borderRadius: '8px',
    backgroundColor: '#F5F5F5',
    cursor: 'pointer',

    fontSize: '14px',
    fontWeight: 600,
    fontFamily: theme.fonts['body'].family,

    // disable blue hightlight of user clicks
    WebkitTapHighlightColor: 'transparent',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',

    '&:hover': {
      opacity: 0.8
    },
    '&:active': {
      opacity: 0.6
    }
  })
)

export default StoreSortControl
