import styled from '@emotion/styled'
import {
  FlashContext,
  useReactRouter,
  useShop,
  useModal,
  useConsumerCart,
  useImages
} from 'shop/hooks'
import SoldOutOverlay from './SoldOutOverlay'
import { IoAddOutline as AddIcon } from 'react-icons/io5'
import { HiCheck as CheckIcon } from 'react-icons/hi'
import { AiOutlineLoading3Quarters as LoadingIcon } from 'react-icons/ai'
import { keyframes } from '@emotion/core'
import { DietaryRequirements } from '../ProductInfo'

import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'

import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  merchantGA4EcommTrackAddToCart,
  slerpGA4EcommTrackAddToCart
} from 'tracker/GA/ecommerce'
import { CategoryProduct } from '../Shop/Categories'
import { AddToCartParams } from '../Landing'
import { OrderItem } from 'shop/types/cart'
import { getCurrentCartId } from '../Cart/utils'
import { Medium } from '../common'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { fadeIn, LineBreak } from '../Shop/commonStyles'
import { SimpleFormat } from 'shop/utils'
import { LineBreakWrapper } from './commonStyles'
import { ImageTagType } from 'shop/types'

const AddToCartButton = ({
  onClick,
  buttonState
}: {
  onClick: (e: SyntheticEvent) => void
  buttonState: ProductCardButtonState
}) => (
  <ButtonWrapper
    onClick={(e: SyntheticEvent) => {
      e.stopPropagation()
      onClick(e)
    }}
    disabled={buttonState === 'loading' || buttonState === 'done'}
    data-testid='add-to-cart-button'
  >
    {getIcon(buttonState)}
  </ButtonWrapper>
)

const getIcon = (buttonState?: string | null) => {
  if (buttonState === 'loading') return <Loading size={20} />
  if (buttonState === 'done') return <CheckIcon color='green' size={20} />
  return <AddIcon size={20} />
}

const getCartItem = (orderItems: OrderItem[], id: string) =>
  orderItems.find((item) => item.variantId === id)

export interface ProductCardProps {
  product: CategoryProduct
  productCategory: string
  isLast: boolean
}

type ProductCardButtonState = 'loading' | 'done' | null

const ProductCard = ({
  product,
  productCategory,
  isLast
}: ProductCardProps) => {
  const [showFulfillmentForm, setShowFulfillmentForm] = useState(false)
  const [isProductImageClick, setIsProductImageClick] = useState(false)
  const {
    id: productId,
    defaultVariantId,
    images,
    inStock,
    name: productName,
    pricing,
    quickAddAllowed,
    limit,
    description,
    dietaryRequirements,
    slug: productSlug
  } = product
  const { maximum: maxPrice, minimum: minPrice } = pricing
  const { match, history } = useReactRouter<{ slug: string }>()
  const [titleLines, setTitleLines] = useState(1)
  const [isLoaded, setIsLoaded] = useState(false)
  const titleRef = useRef<HTMLDivElement>(null)
  const imageUrl = images[0]?.standard

  const { isModalOpen, openModal, closeModal, fulfillmentModal } = useModal()
  const { cart, cartLoading, createOrderItemConsumerCart } = useConsumerCart()
  const { partner, config } = useShop()
  const { getImage, handleImageError } = useImages(
    [{ imageUrl }],
    ImageTagType.Standard
  )
  const cartId = getCurrentCartId(config.domain)
  const [buttonState, setButtonState] = useState<ProductCardButtonState>(null)
  const { pushMessage } = useContext(FlashContext)

  const cartItem = getCartItem(cart?.orderItems || [], defaultVariantId)

  /** If no cart open modal, else route to product card */
  const handleProductClick = (e: SyntheticEvent) => {
    e.preventDefault()

    if (!cartId) {
      if (!isModalOpen('fulfillment')) {
        // User has clicked product card, not quick add button
        setIsProductImageClick(true)
        setShowFulfillmentForm(true)
        openModal('fulfillment')
      }
    } else {
      routeToProductModal(cart?.store.slug)
    }
  }

  const routeToProductModal = (storeSlug?: string) => {
    if (storeSlug) {
      const link = `/store/${storeSlug}/${productSlug}`
      const state = { product: { ...product }, productCategory }
      history.push(link, state)
    }
  }

  const ecommTrackAddToCartCallback = (params: {
    storeName: string
    merchantName: string
  }) => {
    // eccommerce tracking
    const trackParams = {
      currency: 'gbp',
      value: parseFloat(minPrice.base), // quick-add will always be base price
      items: [
        {
          item_id: productId,
          item_name: productName,
          affiliation: params.storeName,
          item_brand: params.merchantName,
          item_variant: productName, // quick-add will always be default name
          price: parseFloat(minPrice.base), // quick-add will always be base price
          quantity: 1 // quick-add will only add 1
        }
      ]
    }
    slerpGA4EcommTrackAddToCart(trackParams)
    merchantGA4EcommTrackAddToCart(trackParams)
  }

  const handleFormComplete = ({ storeSlug }: AddToCartParams) => {
    // If not a quick add item, close modal & route to product
    if (!quickAddAllowed || (quickAddAllowed && isProductImageClick)) {
      if (isModalOpen('fulfillment') || showFulfillmentForm) {
        closeModal('fulfillment')
        setShowFulfillmentForm(false)
      }
      routeToProductModal(storeSlug || '')
      setButtonState(null)
      return
    }
    addItemToCart()
  }

  /** Quick Add Item to Cart */
  const addItemToCart = () => {
    setButtonState('loading')

    const payload = {
      variantId: defaultVariantId,
      quantity: 1
    }

    const projectedItemQuantity = (cartItem && cartItem.quantity + 1) || 1

    if (limit !== null && projectedItemQuantity > limit) {
      setTimeout(() => setButtonState(null), 1500)
      return pushMessage({
        content: `The limit has been reached for ${productName}`,
        timeout: 1500,
        type: 'error'
      })
    }

    createOrderItemConsumerCart(payload)
      .then(() => {
        ecommTrackAddToCartCallback({
          storeName: cart?.store.name || '',
          merchantName: partner?.name || ''
        })
        setButtonState('done')
        setTimeout(() => setButtonState(null), 400)
      })
      .catch((err) => {
        console.error('ADD TO CART ERROR: ', err)
        setButtonState(null)
      })
      .finally(() => {
        closeModal('fulfillment')
        setShowFulfillmentForm(false)
      })

    const trackingBody = {
      category: productCategory ? productCategory : '',
      action: TrackableEvent.ProductAdded,
      label: productName,
      value: 1
    }

    trackUserActionsGA4(trackingBody, 'slerpGA4Tracking')

    // legacy tracking
    trackUserActionsFBPixel('AddToCart', {
      content_name: productName,
      content_type: 'product',
      contents: [{ id: productId, quantity: 1 }]
    })
    trackUserActionsGA4(trackingBody, 'merchantGA4Tracking')
  }

  const handleAddToCart = (event: SyntheticEvent) => {
    event.preventDefault()
    // Prevents quick add if cart is loading, i.e. if another createOrderItem is in progress
    if (cartLoading) return

    if (cartId) return addItemToCart()
    if (!isModalOpen('fulfillment')) {
      setShowFulfillmentForm(true)
      openModal('fulfillment')
    }
  }

  const showFromPrepend =
    !quickAddAllowed && maxPrice.base > minPrice.base && inStock

  const memoizedPrice = useMemo(() => {
    const pricePrepend = showFromPrepend ? 'From' : ''
    const priceToDisplay = minPrice.base
    return (
      <Price data-testid='product-card-price'>
        <PriceInfo>
          {pricePrepend && `${pricePrepend} `}
          <Medium>£{priceToDisplay}</Medium>
        </PriceInfo>
        <DietaryRequirements dietaryRequirements={dietaryRequirements} />
      </Price>
    )
  }, [showFromPrepend, minPrice.base, dietaryRequirements])

  /** Resets local state on related to fulfillment modal on modal close */
  const handleFulfillmentModalClose = () => {
    setShowFulfillmentForm(false)
    setIsProductImageClick(false)
  }

  const productUrl = `/order/store/${match.params.slug}/${productSlug}`

  /** Title and desc should not exceed 4 lines */
  useEffect(() => {
    if (titleRef.current) {
      const computedStyle = window.getComputedStyle(titleRef.current)
      const fontSize = parseFloat(computedStyle.fontSize)

      /**  Checks if lineHeight is "normal" (browser default),
       * if normal, calculates line height as fontSize * 1.2 (normal line height),
       * Divides total height by line height to get number of lines */
      const lineHeight =
        computedStyle.lineHeight === 'normal'
          ? Math.round(fontSize * 1.2)
          : parseInt(computedStyle.lineHeight, 10)

      const titleHeight = titleRef.current.offsetHeight
      const lines = Math.round(titleHeight / lineHeight)
      setTitleLines(lines)
    }
  }, [productName])

  return (
    <>
      {isModalOpen('fulfillment') &&
        showFulfillmentForm &&
        fulfillmentModal({
          addToCart: handleFormComplete,
          onClose: handleFulfillmentModalClose
        })}
      <AnchorWrapper href={productUrl} onClick={handleProductClick}>
        <Container
          data-testid='product-card'
          hasImage={!!imageUrl}
          inStock={inStock}
        >
          <TextContent>
            <div onClick={handleProductClick}>
              <ProductTitle ref={titleRef}>{productName}</ProductTitle>
            </div>

            <ProductDetails>
              <ProductDescription titleLines={titleLines}>
                {/* Split description at first newline to show only first paragraph */}
                <SimpleFormat>{description?.split('\n')[0]}</SimpleFormat>
              </ProductDescription>

              {memoizedPrice}
            </ProductDetails>
          </TextContent>

          <ProductImageContainer hasImage={!!imageUrl}>
            <AddToCartButton
              onClick={quickAddAllowed ? handleAddToCart : handleProductClick}
              buttonState={quickAddAllowed ? buttonState : null}
            />

            {!inStock && <SoldOutOverlay />}

            {imageUrl && (
              <div onClick={handleProductClick}>
                <ProductImage
                  src={getImage(116, 116)}
                  alt={`Order ${productName} online`}
                  loading='lazy'
                  isLoaded={isLoaded}
                  onLoad={() => setIsLoaded(true)}
                  onError={handleImageError}
                />
              </div>
            )}
          </ProductImageContainer>
        </Container>
      </AnchorWrapper>
      <LineBreakWrapper isLast={isLast}>
        <LineBreak />
      </LineBreakWrapper>
    </>
  )
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const Loading = styled(LoadingIcon)(() => ({
  animation: `${rotate} 1s linear infinite`
}))

const AnchorWrapper = styled.a(() => ({
  textDecoration: 'none',
  color: 'inherit'
}))

const ButtonWrapper = styled.button(() => ({
  zIndex: 1,
  position: 'absolute',
  bottom: '8px',
  right: '8px',
  height: '34px',
  width: '34px',
  borderRadius: '50%',
  opacity: 0.8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: 0,
  outline: 0,
  fontWeight: 'lighter',
  boxShadow: '0px 2px 4px rgba(98, 98, 98, 0.15)',
  backgroundColor: 'white',
  '&:hover, &:disabled, &:disabled:hover': {
    opacity: 1
  }
}))

const Container = styled.div(({ theme, inStock, hasImage }: any) => ({
  fontFamily: theme.fonts.normal,
  padding: '12px 0',
  borderRadius: '12px',
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: hasImage
    ? 'minmax(0, 1fr) minmax(124px, auto)'
    : 'minmax(0, 1fr) 0',
  gap: '12px',
  [theme.mediaQueries.viewport7]: {
    padding: '12px'
  },
  [theme.mediaQueries.viewport9]: {
    border: '2px solid #FAFAFA',
    '&:hover': {
      boxShadow:
        '0px -3px 12px 1px rgba(0, 0, 0, 0.03), 0px 3px 12px 1px rgba(0, 0, 0, 0.03)'
    }
  },
  // Pseudo element used to overlay parent element
  position: 'relative',
  '&::before': {
    display: inStock ? 'none' : 'grid',
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    opacity: 0.7,
    borderRadius: '12px',
    zIndex: 1
  }
}))

const Price = styled.p(({ theme }: any) => ({
  fontWeight: theme.fonts.body.weight,
  fontFamily: theme.fonts.body.family,
  fontSize: theme.fontSizes[1],
  margin: 'auto 0 8px',
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[2],
    marginBottom: '8px'
  }
}))

const PriceInfo = styled.span({
  marginRight: '8px'
})

const TextContent = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '116px',
    [theme.mediaQueries.viewport7]: {
      height: '126px'
    }
  })
)

const ProductImageContainer = styled.div<
  StyledHTMLElement & { hasImage: boolean },
  Required<Theme>
>(({ theme, hasImage }) => ({
  width: '116px',
  height: '116px',
  position: 'relative',
  justifySelf: 'end',
  border: hasImage ? '1px solid #EFEFF0' : 'none',
  borderRadius: hasImage ? '12px' : '0',
  [theme.mediaQueries.viewport7]: {
    width: '126px',
    height: '126px'
  }
}))

const ProductImage = styled.img<{ isLoaded: boolean }>(({ isLoaded }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  textDecoration: 'none',
  color: 'lightgray',
  borderRadius: '12px',
  opacity: 0,
  animation: isLoaded ? `${fadeIn} 0.5s ease-in-out forwards` : 'none'
}))

const ProductTitle = styled.div(({ theme }: any) => ({
  fontWeight: 'bold',
  fontFamily: theme.fonts.heading.family,
  fontSize: theme.fontSizes[1],
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[2]
  },
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  marginBottom: '4px'
}))

const ProductDetails = styled.div(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 1,
  gap: '4px'
}))

const ProductDescription = styled.div(({ theme, titleLines }: any) => ({
  fontFamily: theme.fonts.body.family,
  fontSize: theme.fontSizes[1],
  color: '#595959',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: titleLines === 1 ? '3' : '2',
  WebkitBoxOrient: 'vertical',
  '& p:first-of-type': {
    marginTop: 0
  }
}))

export default ProductCard
