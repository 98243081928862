import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'
import { SimpleFormat } from 'shop/utils'
import React, { useLayoutEffect, useState } from 'react'
import useExpandableDescription from './useDescription'
import { BaseContainer, baseDescContainerStyles } from './commonStyles'
import { DescriptionProps } from './types'
import { SeeMoreControl } from '.'

const ProductDescription = ({
  description = '',
  forceShowDescription = false
}: DescriptionProps) => {
  const {
    ref,
    hasExpandedText,
    maxHeight,
    isTransitioning,
    toggleDescriptionExpansion
  } = useExpandableDescription({ forceShowDescription })
  const [isSeeMoreEnabled, setIsSeeMoreEnabled] = useState(false)

  // Detects whether we need to show the "Read more" or not
  useLayoutEffect(() => {
    // no need to set "Read more" if we are forcing to show the description
    if (ref.current && !forceShowDescription) {
      // Small 1px buffer to account for sub-pixel rendering differences
      const buffer = 1
      if (ref.current?.clientHeight + buffer < ref.current.scrollHeight) {
        setIsSeeMoreEnabled(true)
      }
    }
  }, [ref, forceShowDescription])

  const handleContainerClick = () => {
    if (isSeeMoreEnabled) {
      toggleDescriptionExpansion()
    }
  }

  if (!description) return <></>

  return (
    <Container
      isSeeMoreEnabled={isSeeMoreEnabled}
      onClick={handleContainerClick}
    >
      <DescriptionContainer
        data-testid='product-description'
        ref={ref}
        {...{
          hasExpanded: hasExpandedText,
          isSeeMoreEnabled,
          maxHeight: maxHeight,
          isTransitioning
        }}
      >
        <SimpleFormat>{description}</SimpleFormat>
      </DescriptionContainer>
      {isSeeMoreEnabled && (
        <SeeMoreControl
          hasExpandedText={hasExpandedText}
          handleClick={toggleDescriptionExpansion}
        >
          {hasExpandedText ? 'Read less' : 'Read more'}
        </SeeMoreControl>
      )}
    </Container>
  )
}

const Container = styled(BaseContainer)<
  StyledHTMLElement & { isSeeMoreEnabled: boolean }
>(({ isSeeMoreEnabled }) => ({
  cursor: isSeeMoreEnabled ? 'pointer' : 'default',
  marginBottom: '24px'
}))

const DescriptionContainer = styled(baseDescContainerStyles)<
  StyledHTMLElement & {
    hasExpanded: boolean
    isSeeMoreEnabled: boolean
    isTransitioning: boolean
    maxHeight?: number
  }
>(({ hasExpanded, maxHeight, isTransitioning }) => {
  return {
    lineHeight: '1.1',

    ...(!hasExpanded &&
      !isTransitioning && {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3
      }),

    // maxHeight values of "fit-content" and "100%" don't animate properly
    maxHeight: hasExpanded ? `${maxHeight || 2000}px` : '46px'
  }
})

export default ProductDescription
