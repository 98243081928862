import { useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { PartnerStore, PartnerStoreBrowse } from '../types'
import { PseudoFormInput } from '../LocationSelect/FormControls'
import { IoIosSearch } from 'react-icons/io'
import BrowseStoresList from './BrowseStoresList'
import { LandingContext } from '../LandingContext'
import { useLandingLocation, useShop } from 'shop/hooks'
import { filterStores } from './utils'
import { ClosedStoresMessage } from '../LandingForm'
import { isAddressEligible } from '../LocationSelect/AddressSuggestions'

type Props = {
  stores?: PartnerStore[]
}

const LandingStoreList = ({ stores }: Props) => {
  const {
    setCurrentLocation,
    setUseCustomerAddress,
    currentLocation,
    sortedPartnerStores,
    merchant,
    defaultLoadCustomerAddress
  } = useContext(LandingContext)

  const { customerDetails } = useShop()

  const { handleLocationChange, handleLocationSelect, selectCustomerAddress } =
    useLandingLocation({
      setCurrentLocation,
      setUseCustomerAddress,
      currentLocation
    })

  const [searchValue, setSearchValue] = useState(currentLocation?.address || '')
  // sortedStores are the PartnerStoreBrowse responses from the BE with sort criteria
  const [sortedStores, setSortedStores] =
    useState<PartnerStoreBrowse[]>(sortedPartnerStores)
  // filteredStores are locally filtered for the child BrowseStoresList
  const [filteredStores, setFilteredStores] =
    useState<PartnerStoreBrowse[]>(sortedPartnerStores)

  const handleStoreSearch = (input: string) => {
    // handleLocationChange will handle the currentLocation reset
    handleLocationChange(input, { clearLocationOnChange: true })
    setSearchValue(input)

    if (!sortedStores?.length) return
    if (isAddressEligible(input)) {
      setFilteredStores(filterStores(input, sortedStores))
    } else {
      setFilteredStores(sortedStores)
    }
  }

  // listen to any currentLocation changes so we can update the search input value
  useEffect(() => {
    if (currentLocation?.address) {
      setSearchValue(currentLocation.address)
    }
  }, [currentLocation?.address])

  // Autofill Address - only when address input is empty and user has not cleared it before
  useEffect(() => {
    if (
      !!customerDetails?.addresses?.length &&
      !currentLocation?.address &&
      defaultLoadCustomerAddress
    ) {
      const defaultCustomerAddress = customerDetails.addresses.find(
        (customerAddress) => customerAddress.isDefaultShippingAddress
      )
      if (defaultCustomerAddress) {
        selectCustomerAddress(defaultCustomerAddress)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails?.addresses])

  // listen to any changes in stores from a potential location change
  useEffect(() => {
    setSortedStores(sortedPartnerStores)
    setFilteredStores(sortedPartnerStores)
  }, [sortedPartnerStores])

  if (!stores?.length && !searchValue) {
    return (
      <Container>
        <ClosedStoresMessage>
          {merchant && merchant.closed_store_message}
        </ClosedStoresMessage>
      </Container>
    )
  }

  return (
    <Container>
      <PseudoFormInput
        searchValue={searchValue}
        address={currentLocation?.address || ''}
        customerAddresses={customerDetails?.addresses}
        onChange={handleStoreSearch}
        onSelect={handleLocationSelect}
        id='store-search-input'
        selectCustomerAddress={selectCustomerAddress}
        inputPlaceholder='Type your Postcode or Store name'
        leftIcon={<IoIosSearch />}
        stores={currentLocation?.address ? sortedStores : filteredStores}
        closeModalOnSelect={false}
      />
      <BrowseStoresList
        stores={currentLocation?.address ? sortedStores : filteredStores}
      />
    </Container>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: 0,
    minHeight: '100vh', // maintain scroll position when store list shortens
    [theme.mediaQueries.viewport7]: {
      padding: `0 ${theme.space[3]}px`,
      gap: '21px',
      minHeight: 'unset'
    }
  })
)

export default LandingStoreList
