import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { SimpleFormat } from 'shop/utils'
import React, { useLayoutEffect, useState } from 'react'
import {
  FaChevronDown as DownArrowIcon,
  FaChevronRight as RightArrowIcon
} from 'react-icons/fa'
import {
  BaseContainer as Container,
  baseDescContainerStyles
} from './commonStyles'
import { DescriptionProps } from './types'
import useExpandableDescription from './useDescription'
import { SeeMoreControl } from '.'

const StoreDescription = ({
  description = '',
  forceShowDescription = false
}: DescriptionProps) => {
  const { ref, hasExpandedText, maxHeight, toggleDescriptionExpansion } =
    useExpandableDescription({ forceShowDescription })
  const [isSeeMoreEnabled, setIsSeeMoreEnabled] = useState(false)

  // Detects whether we need to show the "See more" or not
  useLayoutEffect(() => {
    // no need to set "See more" if we are forcing to show the description
    if (ref.current && !forceShowDescription) {
      if (ref.current?.clientHeight < ref.current.scrollHeight) {
        setIsSeeMoreEnabled(true)
      }
    }
  }, [ref, forceShowDescription])

  if (!description) return <></>

  const seeMoreContent = (
    <>
      {hasExpandedText ? 'See less' : 'See more'}{' '}
      {hasExpandedText ? <DownArrowIcon /> : <RightArrowIcon />}
    </>
  )

  return (
    <Container>
      <DescriptionContainer
        data-testid='product-description'
        ref={ref}
        {...{
          hasExpanded: hasExpandedText,
          isSeeMoreEnabled,
          maxHeight: maxHeight
        }}
      >
        <SimpleFormat>{description}</SimpleFormat>
      </DescriptionContainer>
      {isSeeMoreEnabled && (
        <SeeMoreControl
          hasExpandedText={hasExpandedText}
          handleClick={toggleDescriptionExpansion}
        >
          {seeMoreContent}
        </SeeMoreControl>
      )}
    </Container>
  )
}

const DescriptionContainer = styled(baseDescContainerStyles)<
  StyledHTMLElement & {
    hasExpanded: boolean
    isSeeMoreEnabled: boolean
    maxHeight?: number
  },
  Required<Theme>
>(({ theme, hasExpanded, isSeeMoreEnabled, maxHeight }) => ({
  lineHeight: 'normal',

  // maxHeight values of "fit-content" and "100%" don't animate properly
  maxHeight: hasExpanded ? `${maxHeight || 2000}px` : '42px',
  [theme.mediaQueries.viewport7]: {
    maxHeight: hasExpanded ? `${maxHeight || 2000}px` : '40px'
  },

  // Gradient effect to better display overflow of text
  '::after': {
    content: "''",
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    backgroundImage:
      hasExpanded || !isSeeMoreEnabled
        ? ''
        : 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 46.35%, #f1f1f1 100%)',
    width: '100%',
    height: '4em'
  }
}))

export default StoreDescription
